<template>
  <div class="eventsummary">
    <v-col cols="12" sm-11>
      <v-card>
        <v-card-title>
          <h3>EventSummary - {{ pumpcname }}</h3>
        </v-card-title>
        <v-card-subtitle>
          {{ pumpnname }} ({{ pumpip }})
        </v-card-subtitle>
        
        <v-container>
          <v-row>
            <v-col>
              <h3>Firmware Startup Times</h3> 
              <span v-if="view_eventstart_setbtn">({{ custStartId }})</span>
            </v-col>
            <v-col><v-text-field v-model="fwdatesearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table :headers="fwdateheaders" :items="firmwaredates" :search="fwdatesearch"
                :footer-props="{'items-per-page-options':[5, 10, 30, -1]}" :items-per-page="10" dense
                must-sort :sort-by.sync="sortFWDatesBy" :sort-desc.sync="sortFWDatesDesc" >
                  <template #item.setviewidbtn="{ item }">
                    <v-btn v-if="item.id != custStartId" dark small color="primary" @click="setCustomerEventViewStartId(item.id)">
                      Set EventStart
                    </v-btn>
                  </template>
              </v-data-table>
            </v-col>
          </v-row>

          <LoadingBar v-if="datafetch_in_progress" />
          <template v-else>
          <v-row>
            <v-col><h3>Events Histogram</h3></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-radio-group inline v-model="summarizeradio">
                <template v-slot:label>
                  <div>Summarize Events based on </div>
                </template>
                <v-radio label="Date" value="date"></v-radio>
                <v-radio label="Counter" value="counter"></v-radio>
              </v-radio-group>
            </v-col>
            <template v-if="summarizeradio == 'date'">
              <v-col cols="6" md="4">
                <v-menu v-model="startdatemenu" :close-on-content-click="false" :nudge-right="40" 
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="startdate" readonly v-bind="attrs" v-on="on"
                      label="Select Start Date" prepend-icon="mdi-calendar"         
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startdate" @input="startdatemenu = false" ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="4">
                  <v-menu v-model="enddatemenu" :close-on-content-click="false" :nudge-right="40" 
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="enddate" readonly v-bind="attrs" v-on="on"
                      label="Select End Date" prepend-icon="mdi-calendar"                    
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="enddate" @input="enddatemenu = false" ></v-date-picker>
                </v-menu>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6" md="4">
                <v-text-field label="Start Counter" variant="solo" v-model="starteventid"></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field label="End Counter" variant="solo" v-model="endeventid"></v-text-field>
              </v-col>
            </template>
            <v-col cols="2" md="1">
              <v-text-field label="Limit" variant="solo" v-model="limit"></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn color="primary" @click.prevent="refresh_eventshist(pumpip)">Refresh</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <apexchart height="600" type="bar" :options="eventhistoptions" :series="eventhistseries"></apexchart>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2">
              <v-checkbox v-model="showerrors" label="Show Errors" color="red"></v-checkbox>
            </v-col>
            <v-col md="2">
              <v-checkbox v-model="showwarns" label="Show Warnings" color="orange"></v-checkbox>
            </v-col>
            <v-col md="2">
              <v-checkbox v-model="showinfos" label="Show Infos" color="blue"></v-checkbox>
            </v-col>
          </v-row>
          </template>

          <v-row>
            <v-col>
              <h3>Events Search</h3>
              <v-spacer></v-spacer>
              <v-text-field v-model="eventssearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2">
              <v-radio-group inline v-model="summarizeradio">
                <template v-slot:label>
                  <div>Summarize Events based on </div>
                </template>
                <v-radio label="Date" value="date"></v-radio>
                <v-radio label="Counter" value="counter"></v-radio>
              </v-radio-group>
            </v-col>
            <template v-if="summarizeradio == 'date'">
              <v-col cols="6" md="4">
                <v-menu v-model="searchstartdatemenu" :close-on-content-click="false" :nudge-right="40" 
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="startdate" readonly v-bind="attrs" v-on="on"
                      label="Select Start Date" prepend-icon="mdi-calendar"         
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startdate" @input="searchstartdatemenu = false" ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="4">
                  <v-menu v-model="searchenddatemenu" :close-on-content-click="false" :nudge-right="40" 
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="enddate" readonly v-bind="attrs" v-on="on"
                      label="Select End Date" prepend-icon="mdi-calendar"                    
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="enddate" @input="searchenddatemenu = false" ></v-date-picker>
                </v-menu>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6" md="4">
                <v-text-field label="Start Counter" variant="solo" v-model="starteventid"></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field label="End Counter" variant="solo" v-model="endeventid"></v-text-field>
              </v-col>
            </template>
            <v-col cols="2" md="1">
              <v-text-field label="Limit" variant="solo" v-model="limit"></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn color="primary" @click.prevent="refresh_searchedevents(pumpip)">Search</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table :headers="eventssearchheaders" :items="eventslist" :loading="searchTableLoading"
              :footer-props="{'items-per-page-options':[10, 30, 100, -1]}" :items-per-page="10" dense>
                <template #item.date="{ item }">
                  {{ new Date(item.timestamp).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }) }}
                </template>
                <template #item.time="{ item }">
                  {{ new Date(item.timestamp).toLocaleTimeString() }}
                </template>
                <template #item.eventmsg="{ item }">
                  <v-container>
                    <v-row v-for="dtc,idx in item.dtclist" :key="dtc.shortLabel" dense>
                      <v-col :class="(idx!=item.dtclist.length-1)?'low-border':''">
                        <a :href="`dtc/${dtc.shortLabel}`" >{{ dtc.shortLabel }}</a> - {{ dtc.label }}
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </div>
</template>

<script>
  import LoadingBar from "../components/LoadingBar";

  export default {
    name: 'PumpEventSummary',
    components: {
      LoadingBar
    },
    data () {
      return {
        datafetch_in_progress: false,
        fwdatesearch: "",
        fwdateheaders:[
          { text: 'Counter', value: 'id', filterable: false, sortable: true },
          { text: 'Startup', value: 'date', filterable: true, sortable: true },
          { text: 'Firmware', value: 'fw', filterable: true, sortable: false },
          { text: 'CollectedOn', value: 'collectedon', filterable: true, sortable: true },
          { text: 'ESPCM-Id', value: 'espcmuuid', filterable: true, sortable: false }
        ],
        sortFWDatesBy: 'id', sortFWDatesDesc: true,
        eventhistoptions: {
            /*xaxis: {
              categories: []
            },*/
            plotOptions: {
              bar: {
                //distributed: true, // for different colored bars
                horizontal: true
              }
            },
            colors: ["#4747FF"],
            fill: {
              type: "gradient",
              gradient: {
                  shade: "dark", type: "horizontal",
                  gradientToColors: ["#000077"], stops: [0, 100]
              }
            },
            stroke: { lineCap: "butt" },
            /*labels: ["Output Pressure"]*/
            tooltip: {
              custom: function({series, seriesIndex, dataPointIndex, w}) {
                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                return '<div class="arrow_box">' +              
                  '<span>' + data.label + '</span>' +
                  ' : <span>' + series[seriesIndex][dataPointIndex] + '</span>' +    
                  '</div>'
              }
            }
        },
        pumpip:"", pumpcname:"", pumpnname:"",
        custStartId: "",
        firmwaredates:[],

        summarizeradio:'date',
        showerrors: true, showwarns: true, showinfos: true, 
        summaryhist: [],
        eventhistseries: [{data: []}],

        startdatemenu: false, enddatemenu: false,
        startdate: null,
        enddate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        starteventid:0, endeventid:999999999,
        limit: 10,

        eventssearch: "",
        searchstartdatemenu: false, searchenddatemenu: false,
        eventslist:[],
        eventssearchheaders: [
          { text: 'Date', value: 'date', sortable: false, filterable:false },
          { text: 'Time', value: 'time', sortable: false, filterable:false },
          { text: 'Event', value: 'eventmsg', sortable: false, filterable:false }
        ],
        searchTableLoading: false
      }
    },
    watch: {
      showerrors() { this.reload_eventshist_graph(); },
      showwarns() { this.reload_eventshist_graph(); },
      showinfos() { this.reload_eventshist_graph(); },
      eventhistoptions:{
        deep: true
      }
    },
    computed: {
      view_eventstart_setbtn() {
        const useraccessdict = this.$store.getters.get_useraccessdict();
        return (useraccessdict != null) && useraccessdict["eventsadmin"];
      }
    },
    methods: {
      refresh_fwdates(ipaddr){
        //this.$emit('loadstart');
        this.$api.get(this.$dtcdburl + "/events/fwdates?ip=" + ipaddr 
          + "&sdate="+this.startdate + "&edate="+this.enddate + "&limit="+this.limit )
        .then(response => {
          if (response.data["code"] == "get_fw_dates") {
            const fwdateslist = response.data["startupevents"];
            this.firmwaredates = fwdateslist.map( (x) => ({ 'id': x[0], 'date': x[1], 'fw': x[2], 'collectedon':x[3], 'espcmuuid':x[4] }) );
            this.custStartId = response.data["startid"];
          }
          //this.$emit('loadcomplete');
        });
      },
      refresh_eventshist(ipaddr){
        //this.$emit('loadstart');
        this.datafetch_in_progress = true;
        var event_summ_url = this.$dtcdburl + "/events/summary?ip=" + ipaddr + "&limit="+this.limit;
        if (this.summarizeradio == 'date') event_summ_url += "&sdate="+this.startdate + "&edate="+this.enddate;
        else event_summ_url += "&startid="+this.starteventid + "&endid="+this.endeventid;
        this.$api.get( event_summ_url )
        .then(response => {
            if (response.data["code"] == "get_events_summary") {
              this.pumpip = response.data["pumpip"];
              this.pumpcname = response.data["cname"];
              this.pumpnname = response.data["nname"];
              this.summaryhist = response.data['eventshist'];
              this.reload_eventshist_graph();
            }
            //this.$emit('loadcomplete');
            this.datafetch_in_progress = false;
        });
      },
      reload_eventshist_graph() {
        var chart_eventhist_list  = this.summaryhist.map( function (x) {
          return ({ 'x': x[0], 'y': x[1], 'label': x[0]+' - '+x[2] });
        });
        if (!this.showerrors) 
          chart_eventhist_list = chart_eventhist_list.filter( (d) => d['x'][0] != 'E');
        if (!this.showwarns) 
          chart_eventhist_list = chart_eventhist_list.filter( (d) => d['x'][0] != 'W');
        if (!this.showinfos) 
          chart_eventhist_list = chart_eventhist_list.filter( (d) => d['x'][0] != 'I');
        this.eventhistseries = [{ name: 'event count', data:  chart_eventhist_list }];

        /*this.eventhistoptions.colors = this.chart_eventhist_list.map( function (d) {
          if (d['x'][0] == 'E') return "#FF0000";
          else if (d['x'][0] == 'W') return "#FFFF47";
          else if (d['x'][0] == 'I') return "#4747FF";
          else return "#000000";
        });
        this.eventhistoptions.fill.colors = this.eventhistoptions.colors;*/
      },
      refresh_searchedevents(ipaddr){
        //this.$emit('loadstart');
        this.searchTableLoading = true;
        var event_search_url = this.$dtcdburl + "/events/search?ip="+ipaddr + "&stext="+this.eventssearch
        + "&offset=0" + "&limit="+this.limit;
        if (this.summarizeradio == 'date') event_search_url += "&sdate="+this.startdate + "&edate="+this.enddate;
        else event_search_url += "&startid="+this.starteventid + "&endid="+this.endeventid;
        this.$api.get( event_search_url )
        .then(response => {
            if (response.data["code"] == "get_events_searched") {
              this.eventslist = response.data['eventslist'];
            }
            //this.$emit('loadcomplete');
            this.searchTableLoading = false;
        });
      },
      setCustomerEventViewStartId(startid){
        //this.$emit('loadstart');
        this.$api.get( this.$dtcdburl + "/events/setstart?ip="+this.pumpip + "&startid="+startid )
        .then(response => {
            if (response.data["code"] == "set_eventsview_startid") {
              if (response.data["success"]) {
                //alert("EventStart updated to " + response.data['startid']);
                this.refresh_fwdates(this.pumpip);
              }
            }
            //this.$emit('loadcomplete');
        });
      }
    },
    mounted(){
      //const today = new Date();
      const start_of_month = new Date();
      start_of_month.setDate(1);      
      this.startdate = (new Date(start_of_month - start_of_month.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

      if(this.view_eventstart_setbtn) {
        this.fwdateheaders.push({ text: 'SetViewCounter', value: 'setviewidbtn', filterable: false, sortable: false });

      }
      const pipaddr = this.$route.query["pumpip"];
      this.refresh_fwdates(pipaddr);
      this.refresh_eventshist(pipaddr);
    },
    
  }
</script>