<template>
<div id="usersearch" style="height:86vh">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Users Search</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >

      <v-col cols="12" class="fill-height">
        <v-card class="fill-height" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <v-text-field  v-model="username_to_search" append-icon="mdi-magnify" class="shrink"
            filled dense hide-details :rules="[v => v.length<=60 || 'Max 60 characters']" counter>
          </v-text-field>
          &nbsp;
          <v-btn class="primary fill-height" @click="searchUsers">Search</v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-card-text><h3>Found Users ({{ userslist.length }})</h3></v-card-text>
        </v-card-subtitle>
        <v-scroll-y-transition mode="out-in">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"> Id </th>
                    <th class="text-left"> Email </th>
                    <th class="text-left"> Firstname </th>
                    <th class="text-left"> Lastname </th>
                    <th class="text-left"> Reference Path </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user,idx in userslist" :key="idx" >
                    <td class="text-left">{{ user.id }}</td>
                    <td class="text-left">{{ user.email }}</td>
                    <td class="text-left">{{ user.fname }}</td>
                    <td class="text-left">{{ user.lname }}</td>
                    <td> {{ pathListToString(user.userpath) }} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  export default {
    name: "UserSearchPage",

    data: () => ({
      username_to_search: "",      
      userslist: [],
    }),
    methods: {
      searchUsers () {
        //console.log(this.username_to_search)
        this.userslist = [];
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/users/search',{"username":this.username_to_search})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "search_company_users")
                {
                  this.userslist = response.data["found-users-list"];
                  //console.log(this.userslist);

                  if (this.userslist == null) this.userslist = [];
                }
                //this.$emit('loadcomplete');
              });

        return;
      },
      pathListToString(pathlist) {
        return pathlist.join(' -> ')
      }
    }
  }
</script>