import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./plugins/vuexstore";
import vuetify from "./plugins/vuetify";
import "./plugins/tiptap";
import siosocket from "./plugins/siosocket";
import axiosapi from "./plugins/axiosapi";
import VueApexCharts from 'vue-apexcharts'

Vue.prototype.$dtcdburl = 'https://vmmh1.quantumspt.com/diagnostics'; //'http://localhost:6001'; // DTC database
Vue.prototype.$rsrcdburl = 'https://vmm-services.quantumspt.com/resources'; //'http://localhost:6003'; // Resources database
Vue.prototype.$mcdurl = 'https://vmm-services.quantumspt.com';//'http://localhost:33333'; // MCD (VHMI) Used from siosocket.js
Vue.prototype.$orgmanurl = 'https://vmm-services.quantumspt.com/gatekeeper';//'http://localhost:5000'; // Organisation Management
Vue.prototype.$pumpreporterurl= 'https://vmm.quantumspt.com:8443';//'http://localhost:8443'; // Pump Reporter
Vue.prototype.$olsupervisorurl= 'https://vmmh1.quantumspt.com/online';//'http://localhost:2312'; // RouterSupervisor
Vue.prototype.$pumpcaptureurl= 'https://vmmh1.quantumspt.com/pumpcapture';//"http://localhost:4741"; // PumpCapture
Vue.config.productionTip = false;

Vue.prototype.$socket = siosocket;
Vue.prototype.$api = axiosapi;

Vue.component('apexchart',VueApexCharts);

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
});

app.$mount("#app");