import io from 'socket.io-client'
import store from "./vuexstore";

// if you change the url, change in main.js as well
const siosocket = io('https://vmm-services.quantumspt.com',// 'http://localhost:33333', //
                      {secure: true, auth: {token: store.getters.get_session_id()}}); 
var vhmi_keepalive_timer = null;

siosocket.on("connect", () => {
  //console.log("Connected: " + this.$siosocket.id);
  store.commit('socket_connected', siosocket.id);
  if ((siosocket != null) && siosocket.connected) {
    //var today = new Date();
    //console.log("keepalive @ " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds());
    siosocket.emit("keepalive", store.getters.get_session_id());//siosocket.id);
  }
  vhmi_keepalive_timer = setInterval(() => {
    if ((siosocket != null) && siosocket.connected) {
      //var today = new Date();
      //console.log("keepalive @ " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds());
      siosocket.emit("keepalive", store.getters.get_session_id());//siosocket.id);
    }
  }, 60000); // 60 seconds
});

/*this.$socket.on("connection", fetcheddata => {
    //console.log(fetcheddata);
    this.statusmsg = fetcheddata['state'] + ": " + fetcheddata['sid'] + "\n";
});*/

siosocket.on("session_expired", () => {
  store.commit('del_session_id');
});

siosocket.on("disconnect", () => {
    //console.log("Disconnected!");
    if (vhmi_keepalive_timer != null)
        clearInterval(vhmi_keepalive_timer);
    store.commit('socket_disconnected');
  });

siosocket.on("pumpdata", fetcheddata => {
  //console.log(fetcheddata);
  //console.log(fetcheddata[0]["data"]);
  store.commit('vhmi_pump_data', fetcheddata);
});


/*siosocket.on("error", errdata => {
  console.log(errdata);
});*/

export default siosocket;