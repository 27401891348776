<template>
    <div>
      <v-data-table v-if="rclist.length > 0" :headers="headers" :items="rclist" 
        :search="searchtext" :footer-props="{'items-per-page-options':[10, 30, 50, -1]}" :items-per-page="10">
          <template #item.id="{ value }">
            <v-btn dark small color="error" @click="deleteEntryClick(value)">
            <v-icon dark> mdi-minus </v-icon>
            </v-btn>
          </template>
          <template #item.title="{ value }">
            <router-link :to="'/resource/'+value[0]">{{ value[1] }}</router-link>
          </template>
      </v-data-table>
      
        <v-btn v-if="view_edit_controls" color="primary" dark to="resource/new">Add Page</v-btn>
        <v-dialog v-model="deleteEntryDialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
            <v-card-text> Are you sure you want to delete ? </v-card-text>
            <v-card-text> {{ delentry_error_msg }} </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleteEntryConfirm" > Delete </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 
    </div>
</template>
<script>
export default ({
    name: "ResourcesList",
    data: () => ({
        view_edit_controls: false,
        
        rclist: [],
        headers: [
          { text: 'Type', value: 'type', filterable: true, sortable: true },
          { text: 'Title', value: 'title', filterable: false, sortable: false },
          { text: 'Publish Date', value: 'publishdate', filterable: false, sortable: true },
          { text: 'Review Date', value: 'reviewdate', filterable: false, sortable: false },
          { text: 'Reviewed By', value: 'author', filterable: true, sortable: false },
          //{ text: 'Delete', value: 'id', filterable: false, sortable: false }
        ],
        searchtext:"",

        deleteEntry: {"id":"","type":"","title":"", "content":""},
        deleteEntryIdx: null,
        delentry_error_msg: null,
        deleteEntryDialog: false,
    }),
  /*computed : {
    secured_headers () {
      if (this.view_edit_controls)
        return this.headers;
      else
        return this.headers.filter(x => x.value != 'id');
    }
  },*/
  mounted(){
    //this.$emit('loadstart');
    const useraccessdict = this.$store.getters.get_useraccessdict();
    //console.log(useraccessdict);
    this.view_edit_controls = (useraccessdict != null) && useraccessdict["edit_resources"];
    if (this.view_edit_controls)
      this.headers.push({ text: 'Delete', value: 'id', filterable: false, sortable: false });
    this.fetch_rc_list();
  },
  methods: {
    fetch_rc_list() {
      this.$emit('loadstart');
      this.$api.get(this.$rsrcdburl + "/")
      .then(response => {
        //console.log(response.data);
        this.rclist = response.data.map(function(x) { 
            const dict = {"id":x.id,"type":x.type,"title":[x.id,x.title],
                          "publishdate":x.publishdate,"reviewdate":x.reviewdate,"author":x.author}
            return dict;
          }
        );
        this.$emit('loadcomplete');
        //alert(typeof this.dtc_details.details);
      });
    },
    deleteEntryClick(rcid){
        const idx = this.rclist.findIndex(x => x.id == rcid);
        this.deleteEntry = this.rclist[idx];
        this.deleteEntryIdx = idx;
        this.delentry_error_msg = null;
        this.deleteEntryDialog = true;
    },
    deleteEntryConfirm(){
      this.$emit('loadstart');
      const deldata = {"code":"delete_page","id":this.deleteEntry['id']};
      console.log(deldata);
      this.$api.post(this.$rsrcdburl + "/delete", deldata)
      .then(response => {
        if (response.data['code'] == 'delete_page') {
        if (response.data['success'])
        {
            console.log("success");
            this.deleteEntryDialog = false;
            this.rclist.splice(this.deleteEntryIdx, 1);
        } else {
            this.delentry_error_msg = response.data['errmsg'];
        }
        } else {
        this.delentry_error_msg = "Invalid response!";
        }});
    }
  }
})
</script>
