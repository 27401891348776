<template>
  <div>
    <v-card>
      <v-tabs v-model="vhmitabidx" centered background-color="teal darken-3" dark>
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab href="#tab-home">Home</v-tab>
        <v-tab href="#tab-diag">Alarms</v-tab>
        <v-tab href="#tab-temp">Temperatures</v-tab>
        <!--v-tab href="#tab-hpress">High Pressure</v-tab-->
        <v-tab href="#tab-adk-oil-system">ADK Oil System</v-tab>
        <v-tab href="#tab-adk-lp-system">ADK LP System</v-tab>
        <v-tab href="#tab-adk-hp-system">ADK HP System</v-tab>
        <v-tab href="#tab-info">Basic Info</v-tab>
        <v-tab href="#tab-pump-capture">Pump Capture</v-tab>
      </v-tabs>
      <v-tabs-items v-model="vhmitabidx">
        <v-tab-item value="tab-home">
          <home-tab :pump="pump" />
        </v-tab-item>
        <v-tab-item value="tab-diag">
          <diagnostics-tab :pump="pump" />
        </v-tab-item>
        <v-tab-item value="tab-temp">
          <temperatures-tab :pump="pump" />
        </v-tab-item>
        <!--v-tab-item value="tab-hpress">
          <high-pressure-tab :pump="pump" />
        </v-tab-item-->
        <v-tab-item value="tab-adk-oil-system">
          <ADKOilSystemTab :pump="pump" />
        </v-tab-item>
        <v-tab-item value="tab-adk-lp-system">
          <ADKLPSystemTab :pump="pump" />
        </v-tab-item>
        <v-tab-item value="tab-adk-hp-system">
          <ADKHPSystemTab :pump="pump" />
        </v-tab-item>
        <v-tab-item value="tab-info">
          <basic-info-tab :pump="pump" />
        </v-tab-item>
        <v-tab-item value="tab-pump-capture">
          <PumpCaptureTab :pump="pump" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog v-model="showpumpdtcs" transition="dialog-bottom-transition" max-width="600" scrollable>
        <v-card>
          <v-toolbar color="primary" dark>
            <div>EventLog</div>
          </v-toolbar>
          <v-list dense>
            <v-list-group v-for="eventdict,idx in eventlist" :key="idx"
                v-model="eventdict.active" @click="getDTClabels(eventdict['dtcs'])" no-action>
                <template v-slot:activator>
                  <v-list-item-content v-if="eventdict['type'] == 1">
                    <v-list-item-subtitle>
                      {{ eventdict["dt"] }}&nbsp;{{ eventdict["dtcs"] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else-if="eventdict['type'] == 3">
                    <v-list-item-title>
                        {{ eventdict["dt"] }}&nbsp;<font color="blue">{{ eventdict["msg"] }}</font>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item v-for="dtcobj,didx in dtcsWithTitle" :key="didx">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <font :color="getDTCcolor(dtcobj.shortLabel)">{{ dtcobj.shortLabel }}</font>&nbsp;{{ dtcobj.label }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
          </v-list>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors';
import HomeTab from './VHMI/HomeTab.vue';
import DiagnosticsTab from './VHMI/DiagnosticsTab.vue';
import PumpCaptureTab from './VHMI/PumpCaptureTab.vue';
import TemperaturesTab from './VHMI/TemperaturesTab.vue';
//import HighPressureTab from './VHMI/HighPressureTab.vue';
import BasicInfoTab from './VHMI/BasicInfoTab.vue';
import ADKOilSystemTab from './VHMI/ADKOilSystemTab.vue';
import ADKLPSystemTab from './VHMI/ADKLPSystemTab.vue';
import ADKHPSystemTab from './VHMI/ADKHPSystemTab.vue';

export default {
  name: "VhmiPumpTile",
  components: {
    HomeTab,
    DiagnosticsTab,
    PumpCaptureTab,
    TemperaturesTab,
    //HighPressureTab,
    BasicInfoTab,
    ADKOilSystemTab,
    ADKLPSystemTab,
    ADKHPSystemTab
  },
  props: {
      pump: null
  },
  data(){
    return {
      vhmitabidx : 0,
      showpumpdtcs: false,
      eventlist: [],
      dtcsWithTitle: [],
    }
  },
  methods: {
    showdtcdialog() {
      this.eventlist = this.pump['events'];
      for (var i = 0; i < this.eventlist.length; i++) {
        this.eventlist[i]["active"] = false;
      }
      this.showpumpdtcs = true;
    },
    getDTClabels(dtcs) {
      this.dtcsWithTitle = []
      //this.$emit('loadstart');
      this.$api.post(this.$dtcdburl + "/code", dtcs)
      .then(response => {
        //console.log(response.data);
        this.dtcsWithTitle = response.data;
        //this.$emit('loadcomplete');
      });
    },
    getDTCcolor(shortlabel){
      var severity = "";
      //console.log(shortlabel[0]);
      switch(shortlabel[0]) {
        case 'I':
          severity = colors.blue.lighten4;
          break;
        case 'W':
          severity = colors.orange.lighten1;
          break;
        case 'E':
          severity =  colors.red;
          break;
        default:
          severity = "black";
          break;
          // code block
      }
      return severity;
    }
  }
}
</script>

