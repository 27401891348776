export default {
  MachineConfiguration_tenPumpVariants : Object.freeze({
    0: "None",
    1: "ESP55-30kW-G1",
    2: "ESP66-30kW-G1",
    3: "ESP55-30kW-G2",
    4: "ESP66-30kW-G2",
    5: "ESP52-15kW-G3",
    6: "ESP60-22kW-G3",
    7: "ESP55-30kW-G4",
    8: "ESP66-30kW-G4",
    9: "ESP55-37kW-G4",
    10: "ESP66-37kW-G4",
    11: "ESP66-45kW-G4",
    12: "ESP60-45kW-G4",
    13: "ESP50-37kW-G4",
    14: "ESP87-55kW-G4",
    15: "ESP87-45kW-G4",
    16: "ESPMA-45kW-G4",
    17: "ESP52-07kW-G3",
    18: "ESP87-37kW-G4",
    19: "ESP60-22kW-G6",
    20: "ESPMA-75kW-G6",
    21: "ESPMX-45kW-G4",
    22: "ESP52-15kW-G6"
  }),

  MachineConfiguration_tenPumpSerialNumberPrefix : Object.freeze({
    0: "Unknown",
    1: "RD",
    2: "TW",
    3: "TT",
    4: "QT",
  }),
}