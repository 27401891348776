<template>
  <div class="pumpreportervariables">
    <v-row>
      <v-col cols="12" sm-11>
        <v-card>
          <v-card-title>
            <h3>Variables in PumpReporter</h3>&nbsp;
            <v-spacer></v-spacer>
            <v-text-field v-model="varcomparisons_search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="varcomparisons_headers" :items="varcomparisons_list" :search="varcomparisons_search" :footer-props="{'items-per-page-options':[30, 50, 100, -1]}" :items-per-page="30" dense>
            <!--template v-slot:item.type="{ item }">
                <v-chip :color="levelColor(item)"> {{ item.type }}</v-chip>
            </template-->
            <template #item.delvar="{ item }">
              <v-btn dark small color="error" @click="delVariableClick(item.varname)">
                <v-icon dark> mdi-minus </v-icon>
              </v-btn>
            </template>
            <!--template #item.code="{ value }">
                  <a :href="`dtc/${value}`" >{{ value }}</a>
            </template-->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm-11>
        <v-card>
          <v-card-title>
            <h3>Allowed Variables</h3>&nbsp;
            <v-spacer></v-spacer>
            <v-text-field v-model="pumpvars_search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="pumpvars_headers" :items="pumpvars_list" :search="pumpvars_search" :footer-props="{'items-per-page-options':[10]}" :items-per-page="30" dense>
            <!--template v-slot:item.type="{ item }">
                <v-chip :color="levelColor(item)"> {{ item.type }}</v-chip>
            </template-->
            <template #item.addvar="{ item }">
              <v-btn dark small color="primary" @click="addVariableClick(item.varname)">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <!--template #item.code="{ value }">
                  <a :href="`dtc/${value}`" >{{ value }}</a>
            </template-->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="addVariableDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Confirm Add Variable  </v-card-title>
        <v-card-text> 
          <b>{{ selected_varcomparison[0] }}</b>
        </v-card-text>
        <v-card-text> 
          <v-select label="Select Comparison*" :items="comparisons" v-model="selected_varcomparison[1]" density="compact"></v-select>
        </v-card-text>
        <v-card-text> 
          <v-text-field label="Test Value*" v-model="selected_varcomparison[2]" required></v-text-field>
        </v-card-text>
        <v-card-text> {{ addvar_error_msg }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addVariableConfirm" > Add Var </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-dialog v-model="delVariableDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
        <v-card-text> Are you sure you want to delete {{ selected_del_var }} ? </v-card-text>
        <v-card-text> {{ delvar_error_msg }} </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="delVariableConfirm" > Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
  export default {
    name: 'PumpReporterVariables',
    data () {
      return {

        //selected_pumpid: "2062d5f7-571d-46c9-93c7-14afa5e74471",
        comparisons: ['gte', 'lte', 'eq', 'chgd', 'log'],

        varcomparisons_search: '',
        varcomparisons_headers: [
          { text: 'Variable', value: 'varname', filterable: true, sortable: true },
          { text: 'Comparison', value: 'comp', filterable: false, sortable: false },
          { text: 'Test Value', value: 'testval', filterable: false, sortable: false },
          { text: 'Delete Variable', value: 'delvar', filterable: false, sortable: false }
        ],
        varcomparisons_list: [],

        pumpvars_search: '',
        pumpvars_headers: [
          { text: 'Variable', value: 'varname', filterable: true, sortable: true },
          { text: 'Add Variable', value: 'addvar', filterable: false, sortable: false }
        ],
        pumpvars_list: [],

        addVariableDialog: false,
        selected_varcomparison: ['','',''],//[varname,comparison,testval]
        addvar_error_msg: '',

        delVariableDialog: false,
        selected_del_var: '',
        delvar_error_msg: ''
      }
    },
    computed:{
      selected_pumpid() {
        return this.$route.query.pumpid;
      }
    },
    mounted(){
      //this.$emit('loadstart');
      this.$api.post(this.$pumpreporterurl + "/pumpvars/allowed", {"code":'allowed_pumpvars_in_report'})
      .then(response => {
        var pumpvarslist = response.data['pumpvarlist'];
        this.pumpvars_list = pumpvarslist.map(mcdvar => ({"varname": mcdvar}))
        //console.log(this.pumpvars_list);
        // Set the initial number of items
        //this.totalRows = this.bill_list.length;
        //this.$emit('loadcomplete');
      });
      this.fetchVarComparisons();
    },
    methods: {
      fetchVarComparisons() {      
        var get_varcomparisons_reqdict = { "code": "get_pumpvars_from_report"}
        get_varcomparisons_reqdict["pumpid"] = this.selected_pumpid
        this.$api.post(this.$pumpreporterurl + "/pumpvars/get", get_varcomparisons_reqdict)
        .then(response => {
          var varcomparisonslist = response.data['varcomparisonslist'];
          this.varcomparisons_list = varcomparisonslist.map(varcomt => ({"varname": varcomt[0], "comp": varcomt[1], "testval": varcomt[2]}))
          //console.log(this.pumpvars_list);
          // Set the initial number of items
          //this.totalRows = this.bill_list.length;
          //this.$emit('loadcomplete');
        });
      },
      addVariableClick(varname){
        this.selected_varcomparison[0] = varname;
        this.addvar_error_msg = null;
        this.addVariableDialog = true;
      },
      addVariableConfirm(){
        var add_varcomparisons_reqdict =  {'code':'add_pumpvars_to_report'}
        add_varcomparisons_reqdict["pumpid"] = this.selected_pumpid
        add_varcomparisons_reqdict['varcomparisonslist'] = [this.selected_varcomparison]
        this.$api.post(this.$pumpreporterurl + '/pumpvars/add',add_varcomparisons_reqdict)
                .then(response => {
                  if (response.data['code'] == 'add_pumpvars_to_report') {
                    if (response.data['success'])
                    {
                      this.fetchVarComparisons();
                      this.addVariableDialog = false;
                    } else {
                      this.addvar_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.addvar_error_msg = "Invalid response!";
                  }
                });
      },
      delVariableClick(varname){
        this.selected_del_var = varname;
        this.delvar_error_msg = null;
        this.delVariableDialog = true;
      },
      delVariableConfirm(){
        var del_varcomparisons_reqdict =  {'code':'del_pumpvars_from_report'}
        del_varcomparisons_reqdict["pumpid"] = this.selected_pumpid
        del_varcomparisons_reqdict['pumpvarlist'] = [this.selected_del_var]
        this.$api.post(this.$pumpreporterurl + '/pumpvars/del',del_varcomparisons_reqdict)
                .then(response => {
                  if (response.data['code'] == 'del_pumpvars_from_report') {
                    if (response.data['success'])
                    {
                      this.fetchVarComparisons();
                      this.delVariableDialog = false;
                    } else {
                      this.delvar_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.delvar_error_msg = "Invalid response!";
                  }
                });
      },
    }
  }
</script>