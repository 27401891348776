<template>
  <DashBoard />
</template>

<script>
import DashBoard from "../components/DashBoard";

export default {
  name: "Home",

  components: {
    DashBoard,
  },
};
</script>
