<template>
    <v-container>
        <v-row>
            <v-col>
                <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
                <template v-if="pump['daq'].length > 0">
                    <div class="gtm-img-wrapper d-none d-md-block" v-if="pumpIsDual">
                        <v-img src="@/assets/adk_oil_system_dual.png"  ></v-img>
                        <div id="p2ntc1_dual">{{ pump["daq"][p2ntc1i].toFixed(0) }}</div>
                        <div id="p2ntc2_dual">{{ pump["daq"][p2ntc2i].toFixed(0) }}</div>
                        <div id="p2ntc3_dual">{{ pump["daq"][p2ntc3i].toFixed(0) }}</div>
                        <div id="p1ntc1_dual">{{ pump["daq"][p1ntc1i].toFixed(0) }}</div>
                        <div id="p1ntc2_dual">{{ pump["daq"][p1ntc2i].toFixed(0) }}</div>
                        <div id="p1ntc3_dual">{{ pump["daq"][p1ntc3i].toFixed(0) }}</div>
                        <div id="ohtemp_dual">{{ pump["daq"][ohtempi].toFixed(0) }}</div>
                        <div id="o1psi_dual">{{ pump["daq"][o1psi].toFixed(0) }}</div>
                        <div id="o2psi_dual">{{ pump["daq"][o2psi].toFixed(0) }}</div>
                        <div id="opfe_dual">{{ pump["daq"][opfei].toFixed(0) }}</div>
                        <div id="o3psi_dual">{{ pump["daq"][o3psi].toFixed(0) }}</div>
                        <div id="octemp_dual">{{ pump["daq"][octempi].toFixed(0) }}</div>
                        <div id="cwotemp_dual">{{ pump["daq"][cwotempi].toFixed(0) }}</div>
                        <div id="cwitemp_dual">{{ pump["daq"][cwitempi].toFixed(0) }}</div>
                    </div>
                    <div class="gtm-img-wrapper d-none d-md-block" v-else>
                        <v-img src="@/assets/adk_oil_system.png"  ></v-img>
                        <div id="p1ntc1">{{ pump["daq"][p1ntc1i].toFixed(0) }}</div>
                        <div id="p1ntc2">{{ pump["daq"][p1ntc2i].toFixed(0) }}</div>
                        <div id="p1ntc3">{{ pump["daq"][p1ntc3i].toFixed(0) }}</div>
                        <div id="ohtemp">{{ pump["daq"][ohtempi].toFixed(0) }}</div>
                        <div id="o1psi">{{ pump["daq"][o1psi].toFixed(0) }}</div>
                        <div id="o2psi">{{ pump["daq"][o2psi].toFixed(0) }}</div>
                        <div id="opfe">{{ pump["daq"][opfei].toFixed(0) }}</div>
                        <div id="o3psi">{{ pump["daq"][o3psi].toFixed(0) }}</div>
                        <div id="octemp">{{ pump["daq"][octempi].toFixed(0) }}</div>
                        <div id="cwotemp">{{ pump["daq"][cwotempi].toFixed(0) }}</div>
                        <div id="cwitemp">{{ pump["daq"][cwitempi].toFixed(0) }}</div>
                    </div>
                    <v-container class="d-block d-md-none">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr><th class="text-center text-h5" colspan="2">Actuator 1</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Left Temperature</td>
                                                <td>{{ pump["daq"][p1ntc1i].toFixed() }} °C</td>
                                            </tr>
                                            <tr>
                                                <td>Center Temperature</td>
                                                <td>{{ pump["daq"][p1ntc2i].toFixed() }} °C</td>
                                            </tr>
                                            <tr>
                                                <td>Right Temperature</td>
                                                <td>{{ pump["daq"][p1ntc3i].toFixed() }} °C</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col v-if="pumpIsDual" cols="12" sm="6">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr><th class="text-center text-h5" colspan="2">Actuator 2</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Left Temperature</td>
                                                <td>{{ pump["daq"][p2ntc1i].toFixed() }} °C</td>
                                            </tr>
                                            <tr>
                                                <td>Center Temperature</td>
                                                <td>{{ pump["daq"][p2ntc2i].toFixed() }} °C</td>
                                            </tr>
                                            <tr>
                                                <td>Right Temperature</td>
                                                <td>{{ pump["daq"][p2ntc3i].toFixed() }} °C</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr><th class="text-center text-h5" colspan="2">Oil Flow/Pressure</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Oil Pressure 1</td>
                                                <td>{{ pump["daq"][o1psi].toFixed(0) }} PSI</td>
                                            </tr>
                                            <tr>
                                                <td>Oil Pressure 2</td>
                                                <td>{{ pump["daq"][o2psi].toFixed(0) }} PSI</td>
                                            </tr>
                                            <tr>
                                                <td>Oil Pressure 3</td>
                                                <td>{{ pump["daq"][o3psi].toFixed(0) }} PSI</td>
                                            </tr>
                                            <tr>
                                                <td>Oil Flowrate</td>
                                                <td>{{ pump["daq"][opfei].toFixed(0) }} LPM</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr><th class="text-center text-h5" colspan="2">Oil Temperatures</th></tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Oil Hot Temperature</td>
                                                <td>{{ pump["daq"][ohtempi].toFixed() }} °C</td>
                                            </tr>
                                            <tr>
                                                <td>Oil Cold Temperature</td>
                                                <td>{{ pump["daq"][octempi].toFixed(0) }} °C</td>
                                            </tr>
                                            <tr>
                                                <td>CoolingWater In Temperature</td>
                                                <td>{{ pump["daq"][cwitempi].toFixed(0) }} °C</td>
                                            </tr>
                                            <tr>
                                                <td>CoolingWater Out Temperature</td>
                                                <td>{{ pump["daq"][cwotempi].toFixed(0) }} °C</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import vhmi_mcdvars_list from '@/plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "ADKOilSystemTab",
  props: {
      pump: null
  },
  data(){
      return {
          u16hmistatei: null,
          instpumpsi: null,

          p1ntc1i:null, p1ntc2i:null, p1ntc3i:null,
          p2ntc1i:null, p2ntc2i:null, p2ntc3i:null,

          ohtempi:null, o1psi: null,
          o2psi:null,opfei:null,
          o3psi:null, octempi: null,
          cwotempi:null, cwitempi: null
      }
  },
  computed: {
      pumpIsDual() {
          return (this.pump["daq"][this.instpumpsi] == 2);
      }
  },
  created(){
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");
    this.instpumpsi = vhmi_mcdvars_list.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");

    this.p1ntc1i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC1.s16TempSense_Deg");
    this.p1ntc2i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC2.s16TempSense_Deg");
    this.p1ntc3i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC3.s16TempSense_Deg");

    this.p2ntc1i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC1.s16TempSense_Deg");
    this.p2ntc2i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC2.s16TempSense_Deg");
    this.p2ntc3i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC3.s16TempSense_Deg");

    this.ohtempi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_OilHot.s16TempSense_Deg");
    this.o1psi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.pressureSensor_Oil1.s16PressureSense_PSI_LPF32ms");
    this.o2psi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.pressureSensor_Oil2.s16PressureSense_PSI_LPF32ms");
    this.opfei = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.oilPump.s32PumpFlowEstimate");
    this.o3psi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.pressureSensor_Oil3.s16PressureSense_PSI_LPF32ms");
    this.octempi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_OilCold.s16TempSense_Deg");
    this.cwotempi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_CoolingWater_Out.s16TempSense_Deg");
    this.cwitempi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_CoolingWater_In.s16TempSense_Deg");
  }
}
</script>

<style scoped>
.gtm-img-wrapper {
    position:relative;
    max-width: 100%;
    min-height:100%;
    height: auto;
    width: auto\9; /* ie8 */
}

#p2ntc1_dual   { position:absolute; top:15%; right:55%; font-size: 1em; }
#p2ntc2_dual   { position:absolute; top:15%; right:47%; font-size: 1em; }
#p2ntc3_dual   { position:absolute; top:15%; right:39%; font-size: 1em; }
#p1ntc1_dual   { position:absolute; top:35%; right:55%; font-size: 1em; }
#p1ntc2_dual   { position:absolute; top:35%; right:47%; font-size: 1em; }
#p1ntc3_dual   { position:absolute; top:35%; right:39%; font-size: 1em; }
#ohtemp_dual { position:absolute; top:48%; right:92%; font-size: 1em; }
#o1psi_dual  { position:absolute; top:55%; right:92%; font-size: 1em; }
#o2psi_dual  { position:absolute; top:57%; right:64%; font-size: 1em; }
#opfe_dual   { position:absolute; top:57%; right:56%; font-size: 1em; }
#o3psi_dual  { position:absolute; top:57%; right:37%; font-size: 1em; }
#octemp_dual { position:absolute; top:48%; right:5%; font-size: 1em; }
#cwotemp_dual{ position:absolute; bottom:18%; right:36%; font-size: 1em; }
#cwitemp_dual{ position:absolute; bottom:18%; right:28%; font-size: 1em; }

#p1ntc1   { position:absolute; top:18%; right:55%; font-size: 1em; }
#p1ntc2   { position:absolute; top:18%; right:47%; font-size: 1em; }
#p1ntc3   { position:absolute; top:18%; right:40%; font-size: 1em; }
#ohtemp { position:absolute; top:34%; right:92%; font-size: 1em; }
#o1psi  { position:absolute; top:43%; right:92%; font-size: 1em; }
#o2psi  { position:absolute; top:45%; right:64%; font-size: 1em; }
#opfe   { position:absolute; top:45%; right:56%; font-size: 1em; }
#o3psi  { position:absolute; top:45%; right:37%; font-size: 1em; }
#octemp { position:absolute; top:34%; right:3%; font-size: 1em; }
#cwotemp{ position:absolute; bottom:21%; right:36%; font-size: 1em; }
#cwitemp{ position:absolute; bottom:21%; right:28%; font-size: 1em; }
</style>