<template>
  <div>
    <v-container>
      <v-row v-if="view_edit_controls">
        <v-col>      
          <EditButton v-model="edit_content" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-container>
              <v-row>
                <v-col>                  
                  <v-text-field v-if="edit_content" placeholder="Title" v-model="title" class="shrink"
                    filled dense hide-details :rules="[v => v.length<=50 || 'Max 50 characters']" counter>
                  </v-text-field>
                  <template  v-else>
                    <h1>{{ title }}</h1>
                  </template>
                  <b>Resource Type: </b>
                  <v-text-field v-if="edit_content" placeholder="Resource Type" v-model="resourcetype" class="shrink"
                    filled dense hide-details :rules="[v => v.length<=10 || 'Max 10 characters']" counter>
                  </v-text-field>
                  <span v-else style="color:green">{{ resourcetype }}</span>
                </v-col>
              </v-row>
              <v-row v-if="edit_content">
                <v-col>
                  [<a href="#" @click="edit_raw_html = !edit_raw_html">Toggle EditMode</a>]
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                    <div v-if="edit_content">
                      <v-textarea v-if="edit_raw_html" v-model="content"></v-textarea>
                      <tiptap-vuetify v-else v-model="content" :extensions="extensions" placeholder="Enter content here…" />                      
                    </div>
                    <div v-else class="rsrccontent" v-html="content"></div>
                </v-col>
              </v-row>
              <v-row v-if="resourcetype=='video'">
                <v-col>
                  <video-player :videosrc="video_source" :steps="video_steps" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card-text>
                    <v-simple-table v-if="attachments_list.length > 0">
                      <template v-slot:default>
                      <thead>
                          <tr>
                          <th class="text-left"> ID </th>
                          <th class="text-left"> Attachment </th>
                          <th class="text-center"  v-if="edit_content"> Delete </th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="rc,idx in attachments_list" :key="idx" >
                          <td class="text-left">{{ rc.id }}</td>
                          <td class="text-left"><a :href="$rsrcdburl+'/files/download/'+rc.sname">{{ rc.fname }}</a></td>
                          <td class="text-center"  v-if="edit_content">
                              <v-btn dark small color="error" @click="deleteAttachmentClick(rc.sname,idx)">
                              <v-icon dark> mdi-minus </v-icon>
                              </v-btn>
                          </td>
                          </tr>
                      </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-if="edit_content">
                <v-col>
                    <label>Attachments (.txt, .zip, .pdf, .csv)</label><br/>
                    <input type="file" @change="handleFileUpload($event)" />
                    <progress max="100" :value.prop="upload_percentage"></progress>&nbsp;
                    <v-btn class="primary" @click="submitFile">Upload</v-btn>
                    <span style="color:#FF0000">{{upload_error_msg}}</span>
                </v-col>
              </v-row>
              <v-row v-if="edit_content">
                <v-col>
                      <v-card-text>
                        <v-btn class="primary" @click="saveChangesClick">Save Changes</v-btn>  
                        <v-dialog v-model="saveChangesDialog" width="500">
                          <v-card>
                            <v-card-title class="text-h5 grey lighten-2"> Confirm Save </v-card-title>
                            <v-card-text> Are you sure you want to Save changes? </v-card-text>
                            <v-card-text> {{ save_error_msg }} </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="save_changes" > Confirm </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog> 
                        &nbsp;
                        <v-btn class="primary" @click="revert_changes">Revert Changes</v-btn>
                      </v-card-text>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import the component and the necessary extensions
import { TiptapVuetify, History, Heading, Bold, Italic, Strike, Underline, Code, Link, Blockquote } from 'tiptap-vuetify'
import { Paragraph, BulletList, OrderedList, ListItem, HardBreak, HorizontalRule } from 'tiptap-vuetify'
import { Table,TableRow,TableHeader,TableCell } from 'tiptap-vuetify'
import EditButton from "../components/EditButton.vue";
import _ from 'lodash';

//import videojs from 'video.js';
import VideoPlayer from '@/components/VideoPlayer.vue';

export default {
  name: "ResourcePage",
  // specify TiptapVuetify component in "components"
  components: { TiptapVuetify, EditButton, VideoPlayer },
  props: ['id'],
  data: () => ({
    // declare extensions you want to use
    extensions: [
       History,[Heading, {
        options: {
          levels: [2, 3, 4]
        }
      }],
       Bold, Italic, Strike, Underline, Code, Link, Blockquote,
       Paragraph, BulletList, OrderedList, ListItem, HardBreak, HorizontalRule,
      Table,TableRow,TableHeader,TableCell,
    ],
    
    resourcetype: "",
    resourcetype_original: "",
    title: "",
    title_original: "",
    content: "",
    content_original: "",
    view_edit_controls: true,
    edit_content : false,
    edit_raw_html: false,
    
    video_source: null,
    video_steps: null,

    attachments_list: [],
    upload_filename: "",
    upload_percentage: 0,
    upload_error_msg: null,

    save_error_msg: null,
    saveChangesDialog: false,
  }),
  mounted(){
    //this.$emit('loadstart');
    const useraccessdict = this.$store.getters.get_useraccessdict();
    //console.log(useraccessdict);
    this.view_edit_controls = (useraccessdict != null) && useraccessdict["edit_resources"];
    if (!isNaN(parseInt(this.id)) || (this.id == 'new')) this.fetch_page_data(this.id);
    else this.edit_content = true;
  },
  methods: {
    fetch_page_data(id) {
      this.$emit('loadstart');
      const get_dict = {'code': 'get_page_data', 'id': id}
      this.$api.post(this.$rsrcdburl + "/getpagedata", get_dict)
      .then(response => {
        //console.log(response.data);
        const pagedata = response.data;
        if (pagedata['code'] == 'get_page_data') {
          this.resourcetype_original = pagedata['type'];
          this.title_original = pagedata['title'];
          this.content_original = pagedata['content'];
          this.resourcetype = _.cloneDeep(this.resourcetype_original);
          this.title = _.cloneDeep(this.title_original);
          this.content = _.cloneDeep(this.content_original);

          if (this.resourcetype == 'video'){
            this.setup_video_content(pagedata['videocontent']);
          }
          this.fetch_page_files(id);
        }
        this.$emit('loadcomplete');
        //alert(typeof this.dtc_details.details);
      });
    },
    setup_video_content(videodata){
      this.video_source = this.$rsrcdburl+'/static/'+videodata['filename'];//Seal Assembly Removal.m4v
      var videosteps = [];
      videodata['steps'].forEach((hms) => {
        var hmssplit = hms.split(':'); // split it at the colons
        var seconds = 0;
        //console.log("hms",hms,hmssplit);
        hmssplit.forEach( (a) => {
          seconds = (seconds * 60) + (+a); 
          //console.log(a, seconds);
        });
        videosteps.push(seconds);
      });
      this.video_steps = videosteps;
    },
    fetch_page_files(id) {
      this.$emit('loadstart');
      //const get_dict = {'code': 'get_page_files', 'pageid': id}
      this.$api.get(this.$rsrcdburl + "/files/list/"+id)
      .then(response => {
        //console.log(response.data);
        const pagefilesdata = response.data;
        if (pagefilesdata['code'] == 'get_page_files') {
          if (pagefilesdata['success']) {
            this.attachments_list = pagefilesdata['filelist'];
          }
          else {
            alert(pagefilesdata['errmsg']);
          }
        }
        this.$emit('loadcomplete');
        //alert(typeof this.dtc_details.details);
      });
    },
    handleFileUpload(event) {
      this.upload_filename = event.target.files[0];
    },
    submitFile(){
      this.upload_error_msg = "";
      let formdata = new FormData();
      formdata.append("file", this.upload_filename);
      this.$api.post(this.$rsrcdburl + '/files/upload/'+this.id, formdata, 
        { 
          onUploadProgress: function (progressEvent) {
                              this.upload_percentage = parseInt(Math.round((progressEvent.loaded/progressEvent.total)*100));
                            }.bind(this)
        }).then(response => {
                //console.log(response.data);
                if (response.data['code'] == 'file_upload') {
                  if (response.data['success'])
                  {
                    console.log("file upload success!");
                    this.attachments_list.push(response.data['filedetail']);
                  } else {
                    this.upload_error_msg = response.data['errmsg'];
                  }
                } else {
                  this.upload_error_msg = "Invalid response!";
                }
              });
    },
    deleteAttachmentClick(sname,idx){
      console.log("delete file: "+sname);
      this.$emit('loadstart');
      this.$api.post(this.$rsrcdburl + "/files/delete", {"code":"file_delete","sname":sname,"pageid":this.id})
      .then(response => {
        //console.log(response.data);
        const delfilesdata = response.data;
        if (delfilesdata['code'] == 'file_delete') {
          if (delfilesdata['success']) {
            this.attachments_list.splice(idx,1);
          }
          else {
            alert(delfilesdata['errmsg']);
          }
        }
        this.$emit('loadcomplete');
        //alert(typeof this.dtc_details.details);
      });
    },
    saveChangesClick(){
      this.save_error_msg = null;
      this.saveChangesDialog = true;
    },
    save_changes() {
      //console.log(this.dtc_details);
      this.save_error_msg = null;
      const changes_dict = {'code': 'save_changes',
        'id':this.id,'type':this.resourcetype, 'title': this.title,'content': this.content
      }
      this.$api.post(this.$rsrcdburl + '/update', changes_dict)
              .then(response => {
                //console.log(response.data);
                if (response.data['code'] == 'save_changes') {
                  if (response.data['success'])
                  {
                    this.saveChangesDialog = false;
                    this.edit_content = false;
                    const id=response.data['id'];
                    if (id != undefined)
                      this.$router.push('/resource/'+id);
                      this.fetch_page_data(this.id);
                  } else {
                    this.save_error_msg = response.data['errmsg'];
                  }
                } else {
                  this.save_error_msg = "Invalid response!";
                }
              });
    },
    revert_changes() {
      this.resourcetype = _.cloneDeep(this.resourcetype_original);
      this.title = _.cloneDeep(this.title_original);
      this.content = _.cloneDeep(this.content_original);
      this.fetch_page_data(this.id);
    },
  }
}
</script>

<style scoped>
  .rsrccontent ::v-deep table {
    border: 1px solid black;
    border-collapse: collapse;
    width: 100%;
  }
  .rsrccontent ::v-deep tr:nth-child(even) {
    background-color: #D6EEEE;
  }
  .rsrccontent ::v-deep th {
    border: 1px solid black;
    padding: 9px;
    text-align: left;
    vertical-align: bottom;
  }
  .rsrccontent ::v-deep th>p {
    margin: 0 auto;
  }
  .rsrccontent ::v-deep td {
    border: 1px solid black;
    padding: 9px;
    vertical-align: center;
  }
  .rsrccontent ::v-deep td>p {
    margin: 0 auto;
  }
</style>