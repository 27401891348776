<template>
<div id="hmireference">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>HMI Reference</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3" v-for="imgdict,idx in imglist" :key="idx">
          <v-hover v-slot="{ hover }" open-delay="200">
            <router-link :to="{ path: 'hmiscreen', query: { name: imgdict['name'] }}">
              <v-card :elevation="hover ? 16 : 2">
                <v-card-text class="blue darken-2 white--text">
                  {{ imgdict['name'] }}
                </v-card-text>
                <v-img lazy-src="@/assets/HMI001.jpg" :src="getImgUrl(imgdict['url'])" width="100vw" contain>
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </router-link>
          </v-hover>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  export default {
    name: "HMIReference",

    data: () => ({
        imgurl: "@/assets/avatar.png",
        imglist: [],
        testimglist: [{'name':'Avatar', 'url': "HMI054.jpg"},
                  {'name':'Avatar1', 'url': "HMI085.jpg"},
                  {'name':'Avatar2', 'url': "HMI054.jpg"},
                  {'name':'Avatar3', 'url': "HMI085.jpg"},
                  {'name':'Avatar4', 'url': "HMI054.jpg"},
                  {'name':'Avatar1', 'url': "HMI085.jpg"},
                  {'name':'Avatar2', 'url': "HMI054.jpg"},
                  {'name':'Avatar3', 'url': "HMI085.jpg"},
                  {'name':'Avatar4', 'url': "HMI054.jpg"}]
    }),
    mounted() {
      //this.$emit('loadstart');
      //console.log(this.$dtcdburl + "/hmi/pages");
      this.$api.get(this.$dtcdburl + "/hmi/pages")
      .then(response => {
        this.imglist = response.data;
        //console.log(response.data);
        //this.$emit('loadcomplete');
      });
    },
    methods: {
      getImgUrl(pic) {
        //return require('@/assets/'+ pic);
        return this.$dtcdburl + '/static/'+ pic;
      }
    }
  }
</script>