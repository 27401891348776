<template>
  <div>
    <template v-if="pump['daq'].length > 0">
      <div class="mt-1">
      &nbsp;
      <v-btn class="primary" @click="getDTClabels(au16LatchedDiagCodeList)">Refresh</v-btn>
      &nbsp;
      <a class="smart-collapse" :href="`events?pumpip=${pump.ipaddr}`">View Eventlog</a>
      </div>
      <v-simple-table height="300px">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Code</th>
          <th class="text-left">Description</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="code,idx in dtcsWithTitle" :key="idx">
          <td><a :href="`dtc/${code['shortLabel']}`" >{{ code['shortLabel'] }}</a></td>
          <td>{{ code['label'] }}</td>
        </tr>
        </tbody>
      </template>
      </v-simple-table>
    </template>
    <v-container v-else>
      <v-row>
          <v-col>
              <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "DiagnosticsTab",
  props: {
      pump: null
  },
  data() {
    return {
      au16LatchedDiagCodeListi: null,
      dtcsWithTitle: null
    }
  },
  created() {
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");

    this.au16LatchedDiagCodeListi = vhmi_mcdvars_list.indexOf("app.diagProfile.au16LatchedDiagCodeList");
  },
  mounted () {
    if (this.pump['daq'].length > 0)
      this.getDTClabels(this.au16LatchedDiagCodeList);
  },
  computed: {
    au16LatchedDiagCodeList: function() {
      const daqdata = this.pump["daq"];
      return daqdata[this.au16LatchedDiagCodeListi];
    }
  },
  methods: {
    getDTClabels(dtcslist) {
      this.dtcsWithTitle = []
      //this.$emit('loadstart');
      this.$api.post(this.$dtcdburl + "/code", dtcslist.filter((dtc) => dtc!=0))
      .then(response => {
        //console.log(response.data);
        this.dtcsWithTitle = response.data;
        //this.$emit('loadcomplete');
      });
    },
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>