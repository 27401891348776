<template>
<div id="loginpage">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Login Redirection</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container class="mt-5">
      <v-row>
        <v-col cols="12" sm="12">
        {{ message_description }}
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="this.username === null"  cols="12" sm="2">
        <v-btn color="primary" @click.prevent="start_user_auth">Login</v-btn>
        </v-col>
        <v-col v-else cols="12" sm="6">
          <v-btn color="primary" @click.prevent="del_user_session">Logout</v-btn>
          <div>Simulating: {{ simusername }}&nbsp;&nbsp;&nbsp;<v-btn @click="reset_user_sim">Reset User</v-btn></div>
        </v-col>
      </v-row>

      <v-row v-if="this.username != null"  >
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title> Telegram Setup </v-card-title>
            <v-card-subtitle>
              1. Install Telegram app on your phone <br />
              2. Search "QuantumSPT" <br />
              3. Start chat with @quantumspt_bot <br />
              4. Copy the PumpReporter Code obtained in the chat <br />
              5. Enter and Submit the Code here
            </v-card-subtitle>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"> Email </th>
                    <th class="text-left"> PumpReporter Code </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">{{ username }}</td>
                    <td class="text-left">
                      <v-text-field v-model="telegram_chat_id"
                        hint="Enter PumpReporter Code"
                        :type="chatidshow ? 'text' : 'password'"
                        @click:append="chatidshow = !chatidshow"
                        :append-icon="chatidshow ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[chatidrules.required]">
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      <v-btn color="primary" @click.prevent="refresh_chatid">Refresh</v-btn>
                    </td>
                    <td class="text-left">
                      <v-btn color="primary" @click.prevent="submit_chatid">Submit</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        
        <v-col cols="12" md="7">
          <v-card>
            <v-card-title> Sessions </v-card-title>
            <v-card-subtitle>
              ses: {{ this.$store.getters.get_session_id() }}<br/>
              sid: {{ this.$store.state.socketid }}
            </v-card-subtitle>
            <v-card-text>
              <v-simple-table v-if="old_usersessions_list.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"> DateTime </th>
                      <th class="text-left"> SessionID </th>
                      <th class="text-center"> CopySession </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="session,idx in old_usersessions_list" :key="idx" >
                      <td class="text-left">{{ session.datetime }}</td>
                      <td class="text-left">{{ session.sessionid }}</td>
                      <td class="text-center">
                        <v-btn dark small color="blue" @click="copySession(session.sessionid)">
                          <v-icon dark> mdi-chevron-triple-right </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-dialog v-model="loading_session" hide-overlay persistent width="300" >
              <v-card color="primary" dark>
                <v-card-text>
                  Copying Session. Please wait...
                  <!--v-progress-linear indeterminatecolor="white" class="mb-0"></v-progress-linear-->
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</div>
</template>

<script>
  export default {
    name: "LoginPage",

    data: () => ({
      message_description: null,
      username: null,
      simusername: null,
      telegram_chat_id: null,
      chatidshow: false,
      chatidrules: {
        required: value => !!value || 'Enter Telegram chat-code for PumpReporter connection.',
        min: v => v.length >= 8 || 'Invalid Code',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      old_usersessions_list: [],
      loading_session: false
    }),
    /*computed: {
      username(){
        return this.$store.getters.get_username();
      }
    },*/
    mounted() {
      if ("code" in this.$route.query) {
        //console.log(this.$route.fullPath);
        this.authenticate_user(this.$route.fullPath);
      } else {
        this.update_user_logstatus()
        //this.start_user_auth();//Auto Start user authentication
      }
    },
    methods: {
      update_user_logstatus(){
        this.username = this.$store.getters.get_username();
        //console.log(username);
        if (this.username == null){
          this.message_description = "This will take you to the Microsoft sign in page.\n\r \
                              After you have signed in, you will be returned to this site."
        } else {
          this.$api.post(this.$orgmanurl + '/users/checkuser')
          .then(response => {
            const statusobj = response.data;
            if (statusobj['code']=='checkuser') {
              this.message_description = "Your are logged in as: " + statusobj['username'];//this.username;
              //this.message_description += " ("+ this.$store.getters.get_session_id() +")";
              this.simusername = this.$store.getters.get_simusername();
              this.get_last_user_sessions();
              this.refresh_chatid();
            }
          });
        }
      },
      start_user_auth() {
        this.$api.get(this.$orgmanurl + '/users/login')
              .then(response => {
                const statusobj = response.data;
                //console.log(statusobj);
                if (statusobj["status"] == "ERROR") {
                  this.message_description = statusobj["ERROR_MSG"];
                }
                else if (statusobj["status"] == "LOGIN_REDIRECT") {
                  const sessionid = statusobj["SESSION_TOKEN"];
                  const username = null;
                  //console.log(sessionid);
                  this.$store.commit("set_user_session", {sessionid, username});
                  window.location.href = statusobj["auth_uri"];
                }
                //this.$emit('loadcomplete');
              });
      },
      authenticate_user (fullPath) {
        this.$api.get(this.$orgmanurl + '/users'+fullPath)
              .then(response => {
                const statusobj = response.data;
                //console.log(statusobj);
                if (statusobj["status"] == "ERROR")
                  this.message_description = statusobj["ERROR_MSG"];
                else if (statusobj["status"] == "LOGIN_SUCCESS") {
                  const sessionid = statusobj["SESSION_TOKEN"]
                  const username = statusobj["USERNAME"]
                  const useraccessdict = statusobj["USERACCESS"]
                  this.$store.commit("set_user_session", {sessionid, username});
                  this.$store.commit("set_user_sim", username);
                  this.$store.commit("set_useraccessdict", useraccessdict);
                  this.update_user_logstatus();
                  this.$socket.connect();
                  this.refresh_chatid();
                }
                //this.$emit('loadcomplete');
              });
        return;
      },
      reset_user_sim() {
        //console.log(newchilddata);
        this.$api.get(this.$orgmanurl + '/users/resetsim')
                .then(response => {
                  if (response.data['code'] == 'reset_simulated_user') {
                    if (response.data['success'])
                    {
                      const useraccessdict = response.data["USERACCESS"];
                      this.$store.commit("set_useraccessdict", useraccessdict);
                      const username = this.$store.getters.get_username();
                      this.$store.commit("set_user_sim", username);
                      this.simusername = username;
                    } else {
                      alert(response.data['errmsg']);
                    }
                  } else {
                    this.adduser_error_msg = "Invalid response!";
                  }
                });

      },
      del_user_session() {
        this.$socket.disconnect();
        this.$api.get(this.$orgmanurl + '/users/logout/'+ this.$store.getters.get_session_id());
        this.$store.commit("del_session_id");
        //window.location.href = "https://mgautam.b2clogin.com/mgautam.onmicrosoft.com/b2c_1_signupsignin1/oauth2/v2.0/logout?post_logout_redirect_uri=http%3A%2F%2Flocalhost:8080%2Flogin]";
        this.update_user_logstatus();
      },
      submit_chatid() {
        const user_details_dict = {"code":"register_user_details", "chatid": this.telegram_chat_id};
        this.$api.post(this.$pumpreporterurl + '/register',user_details_dict)
        .then(response => {
          if (response.data['code'] == 'register_user_details') {
            if (response.data['success'])
            {
              alert("Chat id ("+this.telegram_chat_id+") registered successfully");
              this.loading_session = false;
            } else {
              alert(response.data['errmsg']);
            }
          } else {
            //this.old_usersessions_list = [];
          }
        });
      },
      refresh_chatid() {
        this.$api.post(this.$pumpreporterurl + '/user')
        .then(response => {
          if (response.data['code'] == 'get_user_details') {
            if (response.data['success'])
            {
              this.telegram_chat_id = response.data['chatid'];
              this.loading_session = false;
            } else {
              alert(response.data['errmsg']);
            }
          } else {
            //this.old_usersessions_list = [];
          }
        });
      },
      get_last_user_sessions(){
        this.$api.get(this.$orgmanurl + '/users/lastsessions')
                .then(response => {
                  if (response.data['code'] == 'list_old_usersessions') {
                    if (response.data['success'])
                    {
                      this.old_usersessions_list = response.data['old_usersessions_list']
                    } else {
                      alert(response.data['errmsg']);
                    }
                  } else {
                    this.old_usersessions_list = [];
                  }
                });
      },
      copySession(oldsid){
        //console.log(oldsid)
        this.loading_session = true;

        this.$api.post(this.$orgmanurl + '/users/loadsession',{"oldsession":oldsid})
                .then(response => {
                  if (response.data['code'] == 'load_old_usersession') {
                    if (response.data['success'])
                    {
                      let vhmi_pump_ids = response.data['vhmi_pump_ids'];
                      let vhmi_pump_ips = response.data['vhmi_pump_ips'];
                      /*console.log(vhmi_pump_ids);
                      console.log(vhmi_pump_ips);*/
                      /*this.$store.state.vhmipumpids = vhmi_pump_ids;
                      this.$store.state.vhmipumpips = vhmi_pump_ips;*/
                      //console.log('copySession-set_vhmi_pumps', {vhmi_pump_ids, vhmi_pump_ips});
                      this.$store.commit('set_vhmi_pumps', {vhmi_pump_ids, vhmi_pump_ips});
                      //console.log('copySession-set_vhmi_pumps', {vhmi_pump_ids, vhmi_pump_ips});
                      const authtoken = this.$store.getters.get_session_id();
                      const monpumpsdict = {"authtoken": authtoken,"pumpidlist":this.$store.getters.get_vhmi_pumpids()};
                      this.$socket.emit("configure_monitored_pumps", monpumpsdict);
                    } else {
                      alert(response.data['errmsg']);
                    }
                    this.loading_session = false;
                  } else {
                    //this.old_usersessions_list = [];
                  }
                });
      }
    }
  }
</script>