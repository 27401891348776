// This file is automatically generated...

const vhmi_mcdvars_list = [
	"app.applicationInfo.codeInformationBlock_App.stVersionInformation.au8VersionFullString",
	"app.applicationInfo.codeInformationBlock_BootLoader.stVersionInformation.au8VersionFullString",
	"app.diagProfile.au16LatchedDiagCodeList",
	"app.hmiViewModel.s32PressureDisplay",
	"app.hmiViewModel.s32TotalRunTimeFromHMI_Minutes",
	"app.hmiViewModel.u16StateHMI",
	"app.machine.multiplePumpManager.u8InstalledPumpCount",
	"app.machine.multiplePumpManager.u8PumpEnableFlags",
	"app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].s16VolumeFlowRate",
	"app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].u16StrokeRate",
	"app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].s16VolumeFlowRate",
	"app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].u16StrokeRate",
	"app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC1.s16TempSense_Deg",
	"app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC2.s16TempSense_Deg",
	"app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC3.s16TempSense_Deg",
	"app.machine.pump1.actuatorController.pumpThermalSupervisor.NTC4.s16TempSense_Deg",
	"app.machine.pump1.actuatorController.pumpThermalSupervisor.OnBoardNTC.s16TempSense_Deg",
	"app.machine.pump1.actuatorController.s32MechanicalPowerEstim_Forward_Filt",
	"app.machine.pump1.actuatorController.s32MechanicalPowerEstim_Reverse_Filt",
	"app.machine.pump1.driveController.driveA1000.stDriveStateFeedback.s16HeatsinkTemperature",
	"app.machine.pump1.pumpController.pumpFluidStateObserver.cylinder1.temperatureSensor_HPCV.s16TempSense_Deg",
	"app.machine.pump1.pumpController.pumpFluidStateObserver.cylinder2.temperatureSensor_HPCV.s16TempSense_Deg",
	"app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].s16VolumeFlowRate",
	"app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].u16StrokeRate",
	"app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].s16VolumeFlowRate",
	"app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].u16StrokeRate",
	"app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC1.s16TempSense_Deg",
	"app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC2.s16TempSense_Deg",
	"app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC3.s16TempSense_Deg",
	"app.machine.pump2.actuatorController.pumpThermalSupervisor.NTC4.s16TempSense_Deg",
	"app.machine.pump2.actuatorController.pumpThermalSupervisor.OnBoardNTC.s16TempSense_Deg",
	"app.machine.pump2.actuatorController.s32MechanicalPowerEstim_Forward_Filt",
	"app.machine.pump2.actuatorController.s32MechanicalPowerEstim_Reverse_Filt",
	"app.machine.pump2.driveController.driveA1000.stDriveStateFeedback.s16HeatsinkTemperature",
	"app.machine.pump2.pumpController.pumpFluidStateObserver.cylinder1.temperatureSensor_HPCV.s16TempSense_Deg",
	"app.machine.pump2.pumpController.pumpFluidStateObserver.cylinder2.temperatureSensor_HPCV.s16TempSense_Deg",
	"app.machine.sapCommandManager.pressureSetpointLogic.boPressureSetpoint_Low_En",
	"app.machine.sapCommandManager.pressureSetpointLogic.s32PressureSetpoint_High",
	"app.machine.sapCommandManager.pressureSetpointLogic.s32PressureSetpoint_Low",
	"app.machine.sapController.machineHPFluidController.dumpValveManager.pressureSensor_Air.s16PressureSense_PSI_LPF32ms",
	"app.machine.sapController.machineHPFluidController.dumpValveManager.thermalSensor_Dump.s16TempSense_Deg",
	"app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Error_Threshold_Operator",
	"app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Filt1",
	"app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Filt2",
	"app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32OrificeSize_EstimInstalledInHead",
	"app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32OrificeSize_Specified",
	"app.machine.sapController.machineLPFluidController.boostPumpManager.boBoostPump_IsInstalled",
	"app.machine.sapController.machineLPFluidController.pressureSensor_Cut1.s16PressureSense_PSI_LPF32ms",
	"app.machine.sapController.machineLPFluidController.pressureSensor_Cut2.s16PressureSense_PSI_LPF32ms",
	"app.machine.sapController.machineLPFluidController.thermalSensor_CuttingWater.s16TempSense_Deg",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.oilPump.s32PumpFlowEstimate",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.pressureSensor_Oil1.s16PressureSense_PSI_LPF32ms",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.pressureSensor_Oil2.s16PressureSense_PSI_LPF32ms",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.pressureSensor_Oil3.s16PressureSense_PSI_LPF32ms",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_CoolingWater_In.s16TempSense_Deg",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_CoolingWater_Out.s16TempSense_Deg",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_OilCold.s16TempSense_Deg",
	"app.machine.sapController.machineOilSystemController.oilSystemObserver.thermalSensor_OilHot.s16TempSense_Deg",
	"app.machine.sapController.pressureEstimator.s32Pressure_Estim",
	"app.machine.sapController.sapControlMode.pressureController.s32PressureControlTolerance_Error_FeedbackLow",
	"app.machine.sapController.sapControllerSM.boIsError_Aggregated_Latched",
	"app.machine.sapController.sapControllerSM.boIsLatchedDTC_Warning",
]

export default vhmi_mcdvars_list;
