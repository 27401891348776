<template>
    <v-navigation-drawer v-model="drawer" v-if="visibility" permanent color="#F4F5F9" width="225" clipped expand-on-hover app>
        <v-list class="mt-5" nav dense>
            <v-list-item-group v-model="selectedItem" color="deep-purple">
                <v-list-item v-for="(item, i) in items" :key="i" :to="item.route">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                </v-list-item> 
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    props:{
         visibility: Boolean,
    },
    data: () => ({
        selectedItem: 0,
        drawer: null,
        items: [
            {icon: 'fas fa-home', text: 'Dashboard', route: '/'},
            {icon: 'mdi-alert-octagon', text: 'Diagnostic Codes', route: '/diagnostics'},
            //{icon: 'mdi-cloud-upload', text: 'Resources', route: '/resources'},
            {icon: 'fas fa-building', text: 'Sites', route: '/sites'},
            {icon: 'fas fa-users', text: 'Users', route: '/users'},
            {icon: 'mdi-water-pump', text: 'Pumps', route: '/pumps'},
            {icon: 'fas fa-chart-line', text: 'VHMI', route: '/vhmi'},
            {icon: 'mdi-router-wireless', text: 'Routers', route: '/routers'},
            {icon: 'mdi-gradient-vertical', text: 'HMI Reference', route: '/hmiref'},
            {icon: 'fas fa-lock', text: 'Login', route: '/login'},
        ]
    }),
    /*computed: {
        secured_menu_items(){
            const useraccessdict = this.$store.getters.get_useraccessdict();
            //console.log(useraccessdict);
            const view_resources_link = (useraccessdict != null) && useraccessdict["view_resources"];

            if (view_resources_link) return this.items;
            else return this.items.filter( (item) => (item.text != "Resources") );
        }
    }*/
}
</script>
<style scoped>
div >>> .v-list a {
    text-decoration: none;
}
@media print {
    .v-navigation-drawer {
        visibility: hidden;
    }
}
</style>