<template>
<div id="routerspage" style="height:86vh">
    <!--v-toolbar class="mt-2" flat>
      <v-toolbar-title>Routers Directory</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider-->
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col class="fill-height" cols="3">        
        <v-card class="fill-height"  style="overflow-y: scroll">
          <v-card-title>
            <h3>Organisation</h3>
          </v-card-title>
          <MyTreeView @ouclicked="fetchRouters" @datadropped="onRouterDrop"/>
        </v-card>
      </v-col>

      <v-col  cols="9" class="text-center fill-height">
        <v-card class="fill-height" v-if="datafetch_in_progress" cols="9">
          <v-row class="fill-height d-flex justify-center">
            <v-col cols="6">
              <LoadingBar />
            </v-col>
          </v-row>
        </v-card>
        <v-card class="fill-height" v-else-if="selectedNode !== null" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <h3>{{ selectedNode.name }} - Routers ({{ routerslist.length }})</h3>
          <v-spacer class="d-none d-md-block"></v-spacer>
          <div class="d-flex justify-space-around pr-6">
          <v-btn-toggle v-model="isRecursiveFetch" color="black">
            <v-btn :value="true">All</v-btn>
            <v-btn :value="false">Child</v-btn>
          </v-btn-toggle>
          </div>
          <v-dialog v-if="add_routers_permission" v-model="addRouterDialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Add Router
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Add New Router</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Customer*" v-model="addRouterData.cname" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Routerlabel*" v-model="addRouterData.nname" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text> {{ addrouter_error_msg }} </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addRouterClose()"> Close </v-btn>
                <v-btn color="blue darken-1" text @click="addRouterConfirm"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-dialog v-model="deleteRouterDialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
            <v-card-text> Are you sure you want to delete {{ seletectedDelRouter["nname"] }} ? </v-card-text>
            <v-card-text> {{ delrouter_error_msg }} </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleteRouterConfirm" > Delete </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 
        <v-dialog v-model="moveRoutertoOUDialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> Confirm Move </v-card-title>
                <v-card-text> Are you sure you want to move "{{ selectedRoutertoMove["name"] }}" to "{{ selectedRoutertoMove["newsitename"] }}"? </v-card-text>
                <v-card-text> {{ moveRoutertoOU_error_msg }} </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="moveConfirmClick" > Move </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  
        <v-scroll-y-transition mode="out-in">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center"> Move Router </th>
                    <th class="text-left"> Nickname (Details)</th>
                    <th class="text-left"> IP Address</th>
                    <th class="text-left"> Customer </th>
                    <!--th class="text-left"> ID </th-->
                    <th class="text-left"> Delete </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="router,idx in routerslist" :key="idx" >
                    <td>
                      <v-btn dark small color="green" draggable @dragstart="startDrag($event, router.id, router.nname)">
                        <v-icon dark> mdi-chevron-triple-left </v-icon>
                      </v-btn>
                    </td>
                    <td class="text-left"><a :href="`router/${router.nname}`">{{ router.nname }}</a></td>
                    <td class="text-left">{{ router.ip }}</td>
                    <td class="text-left">{{ router.cname }}</td>
                    <!--td class="text-left">{{ router.id }}</td-->
                    <td class="text-left">
                      <v-btn dark small color="error" @click="deleteRouterClick(idx)">
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import MyTreeView from "../components/MyTreeView";
  import LoadingBar from "../components/LoadingBar";

  export default {
    name: "RoutersPage",

  components: {
    MyTreeView,
    LoadingBar
  },
    data: () => ({
      datafetch_in_progress: false,
      selectedNode: null,
      isRecursiveFetch: false,

      addRouterDialog: false,
      addRouterData: {"nname":"", "cname":""},
      addrouter_error_msg: null,

      deleteRouterDialog: false,
      deleteRouterdx: -1,
      seletectedDelRouter: {},
      delrouter_error_msg: null,

      moveRoutertoOUDialog: false,
      moveRoutertoOU_error_msg: null,
      selectedRoutertoMove: {"id":null, "name": null, 'newsiteid': null, 'newsitename': null},
      
      routerslist: [],
      routers_in_reporter: []
    }),
    computed: {
      add_routers_permission() {
        const useraccessdict = this.$store.getters.get_useraccessdict();
        return (useraccessdict != null) && useraccessdict["addrouters"];
      }
    },
    methods: {
      fetchRouters (recvdnode) {
        this.datafetch_in_progress = true;
        this.routerslist = [];
        this.selectedNode = null;
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/routers/list',{"siteid":recvdnode.id, "recursive": this.isRecursiveFetch})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "list_company_routers")
                {
                  this.routerslist = response.data["company-routers-list"];
                  //console.log(this.routerslist);
                  this.selectedNode = recvdnode;

                  if (this.routerslist == null)
                    this.routerslist = [];
                }
                //this.$emit('loadcomplete');
                this.datafetch_in_progress = false;
              });

        return;
      },
      addRouterClose(){
        this.addrouter_error_msg = null;
        this.addRouterDialog = false;
      },
      addRouterConfirm(){
        const parent = this.selectedNode;
        //console.log(parent);
        const newchilddata = {'code':'add_company_router', "pid":parent.id, "routerdata":this.addRouterData}
        //console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/routers/add', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'add_company_router') {
                    if (response.data['success'])
                    {
                      const newchildip = response.data['ip'];
                      const newchildid = response.data['id'];
                      const newchildnname = response.data['nname'];
                      const newchildcname = response.data['cname'];
                      const newchildrouter = {"id": newchildid, "ip":newchildip, "nname":newchildnname, "cname":newchildcname};
                      this.routerslist.push(newchildrouter);
                      this.addRouterDialog = false;
                    } else {
                      this.addrouter_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.addrouter_error_msg = "Invalid response!";
                  }
                });
      },
      deleteRouterClick(routeridx){
        this.deleteRouterIdx = routeridx;
        this.delrouter_error_msg = null;
        this.deleteRouterDialog = true;
      },
      deleteRouterConfirm(){
        this.$api.post(this.$orgmanurl + '/routers/delete', {'code':'del_company_router', "routerid":this.routerslist[this.deleteRouterIdx]["id"]})
                .then(response => {
                  if (response.data['code'] == 'del_company_router') {
                    if (response.data['success'])
                    {
                      this.routerslist.splice(this.deleteRouterIdx, 1);
                      this.deleteRouterDialog = false;
                    } else {
                      this.delrouter_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.delrouter_error_msg = "Invalid response!";
                  }
                });
      },
      startDrag(evt, routerid, routernname) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('id', routerid);
        evt.dataTransfer.setData('name', routernname);
        //console.log("Dragging RouterID: " + routerid);
      },
      onRouterDrop(pcdict) {
        this.selectedRoutertoMove = pcdict;
        this.moveRoutertoOU_error_msg = ""; // Clear last error message
        this.moveRoutertoOUDialog = true;
      },
      moveConfirmClick(){
        /*console.log("MoveConfirmed!");
        console.log(this.selectedRoutertoMove);*/
        const routerid = this.selectedRoutertoMove['id'];
        const newsiteid = this.selectedRoutertoMove['newsiteid'];
        this.$api.post(this.$orgmanurl + '/routers/move', {'code':'move_router_company', "routerid":routerid, "newsiteid":newsiteid})
                .then(response => {
                  //console.log(response.data);
                  if (response.data['code'] == 'move_router_company') {
                    if (response.data['success'])
                    {
                      if (response.data['id'] === routerid) {
                        // refresh routerlist
                        for(var idx=0;idx<this.routerslist.length;idx++) {
                          if (this.routerslist[idx]["id"] == routerid) {
                            this.routerslist.splice(idx,1);
                            break;
                          }
                        }
                        this.moveRoutertoOUDialog = false;
                      } else {
                        this.moveRoutertoOU_error_msg = "Invalid Router Response!";
                      }
                    } else {
                      this.moveRoutertoOU_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.moveRoutertoOU_error_msg = "Invalid response!";
                  }
                });
      }
    }
  }
</script>