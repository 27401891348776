<template>
  <div class="dashboard">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Dashboard</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container class="mt-5">
      <!--v-row v-if="active_user_name!=null">
        <v-col>Username: {{ active_user_name }}</v-col>
      </v-row-->
      <template v-for="sitestats,idx in parent_site_stats">
        <v-row :key="idx" :set="sitehealth=parentsites_health_data[sitestats['id']]">
          <v-col cols="12" sm="6" lg="3">
            <v-simple-table>
              <template v:slot:default>
                <thead>
                <tr><th colspan="2" class="text-h4">{{ sitestats["name"] }}</th></tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-button">Total Sites</td>
                  <td class="text-body-1">{{ sitestats["numsites"] }}</td>
                </tr>
                <tr>
                  <td class="text-button">Total Users</td>
                  <td class="text-body-1">{{ sitestats["numusers"] }}</td>
                </tr>
                <tr>
                  <td class="text-button">Total Routers</td>
                  <td class="text-body-1">{{ sitestats["numrouters"] }}</td>
                </tr>
                <tr>
                  <td class="text-button">Total Pumps</td>
                  <td class="text-body-1">{{ sitestats["numpumps"] }}</td>
                </tr>
                <!--v-list-item>ID: {{ sitestats["id"] }}</v-list-item-->
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
      
          <template v-if="sitestats['id'] in parentsites_health_data">
            <v-col cols="12" sm="6" lg="3">
              <v-container>
                <v-row>
                  <v-col>
                    <apexchart type="radialBar"  height="300" :options="chart_activerouters_options" :series="activeRoutersPercentage(sitestats, sitehealth)"></apexchart>
                  </v-col>
                </v-row>
                <v-row align="stretch">
                  <v-col class="d-flex justify-center align-center text-md-overline">
                    Active Routers: {{ sitehealth["onrtrs"] }}/{{ sitestats["numrouters"] }}
                  </v-col>
                </v-row>
              </v-container> 
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <v-container>
                <v-row>
                  <v-col>
                    <apexchart type="radialBar"  height="300" :options="chart_activepumps_options" :series="activePumpsPercentage(sitestats, sitehealth)"></apexchart>
                  </v-col>
                </v-row>
                <v-row align="stretch">
                  <v-col class="d-flex justify-center align-center text-md-overline">
                    Active Pumps: {{ sitehealth["on"] }}/{{ sitestats["numpumps"] }}
                  </v-col>
                </v-row>
              </v-container> 
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <v-container>
                <v-row>
                  <v-col>
                    <apexchart type="radialBar"  height="300" :options="chart_activeactuators_options" :series="activeActuatorsPercentage(sitestats, sitehealth)"></apexchart>
                  </v-col>
                </v-row>
                <v-row align="stretch">
                  <v-col class="d-flex justify-center align-center text-md-overline">
                    Actuator State: {{ sitehealth["en"] }}/{{ sitehealth["inst"] }}
                  </v-col>                
                </v-row>
              </v-container>            
            </v-col>
            <!--v-col cols="4">
              <v-container>
                <v-row>
                  <v-col>
                    <apexchart height="390" :options="chart_activestats_options" :series="stats_chart_series(sitestats, sitehealth)"></apexchart>
                  </v-col>
                </v-row>
              </v-container> 
            </v-col-->
          </template>
          <v-col v-else cols="12" sm="6" lg="9">
            <LoadingBar />
          </v-col>      
        </v-row>
        <v-row class="mt-0 pa-0" :key="parent_site_stats.length+idx" v-if="sitestats['id'] in parentsites_health_data">
          <v-col class="mt-0 pa-0 text-end text-body-2">
            <span>* based on past 7 days data</span>
          </v-col>
        </v-row> 
      </template>
    <!--/v-container>
    <v-divider></v-divider>
    <v-container-->
      <v-row>
        <v-col cols="12" sm="6" lg="3">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 16 : 2">
              <v-card-title class="headline mb-1 blue darken-2 white--text">Help Desk</v-card-title>
              <v-card-text class="black--text text-subtitle-1">Online help and documentation for the Quantum pumps</v-card-text>
              <v-list-item-group>
                <v-list-item to="/diagnostics"><a class="text-h5" href="/diagnostics">Diagnostic Codes</a></v-list-item>
                <v-list-item to="/hmiref"><a class="text-h5" href="/hmiref">HMI Reference</a></v-list-item>
                <v-list-item v-if="view_resources_link" to="/Resources"><a class="text-h5" href="/resources">Resources</a></v-list-item>
              </v-list-item-group>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 16 : 2">
              <v-card-title class="headline mb-1 blue darken-2 white--text">Router Management</v-card-title>
              <v-card-text class="black--text text-subtitle-1">Manage VPN routers deployed on site</v-card-text>
              <v-list-item-group>
                <v-list-item to="/routersonline"><a class="text-h5" href="#">Online Routers</a></v-list-item>
                <v-list-item to="/routersearch"><a class="text-h5" href="/routersearch">Router Search</a></v-list-item>
              </v-list-item-group>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 16 : 2">
              <v-card-title class="headline mb-1 blue darken-2 white--text">Pump Management</v-card-title>
              <v-card-text class="black--text text-subtitle-1">Manage servo-pumps installed on site</v-card-text>
              <v-list-item-group>
                <v-list-item to="/pumpsonline"><a class="text-h5" href="/pumpsonline">Online Pumps</a></v-list-item>
                <v-list-item to="/pumpsearch"><a class="text-h5" href="/pumpsearch">Pump Search</a></v-list-item>
                <v-list-item to="/pumpreporter"><a class="text-h5" href="/pumpreporter">Pump Reports</a></v-list-item>
              </v-list-item-group>
            </v-card>
          </v-hover>
        </v-col>  
        <v-col cols="12" sm="6" lg="3">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 16 : 2">
              <v-card-title class="headline mb-1 blue darken-2 white--text">User Management</v-card-title>
              <v-card-text class="black--text text-subtitle-1">Manage users accessing the services of Quantum pumps</v-card-text>
              <v-list-item-group>
                <v-list-item to="/users"><a class="text-h5" href="/users">Users</a></v-list-item>
                <v-list-item to="/usersearch"><a class="text-h5" href="/usersearch">User Search</a></v-list-item>
              </v-list-item-group>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoadingBar from "../components/LoadingBar";

export default {
  name: 'DashBoard',

  components: {
    LoadingBar
  },
  data: () => ({
    active_user_name:null,
    parent_site_stats:null,
    parentsites_health_data:{},

    chart_activestats_options: {
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
                formatter: function (val) {
                  return parseFloat(val).toFixed(2) + '%'
                }
              }
            },
            barLabels: {
              enabled: true,
              useSeriesColors: true,
              offsetX: -8,
              fontSize: '16px',
              /*formatter: function(seriesName, opts) {
                return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
              },*/
            },
          }
        },
        colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
        labels: ['Users','Routers','Actuators','Pumps'],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
                show: true
            }
          }
        }]
    },
    //stats_chart_series:[76, 67, 61, 90],
    chart_activestats_options1: {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135, endAngle: 135,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: false,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: '#300', startAngle: -135, endAngle: 135,
            strokeWidth: '67%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },          
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '18px'
            },
            value: {
              formatter: function(val) {
                return val + "%";
              },
              color: '#111',
              fontSize: '30px',
              show: true,
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['Active Pumps'],
    },
    chart_activestats_options2: {
        colors: ["#FF4747"],
        plotOptions: {
          radialBar: {
            startAngle: -135, endAngle: 135,
            track: { background: '#300', startAngle: -135, endAngle: 135 },
            dataLabels: {
              name: { show: true },
              value: { 
                fontSize: "30px", show: false,
                formatter: function (w) {
                  return w;
                }
              }
            }
          }
        },
        fill: {
        type: "gradient",
        gradient: {
            shade: "dark", type: "horizontal",
            gradientToColors: ["#87D400"], stops: [0, 100]
        }
        },
        stroke: { lineCap: "butt" },
        labels: ["Output Pressure"]
    },
  }),
  computed: {
      view_resources_link(){
          const useraccessdict = this.$store.getters.get_useraccessdict();
          //console.log(useraccessdict);
          const permission_to_view_resources = (useraccessdict != null) && useraccessdict["view_resources"];

          return permission_to_view_resources;
      },
      chart_activerouters_options(){
        const chartoptions = JSON.parse(JSON.stringify(this.chart_activestats_options1));
        chartoptions["labels"] = ["Active Routers"];
        chartoptions["plotOptions"]["radialBar"]["track"]["dropShadow"]["enabled"]=true;
        return chartoptions;
      },
      chart_activepumps_options(){
        const chartoptions = JSON.parse(JSON.stringify(this.chart_activestats_options1));
        chartoptions["labels"] = ["Active Pumps"];
        chartoptions["plotOptions"]["radialBar"]["track"]["dropShadow"]["enabled"]=true;
        return chartoptions;
      },
      chart_activeactuators_options(){
        const chartoptions = JSON.parse(JSON.stringify(this.chart_activestats_options1));
        chartoptions["labels"] = ["Active Actuators"];
        chartoptions["plotOptions"]["radialBar"]["track"]["dropShadow"]["enabled"]=false;
        return chartoptions;
      }
  },
  watch: {
    parent_site_stats(newstatslist) { //oldstatslist
      this.set_parentsites_healthdata(newstatslist);      
    }
  },
  mounted() {
    this.fetch_parentsite_stats();
  },
  methods:{
    activeActuatorsPercentage(sitestats, sitehealthdata) {
      //return [67.23];
      return [(100*sitehealthdata["en"]/sitehealthdata["inst"]).toFixed(2)];
    },
    activePumpsPercentage(sitestats, sitehealthdata) {
      //return [67.23];
      return [(100*sitehealthdata["on"]/sitestats["numpumps"]).toFixed(2)];
    },
    activeRoutersPercentage(sitestats, sitehealthdata) {
      //return [67.23];
      return [(100*sitehealthdata["onrtrs"] / sitestats["numrouters"]).toFixed(2)];
    },

    stats_chart_series(sitestats, sitehealthdata) {
      const active_routers_ratio = 100*sitehealthdata["onrtrs"] / sitestats["numrouters"];
      const active_actuators_ratio = 100*sitehealthdata["en"]/sitehealthdata["inst"];
      const active_pumps_ratio = 100*sitehealthdata["on"]/sitestats["numpumps"];
      const statsseries = [0,active_routers_ratio,active_actuators_ratio,active_pumps_ratio];
      //console.log(statsseries);
      return statsseries;
    },
    fetch_parentsite_stats () {
      this.active_user_name =  null;
      this.parent_site_stats = null;
      this.$api.post(this.$orgmanurl + '/company/stats')
      .then(response => {
        //console.log(response.data);
        if ((response.data["code"] == "parent_site_stats") && (response.data["success"]))
        {
          this.active_user_name =  response.data["username"];
          this.parent_site_stats = response.data["statslist"];
          //console.log("parent_site_stats",this.parent_site_stats);
        }
        //this.$emit('loadcomplete');
      });

      return;
    },
    rebuild_sitehealth_data(site_pumphealth_profile, site_onlinepumps_healthdict, site_onlinerouters_list) {
      //console.log("rebuild_sitehealth_data",site_pumphealth_profile,site_onlinepumps_healthdict);
      const instpumpsi = site_pumphealth_profile.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");
      const enpumpsi = site_pumphealth_profile.indexOf("app.machine.multiplePumpManager.u8PumpEnableFlags");
      
      var total_installed_actuators = 0;
      var total_enabled_actuators = 0;
      var total_online_pumps = 0;
      var total_offline_pumps = 0;
      //this.site_onlinepumps_healthdict['all-managed-pumps']
      for (const [pumpip,online_pump_healthlist] of Object.entries(site_onlinepumps_healthdict)){
        if (pumpip in site_onlinepumps_healthdict) {
          total_installed_actuators += online_pump_healthlist[instpumpsi];
          const enpumpsflags = online_pump_healthlist[enpumpsi];
          total_enabled_actuators += ( ((enpumpsflags & 0b10)>>1) + (enpumpsflags & 0b01) );
          total_online_pumps++;
        } else {
          total_offline_pumps++;
        }
      }

      const site_pumphealth_dict = {"inst":total_installed_actuators,"en":total_enabled_actuators,"on":total_online_pumps,"off":total_offline_pumps,"onrtrs":site_onlinerouters_list.length};
      return site_pumphealth_dict;
    },
    async fetch_site_health(siteid) {
      const requestdict = {"code":"get_site_health", "siteid":siteid};
      const response = await this.$api.post(this.$olsupervisorurl + '/health/aggregate/site',requestdict);
      //console.log(response.data);
      if ((response.data["code"] == "get_site_health") && (response.data["success"] === true))
      {
        const site_pumphealth_profile = response.data["varlist"];
        const site_onlinepumps_healthdict = response.data["sitehealth"];
        const site_onlinerouters_list = response.data["olrtrs"];
        const site_pumphealth_dict = this.rebuild_sitehealth_data( site_pumphealth_profile, site_onlinepumps_healthdict, site_onlinerouters_list);
        return site_pumphealth_dict;
      }

      return null;
    },
    async set_parentsites_healthdata(sitesstatlist) {
      this.parentsites_health_data = {};
      if (sitesstatlist != null){
        var temp_health_data = {};
        for (var i=0;i< sitesstatlist.length;i++) {
          const sitestats = sitesstatlist[i];
          const siteid = sitestats["id"];
          const site_health_data = await this.fetch_site_health(siteid);
          if (site_health_data !== null) temp_health_data[siteid] = site_health_data;
        }
        this.parentsites_health_data = temp_health_data;
      }
    },
  }
}
</script>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
<style scoped>
.border {
  border-right: 1px solid grey
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>