<template>    
    <v-app-bar app  clipped-left color="primary" dark>
      <v-app-bar-nav-icon class="grey--text" @click="toggleSidebar"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Quantum SPT"
          contain
          min-width="100"
          :src="companyLogo"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>

      
      <v-btn icon>
        <v-icon>far fa-bell</v-icon>
      </v-btn>
      <v-badge bordered bottom color="green" dot offset-x="10" offset-y="10">
        <router-link to="/login">
          <v-avatar size="40">
              <v-img src="@/assets/avatar.png"></v-img>
          </v-avatar>
        </router-link>
      </v-badge>
    </v-app-bar>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
      return {
          companyLogo: require('@/assets/logo.png')
      }
  },
  methods:{
    toggleSidebar(){
      this.$emit("togglesidebar");
    }
  }
}
</script>
<style scoped>
@media print {
    .v-app-bar {
        visibility: hidden;
    }
}
</style>