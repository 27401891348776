import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
      storage: window.sessionStorage,//localStorage
  })],
  state() {
    return {
      sessionid: null,
      username: null,
      simusername: null,
      useraccessdict : null,//{"editdtc": false},

      vhmipumpids: null,//new Array(),
      vhmipumpips: null,//new Array(),

      socketid: null,
      vhmipumpdata: {},
    }
  },
  mutations: {
    set_user_session(state, {sessionid, username}){
      if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        localStorage.setItem("sessionid", sessionid);
        localStorage.setItem("username", username);
      } else {
        // Sorry! No Web Storage support..
        state.sessionid = sessionid;
        state.username = username;
      }
      //console.log(sessionid, username);
    },
    set_user_sim(state, username){
      if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        localStorage.setItem("simusername", username);
      } else {
        // Sorry! No Web Storage support..
        state.simusername = username;
      }
      //console.log(username);
    },
    set_useraccessdict(state, useraccessdict){
      if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        localStorage.setItem("useraccessdict", JSON.stringify(useraccessdict));
      } else {
        // Sorry! No Web Storage support..
        state.useraccessdict = useraccessdict;
      }
      //console.log(useraccessdict);
    },
    del_session_id(state){
      if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        localStorage.clear(); 
        localStorage.removeItem("sessionid");
        localStorage.removeItem("username");
        localStorage.removeItem("simusername");
        localStorage.removeItem("useraccessdict");
        localStorage.removeItem("vhmipumpids");
        localStorage.removeItem("vhmipumpips");
      } else {
        // Sorry! No Web Storage support..
        state.sessionid = null;
        state.username = null;
        state.simusername = null;
        state.useraccessdict = null;//{};

        state.vhmipumpids = null;//new Array();
        state.vhmipumpips = null;//new Array();
      }
      state.vhmipumpdata = {};
    },
    socket_connected(state, sid) {
      state.socketid = sid;
    },
    socket_disconnected(state) {
      state.socketid = null;
    },
    add_vhmi_pump (state, {pumpid, pumpip}) {
      if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        let vhmipumpids=JSON.parse(localStorage.getItem("vhmipumpids"));
        let vhmipumpips=JSON.parse(localStorage.getItem("vhmipumpips"));
        if ((vhmipumpids == null) || (vhmipumpids == null))
        {
          vhmipumpids = [pumpid];//new Array();
          vhmipumpips = [pumpip];//new Array();
        }
        else if (!vhmipumpids.includes(pumpid)){
          vhmipumpids.push(pumpid);
          vhmipumpips.push(pumpip);
        }
        localStorage.setItem("vhmipumpids", JSON.stringify(vhmipumpids));
        localStorage.setItem("vhmipumpips", JSON.stringify(vhmipumpips));
      } else {
        // Sorry! No Web Storage support..
        if ((state.vhmipumpids == null) || (state.vhmipumpids == null))
        {
          state.vhmipumpids = [pumpid];//new Array();
          state.vhmipumpips = [pumpip];//new Array();
        }
        else if (!state.vhmipumpids.includes(pumpid)){
          state.vhmipumpids.push(pumpid);
          state.vhmipumpips.push(pumpip);
        }
      }
    },
    del_vhmi_pump(state, pumpip) {
      if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        let vhmipumpids=JSON.parse(localStorage.getItem("vhmipumpids"));
        let vhmipumpips=JSON.parse(localStorage.getItem("vhmipumpips"));
        if ((vhmipumpids == null) || (vhmipumpids == null))
        {
          vhmipumpids = new Array();
          vhmipumpips = new Array();
        }
        else if (vhmipumpips.includes(pumpip)){
          let delidx = vhmipumpips.indexOf(pumpip);
          vhmipumpids.splice(delidx,1);
          vhmipumpips.splice(delidx,1);
        }
        localStorage.setItem("vhmipumpids", JSON.stringify(vhmipumpids));
        localStorage.setItem("vhmipumpips", JSON.stringify(vhmipumpips));
      } else {
        // Sorry! No Web Storage support..
        if ((state.vhmipumpids == null) || (state.vhmipumpids == null))
        {
          state.vhmipumpids = new Array();
          state.vhmipumpips = new Array();
        }
        else if (state.vhmipumpips.includes(pumpip)){
          let delidx = state.vhmipumpips.indexOf(pumpip);
          state.vhmipumpids.splice(delidx,1);
          state.vhmipumpips.splice(delidx,1);
        }
      }
    },
    set_vhmi_pumps(state, {vhmi_pump_ids, vhmi_pump_ips}) {
      if (typeof(Storage) !== "undefined") {
        // Code for localStorage/sessionStorage.
        //console.log("set_vhmi_pumps", vhmi_pump_ids, vhmi_pump_ips);
        localStorage.setItem("vhmipumpids", JSON.stringify(vhmi_pump_ids));
        localStorage.setItem("vhmipumpips", JSON.stringify(vhmi_pump_ips));
      } else {
        // Sorry! No Web Storage support..
          state.vhmipumpids=vhmi_pump_ids;
          state.vhmipumpips=vhmi_pump_ips;
      }
    },
    vhmi_pump_data(state, data) {
      //console.log(data);
      state.vhmipumpdata = data;
    }
  },
  actions: {},
  modules: {},
  getters: {
    //get_session_id(state) {
    //Note that getters accessed via methods will run each time you call them, and the result is not cached.
    get_session_id: state => () => {
      var sessionid=null;
      if (typeof(Storage) !== "undefined") {     
        //console.log("sessionStorage");   
        // Code for localStorage/sessionStorage.
        sessionid=localStorage.getItem("sessionid");      
      } else {
        //console.log("stateStorage");   
        // Sorry! No Web Storage support..
        sessionid=state.sessionid;
      }
      return sessionid;
    },
    get_username: state => () => {
      var username=null;
      if (typeof(Storage) !== "undefined") {     
        //console.log("sessionStorage");   
        // Code for localStorage/sessionStorage.
        username=localStorage.getItem("username");      
      } else {
        //console.log("stateStorage");   
        // Sorry! No Web Storage support..
        username=state.username;
      }
      return username;
    },
    get_simusername: state => () => {
      var username=null;
      if (typeof(Storage) !== "undefined") {     
        //console.log("sessionStorage");   
        // Code for localStorage/sessionStorage.
        username=localStorage.getItem("simusername");      
      } else {
        //console.log("stateStorage");   
        // Sorry! No Web Storage support..
        username=state.simusername;
      }
      return username;
    },
    get_useraccessdict: state => () => {
      var useraccessdict=null;
      if (typeof(Storage) !== "undefined") {     
        //console.log("sessionStorage");   
        // Code for localStorage/sessionStorage.
        useraccessdict=JSON.parse(localStorage.getItem("useraccessdict"));
      } else {
        //console.log("stateStorage");   
        // Sorry! No Web Storage support..
        useraccessdict=state.useraccessdict;
      }
      if (useraccessdict==null)
        useraccessdict= {};
      return useraccessdict;
    },
    get_vhmi_pumpids: state => () => {
      var vhmipumpids = null;
      if (typeof(Storage) !== "undefined") {     
        //console.log("sessionStorage");   
        // Code for localStorage/sessionStorage.
        //console.log("get_vhmi_pumpids",localStorage.getItem("vhmipumpids"));  
        vhmipumpids=JSON.parse(localStorage.getItem("vhmipumpids"));
      } else {
        //console.log("stateStorage");   
        // Sorry! No Web Storage support..
        vhmipumpids=state.vhmipumpids;
      }
      if (vhmipumpids==null)
        vhmipumpids= new Array();
      return vhmipumpids;
    },
    get_vhmi_pumpips: state => () => {
      var vhmipumpips = null;
      if (typeof(Storage) !== "undefined") {     
        //console.log("sessionStorage");   
        // Code for localStorage/sessionStorage.
        vhmipumpips=JSON.parse(localStorage.getItem("vhmipumpips"));
      } else {
        //console.log("stateStorage");   
        // Sorry! No Web Storage support..
        vhmipumpips=state.vhmipumpips;
      }
      if (vhmipumpips==null)
        vhmipumpips= new Array();
      return vhmipumpips;
    },
  }
});
