<template>
<div id="sitespage" style="height:86vh">
    <!--v-toolbar class="mt-2" flat>
      <v-toolbar-title>Sites Directory</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider-->
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col class="d-none d-sm-block fill-height" cols="12" sm="4" md="3">
        <v-card class="fill-height"  style="overflow-y: scroll">
          <v-card-title>
            <h3>Organisation</h3>
          </v-card-title>
          <MyTreeView @ouclicked="rebuild_childsites_list" @datadropped="onSiteDrop"/>
        </v-card>
      </v-col>

      <v-col cols="12" sm="8" md="9" class="text-center fill-height">
        <v-card class="fill-height" v-if="datafetch_in_progress" cols="9">
          <v-row class="fill-height d-flex justify-center">
            <v-col cols="6">
              <LoadingBar />
            </v-col>
          </v-row>
        </v-card>
        <v-card class="fill-height" v-else-if="selectedNode !== null" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <h3>{{ selectedNode.name }} - Sites ({{ child_sites_list.length }})</h3>
          <v-btn dark small color="primary" @click="getSiteReport">
            <v-icon dark> mdi-alpha-s-circle-outline </v-icon>
          </v-btn>
          <v-spacer></v-spacer>          
          <!--div class="d-flex justify-space-around pa-6">
          <v-btn-toggle v-model="isRecursiveFetch" color="black">
            <v-btn :value="true">All</v-btn>
            <v-btn :value="false">Child</v-btn>
          </v-btn-toggle>
          </div-->
          <v-dialog v-model="addOUDialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Add Site
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Add New Site</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Name*" v-model="addSiteData.name" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text> {{ addOU_error_msg }} </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="addSiteClose()"> Close </v-btn>
                <v-btn color="blue darken-1" text @click="addSiteConfirm"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <!--v-card-subtitle v-if="active_routers_list !== null" style="text-align:left">
          <v-col>Active Routers: {{ active_routers_list.length }}</v-col>
        </v-card-subtitle-->
        <v-dialog v-model="renameSiteDialog" width="500">
          <v-card>
              <v-card-title>
                <span class="text-h5">Rename Site</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Name*" v-model="renSiteData.name" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field label="Contact" v-model="renSiteData.contact"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Location" v-model="renSiteData.location"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field label="Id*" v-model="renSiteData.id" disabled></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-text> {{ rensite_error_msg }} </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="renameSiteClose()"> Close </v-btn>
                <v-btn color="blue darken-1" text @click="renameSiteConfirm"> Save </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>  
        <v-dialog v-model="deleteOUDialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
            <v-card-text> Are you sure you want to delete {{ seletectedDelSite["name"] }} ? </v-card-text>
            <v-card-text> {{ delOU_error_msg }} </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleteSiteConfirm" > Delete </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 
        <v-dialog v-model="moveSitetoOUDialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2"> Confirm Move </v-card-title>
                <v-card-text> Are you sure you want to move "{{ selectedSitetoMove["name"] }}" to "{{ selectedSitetoMove["newsitename"] }}"? </v-card-text>
                <v-card-text> {{ moveSitetoOU_error_msg }} </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="moveConfirmClick" > Move </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>  
        <v-scroll-y-transition mode="out-in">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center"> Move Site </th>
                    <th class="text-left"> Name (Edit)</th>
                    <th class="text-left"> Location </th>
                    <th class="text-left"> Contact </th>   
                    <template v-if="childsites_pumphealth_list.length == child_sites_list.length">
                      <th class="text-center"> Enabled Actuators </th>
                      <th class="text-center"> Active Pumps </th>
                      <th class="text-center"> Active Routers </th>
                      <th class="text-center"> UserCount </th>
                    </template>  
                    <th class="text-center"> Delete </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="site,idx in child_sites_list" :key="idx" >
                    <td>
                      <v-btn dark small color="green" draggable @dragstart="startDrag($event, idx, site.id, site.name)">
                        <v-icon dark> mdi-chevron-triple-left </v-icon>
                      </v-btn>
                    </td>
                    <td class="text-left"><a href="#" @click="renameSiteClick(idx)">{{ site.name }}</a></td>
                    <td class="text-left">{{ site.location }}</td>
                    <td class="text-left">{{ site.contact }}</td>
                    <!--td class="text-left">{{ site.id }}</td-->
                    <template v-if="childsites_pumphealth_list.length == child_sites_list.length">
                      <td class="text-center">
                        <v-chip v-bind:color="(childsites_pumphealth_list[idx]['en']==childsites_pumphealth_list[idx]['inst'])?'white':'orange'">
                          {{ childsites_pumphealth_list[idx]['en'] }}/{{ childsites_pumphealth_list[idx]['inst'] }}
                        </v-chip>
                      </td>
                      <td class="text-center">
                        <v-chip v-bind:color="(childsites_pumphealth_list[idx]['off']==0)?'white':'yellow'">
                        {{ childsites_pumphealth_list[idx]['on'] }}/{{ site["all-managed-pumps"].length }}
                        </v-chip>
                      </td>
                      <td class="text-center">
                        <v-chip v-bind:color="(childsites_pumphealth_list[idx]['offrtrs']==0)?'white':'cyan'">
                        {{ childsites_pumphealth_list[idx]['actrtrs'] }}/{{ site["all-managed-routers"].length }}
                        </v-chip>
                      </td>
                      <td class="text-center">
                        {{ childsites_pumphealth_list[idx]['numusers'] }}
                      </td>
                    </template>                      
                    <td class="text-center">
                      <v-btn dark small color="error" @click="deleteSiteClick(idx)">
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import LoadingBar from "../components/LoadingBar";
  import MyTreeView from "../components/MyTreeView";

  export default {
    name: "SitesPage",

  components: {
    MyTreeView,
    LoadingBar
  },
    data: () => ({
      datafetch_in_progress: false,
      selectedNode: null,
      isRecursiveFetch: false,

      addOUDialog: false,
      addSiteData: {"name":""},
      addOU_error_msg: null,

      renameSiteDialog: false,
      renSiteData: {"id": null, "name":"", "contact":"", "location":""},
      renSitedx: -1,
      rensite_error_msg: null,

      deleteOUDialog: false,
      deleteSitedx: -1,
      seletectedDelSite: {},
      delOU_error_msg: null,

      moveSitetoOUDialog: false,
      moveSiteIdx : -1,
      moveSitetoOU_error_msg: null,
      selectedSitetoMove: {"id":null, "name": null, 'newsiteid': null, 'newsitename': null},
      
      child_sites_list: [], //[{"id":12,"name":XYZCompnay,"children":null,"all-managed-pumps":[...]}]
      childsites_pumphealth_profile: [],
      childsites_onlinepumps_healthdict: {},
      childsites_pumphealth_list: [],
      active_routers_list: []
      
    }),
    methods: {
      getSiteReport(){
        this.$router.push({ path: '/sitereport',
                            query: { "siteid": this.selectedNode.id, "sitename":this.selectedNode.name } });
      },
      fetch_site_details (siteid) {
        this.datafetch_in_progress = true;
        this.child_sites_list = [];
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/company/childrenfull',{"siteid":siteid, "recursive": this.isRecursiveFetch})
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "list_child_companies")
          {
            this.child_sites_list = response.data["child-companies-list"];
            this.fetch_site_health(siteid);
          }

          //console.log(this.child_sites_list);

          if (this.child_sites_list == null) this.child_sites_list = [];  
          this.datafetch_in_progress = false;        
        });
      },
      rebuild_sitehealth_data() {
        const childsites_onlinepumps_healthdict = this.childsites_onlinepumps_healthdict;
        const childsites_onlinerouters = this.active_routers_list;
        const instpumpsi = this.childsites_pumphealth_profile.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");
        const enpumpsi = this.childsites_pumphealth_profile.indexOf("app.machine.multiplePumpManager.u8PumpEnableFlags");
        
        //console.log(childsites_onlinerouters);

        this.childsites_pumphealth_list = [];
        var childsites_pumphealth_list = [];
        this.child_sites_list.forEach((sitedetail) => {
          var total_installed_actuators = 0;
          var total_enabled_actuators = 0;
          var total_online_pumps = 0;
          var total_offline_pumps = 0;
          sitedetail['all-managed-pumps'].forEach((pumpip) => {
            if (pumpip in childsites_onlinepumps_healthdict) {
              total_installed_actuators += childsites_onlinepumps_healthdict[pumpip][instpumpsi];
              const enpumpsflags = childsites_onlinepumps_healthdict[pumpip][enpumpsi];
              total_enabled_actuators += ( ((enpumpsflags & 0b10)>>1) + (enpumpsflags & 0b01) );
              total_online_pumps++;
            } else {
              total_offline_pumps++;
            }
          });
          
          const total_users = sitedetail['all-managed-users'].length;

          var total_active_routers = 0;
          var total_offline_routers = 0;
          sitedetail['all-managed-routers'].forEach((rtrip) => {
            if (childsites_onlinerouters.includes(rtrip)) {
              total_active_routers++;
            } else {
              total_offline_routers++;
            }
            //console.log(rtrip,total_active_routers);
          });
          //console.log(sitedetail['all-managed-routers'], total_active_routers, total_offline_routers);
          childsites_pumphealth_list.push({
            "inst":total_installed_actuators,
            "en":total_enabled_actuators,
            "on":total_online_pumps,
            "off":total_offline_pumps,
            "numusers":total_users,
            "actrtrs":total_active_routers,
            "offrtrs":total_offline_routers
          });
        });
        this.childsites_pumphealth_list = childsites_pumphealth_list;
      },
      fetch_site_health(siteid) {
        this.active_routers_list = [];
        this.childsites_onlinepumps_healthdict = {};

        this.$api.post(this.$olsupervisorurl + '/health/aggregate/site',{"code":"get_site_health", "siteid":siteid})
        .then(response => {
          //console.log(response.data);
          if ((response.data["code"] == "get_site_health") && (response.data["success"] === true))
          {
            this.childsites_pumphealth_profile = response.data["varlist"];
            this.childsites_onlinepumps_healthdict = response.data["sitehealth"];
            this.active_routers_list = response.data["olrtrs"];
          } else {
            this.childsites_pumphealth_profile = [];
            this.childsites_onlinepumps_healthdict = {};
            this.active_routers_list = [];
          }
          this.rebuild_sitehealth_data();
        });
      },
      rebuild_childsites_list(recvdnode){
        this.selectedNode = null;
        this.fetch_site_details (recvdnode.id);
        //this.fetch_site_health(recvdnode.id);
        //this.rebuild_sitehealth_data();    
        
        this.selectedNode = recvdnode;
      },
      addSiteClose(){
        this.addOU_error_msg = null;
        this.addOUDialog = false;
      },
      addSiteConfirm(){
        const parent = this.selectedNode;
        //console.log(parent);
        const newchilddata = {'code':'add_child_company', "abspath":parent.abspath, "pid":parent.id, "cname":this.addSiteData["name"]}
        //console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/company/addchild', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'add_child_company') {
                    if (response.data['success'])
                    {
                      const newchildname = response.data['name'];
                      const newchildid = response.data['id'];
                      const newchildabspath = response.data['abspath']; //parent.abspath.concat(this.addOUName);
                      const newchildOU = {name:newchildname, "id": newchildid, "abspath":newchildabspath, expanded:false, children:[] };
                      this.child_sites_list.push(newchildOU);
                      parent.expanded = true;
                      this.addOUDialog = false;
                    } else {
                      this.addOU_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.addOU_error_msg = "Invalid response!";
                  }
                });
      },
      renameSiteClick(idx){
        this.renSitedx = idx;
        const sitedata = this.child_sites_list[this.renSitedx];
        this.renSiteData = sitedata;
        this.rensite_error_msg = null;
        this.renameSiteDialog = true;
      },
      renameSiteConfirm(){
        const newchilddata = {'code':'rename_company', "id":this.renSiteData.id, "sitedata":this.renSiteData}
        //console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/company/rename', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'rename_company') {
                    if (response.data['success'])
                    {
                      this.child_sites_list[this.renSitedx] = this.renSiteData;
                      this.renameSiteDialog = false;
                    } else {
                      this.rensite_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.rensite_error_msg = "Invalid response!";
                  }
                });
      },
      renameSiteClose(){
        this.rensite_error_msg = null;
        this.renameSiteDialog = false;
      },
      deleteSiteClick(siteidx){
        this.deleteSiteIdx = siteidx;
        this.delOU_error_msg = null;
        this.deleteOUDialog = true;
      },
      deleteSiteConfirm(){
        this.$api.post(this.$orgmanurl + '/company/delchild', {'code':'del_child_company', "siteid":this.child_sites_list[this.deleteSiteIdx]["id"]})
                .then(response => {
                  if (response.data['code'] == 'del_child_company') {
                    if (response.data['success'])
                    {
                      this.child_sites_list.splice(this.deleteSiteIdx, 1);
                      this.deleteOUDialog = false;
                    } else {
                      this.delOU_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.delOU_error_msg = "Invalid response!";
                  }
                });
      },
      startDrag(evt, idx, siteid, sitenname) {
        this.moveSiteIdx = idx;
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('id', siteid);
        evt.dataTransfer.setData('name', sitenname);
        //console.log("Dragging SiteID: " + siteid);
      },
      onSiteDrop(pcdict) {
        this.selectedSitetoMove = pcdict;
        this.moveSitetoOU_error_msg = ""; // Clear last error message
        this.moveSitetoOUDialog = true;
      },
      moveConfirmClick(){
        /*console.log("MoveConfirmed!");
        console.log(this.selectedSitetoMove);*/
        const siteid = this.selectedSitetoMove['id'];
        const newsiteid = this.selectedSitetoMove['newsiteid'];
        this.$api.post(this.$orgmanurl + '/company/move', {'code':'move_company', "siteid":siteid, "newsiteid":newsiteid})
                .then(response => {
                  //console.log(response.data);
                  if (response.data['code'] == 'move_company') {
                    if (response.data['success'])
                    {
                      // refresh sitelist
                      this.child_sites_list.splice(this.moveSiteIdx, 1);
                      this.moveSitetoOUDialog = false;
                    } else {
                      this.moveSitetoOU_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.moveSitetoOU_error_msg = "Invalid response!";
                  }
                });
      }
    }
  }
</script>