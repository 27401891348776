var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"86vh"},attrs:{"id":"pumpreporter"}},[_c('v-toolbar',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Pumps in Reporter")])],1),_c('v-divider'),_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"fill-height",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height",staticStyle:{"overflow-y":"scroll"},attrs:{"cols":"9"}},[_c('v-card-subtitle',[_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.refresh_pumpslist.apply(null, arguments)}}},[_vm._v("Refresh")]),_vm._v("   "),_c('span',[_vm._v("Found Pumps ("+_vm._s(_vm.num_found_pumps)+")")])],1)],1),_c('v-scroll-y-transition',{attrs:{"mode":"out-in"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pumpslist,"items-per-page":-1,"must-sort":"","sort-by":_vm.sortPumpsBy,"sort-desc.sync":"sortPumpsDesc"},on:{"update:sortBy":function($event){_vm.sortPumpsBy=$event},"update:sort-by":function($event){_vm.sortPumpsBy=$event}},scopedSlots:_vm._u([{key:"item.vhmi",fn:function(ref){
var item = ref.item;
return [(item.onvhmi)?_c('v-btn',{attrs:{"dark":"","small":"","color":"error"}},[_c('v-icon',{attrs:{"light":""}},[_vm._v(" mdi-checkbox-marked-outline ")])],1):_c('v-btn',{attrs:{"dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.add_pump_to_vhmi(item.id,item.ip)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}},{key:"item.reporter",fn:function(ref){
var item = ref.item;
return [(item.onreporter)?_c('v-btn',{attrs:{"dark":"","small":"","color":"error"},on:{"click":function($event){return _vm.del_pump_from_reporter(item.ip)}}},[_c('v-icon',{attrs:{"light":""}},[_vm._v(" mdi-minus ")])],1):_c('v-btn',{attrs:{"dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.add_pump_to_reporter(item.id,item.ip)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}},(_vm.view_pumpreporter_vars)?{key:"item.reportervars",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.goto_pumpreporter_varspage(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}:null,{key:"item.getreport",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.get_pump_report(item.ip,item.nname)}}},[_vm._v(" Get Report ")])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"450px"},model:{value:(_vm.reportdialog),callback:function ($$v) {_vm.reportdialog=$$v},expression:"reportdialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.pump_report_name))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.pump_report_text)}}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.reportdialog = false}}},[_vm._v("Close Dialog")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }