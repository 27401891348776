<template>
    <div class="tree-list">
        <node-tree v-for="treedict,idx in forestList" :key="idx" :node="treedict"
          :depth="depth" v-on:nodeclicked="emitNode" v-on:datadropped="dropEmitRelay"></node-tree>
    </div>
</template>

<script>

import NodeTree from "./NodeTree";

export default {
  props: {
  },
  components: {
    NodeTree
  },
  data() {
    return {
        forestList: [],
        depth: 0
    }
  },
  mounted(){
    //this.$emit('loadstart');
    this.forestList = [];
    this.$api.get(this.$orgmanurl + '/company/get')
      .then(response => {
        //console.log(response.data);
        if (response.data["code"] == "list_users_company")
        {
          const ucl = response.data["users-company-list"];
          ucl.forEach( (abspathtuples) => {
            let abspathnames = [];
            abspathtuples.forEach( (sitetuple) => abspathnames.push(sitetuple[1]));
            let usersitetuple = abspathtuples[abspathtuples.length-1];
            //console.log(usersitetuple);
            var companydict = { 'name': usersitetuple[1], "id": usersitetuple[0], 'abspath': abspathnames, 'expanded': false, 'children': null };
            this.forestList.push(companydict);
          });
          
          this.expandFirstRootNode();
          this.emitNode(this.forestList[0]);//Fetch the data linked to the first root node
        }
        //this.$emit('loadcomplete');
      });
  },
  methods: {
    emitNode(node) {
        //console.log(node);
        this.$emit('ouclicked', node);
    },
    expandFirstRootNode() {
        this.$api.post(this.$orgmanurl + '/company/children',{"siteid":this.forestList[0].id})
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "list_child_companies")
          {
            this.forestList[0].children = [];
            response.data["child-companies-list"].forEach(sitetuple => {
              var siteid = sitetuple[0];
              var sitename = sitetuple[1];
              var newdict = {"name": sitename, "id": siteid, "children":null};
              this.forestList[0].children.push(newdict);
            });
          }
          //this.$emit('loadcomplete');
          if (this.forestList[0].children && (this.forestList[0].children.length > 0)) {
              //this.node.expanded = !this.node.expanded;
              this.$set(this.forestList[0], 'expanded', true);
          }
        });
    },
    dropEmitRelay(droppeddata) {
      this.$emit('datadropped', droppeddata);
      //this.$parent.$emit('ouclicked', this.node);
    },
  }
};
</script>

<!--style scoped>
.tree-list {
  padding-left: 16px;
  margin: 6px 0;
}
</style-->