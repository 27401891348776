<template>
    <div class="runstate">
        <svg v-if="runstate==0" viewbox="0,0,25,25" height="30"><!--Stopped-->
            <rect x="10" y="5" width="5" height="20" class="run-line" />
            <rect x="00" y="5" width="25" height="5" class="run-line" />    
        </svg>
        <svg v-else-if="runstate==1" viewbox="0,0,25,25" height="30"><!--Starting-->
            <rect x="10" y="0" width="5" height="17.5" class="run-line" />
            <polygon points="00,20 12.5,5 25,20" class="run-line" />            
        </svg>
        <svg v-else-if="runstate==2" viewbox="0,0,25,25" height="30"><!--Running-->
            <rect x="10" y="0" width="5" height="25" class="run-line" />        
        </svg>
        <svg v-else-if="runstate==3" viewbox="0,0,25,25" height="30"><!--Stopping-->
            <rect x="10" y="12.5" width="5" height="12.5" class="run-line" />
            <line x1="5" y1="5" x2="20" y2="20" width="5" style="stroke:black;stroke-width:5" />  
            <line x1="20" y1="5" x2="5" y2="20" width="5" style="stroke:black;stroke-width:5" />          
        </svg>
        <svg v-else viewbox="0,0,25,25" height="8"><!--Unknown-->
            <circle cx="12.5" cy="12.5" r="2.5" class="run-line" />    
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        runstate: Number
    },
}
</script>
