<template>
<div id="pumpreporter" style="height:86vh">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Pumps in Reporter</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >

      <v-col cols="12" class="fill-height">
        <v-card class="fill-height" cols="9" style="overflow-y: scroll">
        <v-card-subtitle>
          <v-card-text>
            <v-btn color="primary" @click.prevent="refresh_pumpslist">Refresh</v-btn>
            &nbsp;
            <span>Found Pumps ({{ num_found_pumps }})</span>
          </v-card-text>
        </v-card-subtitle>
        <v-scroll-y-transition mode="out-in">
            <v-data-table :headers="headers" :items="pumpslist" :items-per-page="-1" 
              must-sort :sort-by.sync="sortPumpsBy" sort-desc.sync="sortPumpsDesc">
            <template v-slot:item.vhmi="{ item }">
              <v-btn v-if="item.onvhmi" dark small color="error">
                <v-icon light > mdi-checkbox-marked-outline </v-icon>
              </v-btn>
              <v-btn v-else dark small color="primary" @click="add_pump_to_vhmi(item.id,item.ip)">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <template #item.reporter="{ item }">
              <v-btn v-if="item.onreporter" dark small color="error" @click="del_pump_from_reporter(item.ip)">
                <v-icon light > mdi-minus </v-icon>
              </v-btn>
              <v-btn v-else dark small color="primary" @click="add_pump_to_reporter(item.id,item.ip)">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
              <template v-if="view_pumpreporter_vars" #item.reportervars="{ item }">
                <v-btn dark small color="green" @click="goto_pumpreporter_varspage(item.id)">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
            
            <template #item.getreport="{ item }">
              <v-btn dark small color="primary" @click="get_pump_report(item.ip,item.nname)">
                Get Report
              </v-btn>
            </template>
          </v-data-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>

    <v-dialog v-model="reportdialog" width="450px">
      <v-card>
        <v-card-title>{{ pump_report_name }}</v-card-title>
        <v-card-text v-html="pump_report_text">
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="reportdialog = false">Close Dialog</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
  import pumphooks from '../plugins/pump_data_hooks'
  export default {
    name: "PumpReporterPage",

    data: () => ({
      pumps_in_reporter: [],
      pumpslist: [],      

        headers: [
          { text: 'Add to VHMI', value: 'vhmi', filterable: false, sortable: false },
          { text: 'Del Reporting', value: 'reporter', filterable: false, sortable: false },
          { text: 'IP Address', value: 'ip', filterable: false, sortable: true },
          { text: 'Nickname', value: 'nname', filterable: false, sortable: true },
          { text: 'Customer', value: 'cname', filterable: false, sortable: true },
          { text: 'Last Online', value: 'lastonline', filterable: false, sortable: true },
          { text: 'Get Report', value: 'getreport', filterable: false, sortable: false },
        ],
        sortPumpsBy: 'lastonline', sortPumpsDesc: true,

      reportdialog: false,
      pump_report_name: "",
      pump_report_text: "",

      view_pumpreporter_vars: null
    }),
    created() {
      //this.pumps_in_reporter = pumphooks.pumps_in_reporter;
      this.reporter_pumps_onlinestats = pumphooks.reporter_pumps_onlinestats;
      this.get_pumps_in_reporter = pumphooks.get_pumps_in_reporter;
      this.add_pump_to_vhmi = pumphooks.add_pump_to_vhmi;
      this.pumps_onvhmi_check = pumphooks.pumps_onvhmi_check;
      this.pumps_onreporter_check = pumphooks.pumps_onreporter_check;
      this.add_pump_to_reporter = pumphooks.add_pump_to_reporter;
      this.del_pump_from_reporter = pumphooks.del_pump_from_reporter;
      this.goto_pumpreporter_varspage = pumphooks.goto_pumpreporter_varspage;
    },
    mounted() {
      const useraccessdict = this.$store.getters.get_useraccessdict();
      this.view_pumpreporter_vars = (useraccessdict != null) && useraccessdict["edit_reporter_vars"];
      if(this.view_pumpreporter_vars)
        this.headers.splice(2,0, { text: 'ReporterVars', value: 'reportervars', filterable: false, sortable: false });

      this.get_pumps_in_reporter();
    },
    computed: {
      num_found_pumps() {
        return this.pumpslist.length;
      }
    },
    watch: {
      pumps_in_reporter: function (newlist) {//, oldlist
        this.get_pump_info(newlist);
      }
    },
    methods: {
      refresh_pumpslist(){
        this.get_pumps_in_reporter();
      },
      get_pump_info(new_reporter_pumps) {
        //this.pumpslist = new_reporter_pumps;

        this.$api.post(this.$orgmanurl + '/pumps/getipinfo', {'code':'get_pumps_info', "pumpiplist":new_reporter_pumps})
                .then(response => {
                  //console.log(response.data);
                  if (response.data['code'] == 'get_pumps_info') {
                    if (response.data['success'])
                    {
                      const pumpidlist=response.data['pumpidlist'];
                      const pumpiplist=response.data['pumpiplist'];
                      const pumpnnamelist=response.data['pumpnnamelist'];
                      const pumpcnamelist=response.data['pumpcnamelist'];
                      const pumponlinestats = this.reporter_pumps_onlinestats;
                      const pumps_in_reporter = this.pumps_in_reporter;
                      //console.log(pumponlinestats);
                      // refresh pumplist
                      var pumpslist = [];
                      if (pumps_in_reporter != null){
                        pumps_in_reporter.forEach(function (pumpip,pridx) {
                        //pumpidlist.forEach(function (pumpid,idx){
                          var pumpdict = {"id":null, "ip": pumpip, "nname":"Unauthorized", "cname":"Unauthorized", "lastonline": "Unauthorized" }
                          var idx = pumpiplist.indexOf(pumpip);
                          if (idx > -1) {
                            var pumpid = pumpidlist[idx];
                            pumpdict = {"id":pumpid, "ip": pumpiplist[idx],
                              "nname":pumpnnamelist[idx], "cname":pumpcnamelist[idx],
                              //"lastonline": pumponlinestats[this.pumps_in_reporter.indexOf(pumpiplist[idx])]
                            }
                            if (pumponlinestats != null)
                              pumpdict["lastonline"] = pumponlinestats[pridx];
                          }
                          pumpslist.push(pumpdict);
                        });
                      }
                      this.pumpslist=pumpslist;
                      //console.log(pumpslist);

                      if (this.pumpslist != null) {
                        this.pumps_onvhmi_check();
                        this.pumps_onreporter_check();
                      }
                      else this.pumpslist = [];
                    } else {
                      alert(response.data['errmsg']);
                    }
                  } else {
                    alert("Invalid response!");
                  }
                });
      },
      get_pump_report(pumpip,nname){
        this.$api.post(this.$pumpreporterurl + '/report', {'code':'get_last_report', "pumpip":pumpip})
                .then(response => {
                  //console.log(response.data);
                  if (response.data['code'] == 'get_last_report') {
                    if (response.data['success'])
                    {
                      this.pump_report_name = nname;
                      this.pump_report_text = response.data['pumpreport'];
                    } else {
                      this.pump_report_text = response.data['errmsg'];
                    }
                  } else {
                    this.pump_report_text = "Invalid response!";
                  }
                  this.reportdialog = true;
                });
      }
    }
  }
</script>