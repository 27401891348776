<template>
<div>
    <v-container v-if="pump['daq'].length > 0">
        <v-row align="stretch">
            <v-col class="d-flex justify-center align-center">
                Installed Pumps: {{ pump["daq"][instpumpsi] }} | Enabled Pumps: {{ enPumpCount }}
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex justify-center align-center">
            <v-card>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center"><font size="3">Pump 1</font></th>
                                <th class="text-center">Cyl 1</th>
                                <th class="text-center">Cyl 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Flowrate</td>
                                <td class="text-center">{{ pump["daq"][p1ss0vflowi].toFixed(2) }} L/min</td>
                                <td class="text-center">{{ pump["daq"][p1ss1vflowi].toFixed(2) }} L/min</td>
                            </tr>
                            <tr>
                                <td>Strokerate</td>
                                <td class="text-center">{{ pump["daq"][p1ss0sratei].toFixed(2) }} SPM</td>
                                <td class="text-center">{{ pump["daq"][p1ss1sratei].toFixed(2) }} SPM</td>
                            </tr>
                            <tr>
                                <td>Power</td>
                                <td class="text-center">{{ pump["daq"][p1cwpc1f2i].toFixed(2) }} W</td>
                                <td class="text-center">{{ pump["daq"][p1cwpc2f2i].toFixed(2) }} W</td>
                            </tr>
                            <tr>
                                <td>Temperature</td>
                                <td class="text-center">{{ pump["daq"][p1c1tempi].toFixed(2) }} C</td>
                                <td class="text-center">{{ pump["daq"][p1c2tempi].toFixed(2) }} C</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
            </v-col>
            <v-col v-if="pumpIsDual" class="d-flex justify-center align-center">
            <v-card>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center"><font size="3">Pump 2</font></th>
                                <th class="text-center">Cyl 1</th>
                                <th class="text-center">Cyl 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Flowrate</td>
                                <td class="text-center">{{ pump["daq"][p2ss0vflowi].toFixed(2) }} L/min</td>
                                <td class="text-center">{{ pump["daq"][p2ss1vflowi].toFixed(2) }} L/min</td>
                            </tr>
                            <tr>
                                <td>Strokerate</td>
                                <td class="text-center">{{ pump["daq"][p2ss0sratei].toFixed(2) }} SPM</td>
                                <td class="text-center">{{ pump["daq"][p2ss1sratei].toFixed(2) }} SPM</td>
                            </tr>
                            <tr>
                                <td>Power</td>
                                <td class="text-center">{{ pump["daq"][p2cwpc1f2i].toFixed(2) }} W</td>
                                <td class="text-center">{{ pump["daq"][p2cwpc2f2i].toFixed(2) }} W</td>
                            </tr>
                            <tr>
                                <td>Temperature</td>
                                <td class="text-center">{{ pump["daq"][p2c1tempi].toFixed(2) }} C</td>
                                <td class="text-center">{{ pump["daq"][p2c2tempi].toFixed(2) }} C</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center" colspan="2"><font size="3">Estimated</font></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Flowrate</td>
                                <td class="text-right">{{ pump["daq"][flowfilt2i].toFixed(2) }} LPM</td>
                            </tr>
                            <tr>
                                <td>Orifice</td>
                                <td class="text-right">{{ pump["daq"][orificeestimi].toFixed(2) }} Thou</td>
                            </tr>
                            <tr>
                                <td>Pressure</td>
                                <td class="text-right">{{ pump["daq"][pestimi].toFixed(2) }} PSI</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center" colspan="2"><font size="3">Limit</font></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Maximum</td>
                                <td class="text-right">{{ pump["daq"][flowerrthreshi].toFixed(2) }} LPM</td>
                            </tr>
                            <tr>
                                <td>Installed</td>
                                <td class="text-right">{{ pump["daq"][orificespeci].toFixed(2) }} Thou</td>
                            </tr>
                            <tr>
                                <td>Max Error</td>
                                <td class="text-right">{{ pump["daq"][presserri].toFixed(2) }} PSI</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        
    </v-container>
    <v-container v-else>
        <v-row>
            <v-col>
                <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "HighPressureTab",
  props: {
      pump: null
  },
  data() {
    return {
      enpumpflgsi: null, instpumpsi: null,
      p1ss0vflowi: null, p1ss1vflowi: null,
      p2ss0vflowi: null, p2ss1vflowi: null,
      p1ss0sratei: null, p1ss1sratei: null,
      p2ss0sratei: null, p2ss1sratei: null,
      p1cwpc1f2i: null, p1cwpc2f2i: null,
      p2cwpc1f2i: null, p2cwpc2f2i: null,
      p1c1tempi: null, p1c2tempi: null,
      p2c1tempi: null, p2c2tempi: null,

      flowfilt2i: null, flowerrthreshi: null,
      orificeestimi: null, orificespeci: null,
      pestimi: null, presserri: null
    }
  },
  computed: {
      pumpIsDual() {
          return (this.pump["daq"][this.instpumpsi] == 2);
      },
      enPumpCount() {
          var pumpcount = 0;
          pumpcount = pumpcount + (this.pump["daq"][this.enpumpflgsi] & 0b01);
          pumpcount = pumpcount + ((this.pump["daq"][this.enpumpflgsi] & 0b10) >> 1);
          return pumpcount;
      }
  },
  created(){
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");

    this.enpumpflgsi = vhmi_mcdvars_list.indexOf("app.machine.multiplePumpManager.u8PumpEnableFlags");
    this.instpumpsi = vhmi_mcdvars_list.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");
    this.p1ss0vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].s16VolumeFlowRate");
    this.p1ss1vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].s16VolumeFlowRate");
    this.p2ss0vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].s16VolumeFlowRate");
    this.p2ss1vflowi = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].s16VolumeFlowRate");
    this.p1ss0sratei = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].u16StrokeRate");
    this.p1ss1sratei = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].u16StrokeRate");
    this.p2ss0sratei = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[0].u16StrokeRate");
    this.p2ss1sratei = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics[1].u16StrokeRate");
    this.p1cwpc1f2i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.s32MechanicalPowerEstim_Reverse_Filt");
    this.p1cwpc2f2i = vhmi_mcdvars_list.indexOf("app.machine.pump1.actuatorController.s32MechanicalPowerEstim_Forward_Filt");
    this.p2cwpc1f2i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.s32MechanicalPowerEstim_Reverse_Filt");
    this.p2cwpc2f2i = vhmi_mcdvars_list.indexOf("app.machine.pump2.actuatorController.s32MechanicalPowerEstim_Forward_Filt");
    this.p1c1tempi = vhmi_mcdvars_list.indexOf("app.machine.pump1.pumpController.pumpFluidStateObserver.cylinder1.temperatureSensor_HPCV.s16TempSense_Deg");
    this.p1c2tempi = vhmi_mcdvars_list.indexOf("app.machine.pump1.pumpController.pumpFluidStateObserver.cylinder2.temperatureSensor_HPCV.s16TempSense_Deg");
    this.p2c1tempi = vhmi_mcdvars_list.indexOf("app.machine.pump2.pumpController.pumpFluidStateObserver.cylinder1.temperatureSensor_HPCV.s16TempSense_Deg");
    this.p2c2tempi = vhmi_mcdvars_list.indexOf("app.machine.pump2.pumpController.pumpFluidStateObserver.cylinder2.temperatureSensor_HPCV.s16TempSense_Deg");

    this.flowfilt2i = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Filt2");
    this.flowerrthreshi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32FluidFlowOutOrifice_Error_Threshold_Operator");
    this.orificeestimi = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32OrificeSize_EstimInstalledInHead");
    this.orificespeci = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineHPFluidController.machineHPFluidStateObserver.s32OrificeSize_Specified");
    this.pestimi = vhmi_mcdvars_list.indexOf("app.machine.sapController.pressureEstimator.s32Pressure_Estim");
    this.presserri = vhmi_mcdvars_list.indexOf("app.machine.sapController.sapControlMode.pressureController.s32PressureControlTolerance_Error_FeedbackLow");
  },
}
</script>