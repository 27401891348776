<template>
<div id="routersonline" style="height:86vh">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Router List ({{ num_found_routers }})</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >
      <v-col cols="12" class="fill-height">
        <v-card class="fill-height" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <v-btn color="primary" @click.prevent="refresh_routerslist">Refresh</v-btn>
          <v-spacer></v-spacer>
          <v-text-field v-model="searchtext" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>

        <v-scroll-y-transition mode="out-in">
          <v-data-table :headers="headers" :items="routerslist" :items-per-page="15"  :search="searchtext"
            :custom-sort="customSort" must-sort :sort-by.sync="sortRoutersBy" :sort-desc.sync="sortRoutersDesc">            
            <template #item.label="{ value }">
              <a :href="`router/${value}`" >{{ value }}</a>
            </template>
          </v-data-table>
        </v-scroll-y-transition>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import dateutils from '../plugins/date_utilities';

  export default {
    name: "RoutersOnlinePage",
    data: () => ({
      routerslist: [],      

        /*
        { text: 'Subnet Address', value: 'subnetaddr', filterable: false, sortable: true },
        { text: 'Description', value: 'sitedesc', filterable: false, sortable: false },
        { text: 'Country', value: 'country', filterable: true, sortable: true },
        */
      headers: [
        { text: 'RouterLabel', value: 'label', filterable: true, sortable: true },
        { text: 'IP Address', value: 'ipaddr', filterable: true, sortable: false },
        { text: 'MAC Address', value: 'macaddr', filterable: true, sortable: false },
        { text: 'Connected Since', value: 'connectedsince', filterable: false, sortable: true },
        { text: 'Last Online', value: 'lastonline', filterable: false, sortable: true },
        { text: 'Site Name', value: 'sitename', filterable: true, sortable: true },
      ],
      sortRoutersBy: 'lastonline', sortRoutersDesc: true,
      searchtext: "",      
    }),
    created() {
      this.convertUTCDateToSortableLocalDate = dateutils.convertUTCDateToSortableLocalDate;
      //this.pumps_in_reporter = pumphooks.pumps_in_reporter;
    },
    mounted() {
      this.get_routers_list();
    },
    computed: {
      num_found_routers() {
        return this.routerslist.length;
      }
    },
    methods: {
      get_routers_list(){
        this.$api.post(this.$olsupervisorurl + '/router/list')
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "list_configured_routers")
          {
            //this.routerslist = response.data["routerslist"];
            const recvdrouterslist = response.data["routerslist"];
            //var routerslist = [];
            for(var idx in recvdrouterslist){
              const routerdict = recvdrouterslist[idx];
              routerdict["lastonline"] = this.convertUTCDateToSortableLocalDate(routerdict["lastonline"]);
              //routerslist.push(routerdict);
            }
            this.routerslist = recvdrouterslist;//routerslist;
          }
          //this.$emit('loadcomplete');
        });

      },
      refresh_routerslist(){
        this.get_routers_list();
      },
      customSort(items, index, isDesc) {
        if (!index || !index.length || !isDesc || !isDesc.length) {
          return items;
        }

        if (index[0] === "label") {
          items.sort((a, b) => {
            const anum = parseInt(a.label.replace("TWROUTER",""));
            const bnum = parseInt(b.label.replace("TWROUTER",""));
            if (!isDesc[0]) {
              return anum < bnum ? -1 : 1;
            } else {
              return bnum < anum ? -1 : 1;
            }
          });
        } else if (index[0] === "sitename") {
          items.sort((a, b) => {
            if (!isDesc[0]) {
              if (a[index[0]] == null) return 1; // Send nulls to the end
              return a[index[0]].localeCompare(b[index[0]]);
            } else {
              if (b[index[0]] == null) return -1; // Send nulls to the end
              return b[index[0]].localeCompare(a[index[0]]);
            }
          });
        } else {
          items.sort((a, b) => {
            if (!isDesc[0]) {
              if (a[index[0]] == null) return 1; // Send nulls to the end
              return a[index[0]] < b[index[0]] ? -1 : 1;
            } else {
              if (b[index[0]] == null) return -1; // Send nulls to the end
              return b[index[0]] < a[index[0]] ? -1 : 1;
            }
          });
        }
          
        return items;
      }
    }
  }
</script>

<style scoped>
</style>