<template>
<div id="routersearch" style="height:86vh">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Routers Search</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >

      <v-col cols="12" class="fill-height">
        <v-card class="fill-height" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <v-text-field  v-model="routerlabel_to_search" append-icon="mdi-magnify" class="shrink"
            filled dense hide-details :rules="[v => v.length<=15 || 'Max 15 characters']" counter>
          </v-text-field>
          &nbsp;
          <v-btn class="primary fill-height" @click="searchRouters">Search</v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-card-text><h3>Found Routers ({{ num_found_routers }})</h3></v-card-text>
        </v-card-subtitle>
        <v-scroll-y-transition mode="out-in">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"> IP Address </th>
                    <th class="text-left"> Nickname </th>
                    <th class="text-left"> Customer </th>
                    <th class="text-left"> Reference Path </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="router,idx in routerslist" :key="idx" >
                    <td class="text-left">{{ router.ip }}</td>
                    <td class="text-left">{{ router.nname }}</td>
                    <td class="text-left">{{ router.cname }}</td>
                    <td> {{ pathListToString(router.routerpath) }} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  export default {
    name: "RouterSearchPage",

    data: () => ({
      routerlabel_to_search: "TWROUTER000",      
      routerslist: [],
      num_found_routers: 0,
      routers_in_reporter: [],

      view_routerreporter_vars: null
    }),
    methods: {
      searchRouters () {
        //console.log(this.routerlabel_to_search)
        this.routerslist = [];
        this.num_found_routers = 0;
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/routers/search',{"routerlabel":this.routerlabel_to_search})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "search_company_routers")
                {
                  this.routerslist = response.data["found-routers-list"];
                  //console.log(this.routerslist);
                  
                  if (this.routerslist == null)
                    this.routerslist = [];
                  this.num_found_routers = this.routerslist.length;
                  if (this.routerslist.length == 0) this.routerslist = [{nname:this.routerlabel_to_search, ip:"Not Found!", routerpath:[]}];
                }
                //this.$emit('loadcomplete');
              });

        return;
      },
      pathListToString(pathlist) {
        return pathlist.join(' -> ')
      }
    }
  }
</script>