import Axios from "axios";
import router from "./router";
import store from "./vuexstore";

const axiosapi = Axios;

axiosapi.interceptors.request.use(
  config => {
    const token = store.getters.get_session_id();
    if (token) {
      config.headers.common["AuthToken"] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosapi.interceptors.response.use(
  function(response) {
    if (response.status === 200 || response.status === 201) return Promise.resolve(response);
    else return Promise.reject(response);
  },
  function(error) {
    //console.log(error);
    if (error.response.status) {
      switch (error.response.status) {
        case 400:         
         //do something
          break;      
        case 401:
          alert("session expired");
          store.commit("del_session_id");
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath },
            params: { errormsg: 'Session Expired!' } 
          });
          break;
        case 403:
          store.commit("del_session_id");
          console.log(error);
          router.push({path:"/login"});
          window.location.reload();
          /*router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath },
            params: { errormsg: 'Session Invalid!' + error.response.data } 
          });*/
           break;
        case 404:
          alert('page not exist');
          break;
        case 502:
         setTimeout(() => {
            store.commit("del_session_id");
            router.replace({
              path: "/login",
              query: { redirect: router.currentRoute.fullPath },
              params: { errormsg: 'Timed Out!' } 
            });
          }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
);

export default axiosapi;