<template>
  <div class="routerdetails">
    <v-col cols="12" sm-11>
      <v-card class="ma-2" flat>
        <v-card-title>
          <h2>Router Details</h2>
        </v-card-title>
      </v-card>
      <v-card class="ma-6">
        <v-card-title>
          <h4>{{ this.$route.params["label"] }}</h4>
        </v-card-title>
        <v-container class="pb-6">
          <v-row>
            <v-col><b>RouterIP</b><br/>{{ router_details.ipaddr }}</v-col>
            <v-col><b>MAC Address</b><br/>{{ router_details.macaddr }}</v-col>
            <v-col><b>Last Online</b><br/>{{ router_details.lastonline }}</v-col>
            <v-col v-if="ping_routers_permission"><v-btn color="primary" @click.prevent="ping_refresh_router">OnlineCheck</v-btn></v-col>          
          </v-row>
        </v-container>
      </v-card>
      <v-card class="ma-6">
        <v-card-title>
          <h4>Installation Site</h4>
        </v-card-title>
          <table class="ml-6">
            <tr><td><b>Name</b></td><td>&nbsp;</td><td>{{ router_details.sitename }}</td></tr>
            <tr><td><b>Description</b></td><td>&nbsp;</td><td>{{ router_details.sitedesc }}</td></tr>
            <tr><td><b>Country</b></td><td>&nbsp;</td><td>{{ router_details.country }}</td></tr>
          </table>
        <v-card-title></v-card-title> <!-- for padding the bottom -->
      </v-card>
      <v-card class="ma-6">
        <v-card-title>
          <h4>Connected Pumps</h4>
        </v-card-title>
        <v-scroll-y-transition mode="out-in">
            <v-data-table :headers="espcmheaders" :items="connected_pumplist" :items-per-page="15"
              must-sort :sort-by.sync="sortPumpsBy" :sort-desc.sync="sortPumpsDesc">
            </v-data-table>
        </v-scroll-y-transition>
      </v-card>
    </v-col>
  </div>
</template>

<script>
  export default {
    name: 'RouterDetailed',
    data () {
      return {
        router_details: {
          "country": null,
          "ipaddr": null,
          "label": null,
          "macaddr": null,
          "sitedesc": null,
          "sitename": null,
          "subnetaddr": null,
        },
        connected_pumplist: [{
          "espcmip": null,
          "espcmlabel": null,
          "globalip": null,
          "lastonline": null,
          "routerip": null,
          "routerlabel": null,
        }],

        espcmheaders: [
          { text: 'IP Address', value: 'espcmip', filterable: true, sortable: true },
          { text: 'Label', value: 'espcmlabel', filterable: false, sortable: false },
          { text: 'SerialNumber', value: 'espcmser', filterable: false, sortable: false },
          { text: 'Last Online', value: 'lastonline', filterable: false, sortable: true }
        ],
        sortPumpsBy: 'lastonline', sortPumpsDesc: true,
      }
    },
    mounted(){
      //this.$emit('loadstart');
      this.fetchRouterdict();      
    },
    computed: {
      ping_routers_permission() {
        const useraccessdict = this.$store.getters.get_useraccessdict();
        return (useraccessdict != null) && useraccessdict["addrouters"];
      }
    },
    methods: {
      fetchRouterdict() {
        this.$emit('loadstart');
        this.$api.get(this.$olsupervisorurl + "/router/" + this.$route.params["label"])
        .then(response => {
          //console.log(response.data);
          if (response.data['code'] == 'get_router_details') {
            if (response.data['success'])
            {
              this.router_details = response.data["routerdict"];
              this.connected_pumplist = response.data["espcmlist"];
              this.$emit('loadcomplete');
            } /*else {
              this.fetch_error_msg = response.data['errmsg'];
            }*/
          } /*else {
            this.fetch_error_msg = "Invalid response!";
          }*/
        });
      },
      ping_refresh_router(){
        this.$emit('loadstart');
        this.$api.get(this.$olsupervisorurl + "/router/ping/" + this.$route.params["label"])
        .then(response => {
          //console.log(response.data);
          if (response.data['code'] == 'ping_update_router') {
            if (response.data['success'])
            {
              this.fetchRouterdict();
              this.$emit('loadcomplete');
            } /*else {
              this.fetch_error_msg = response.data['errmsg'];
            }*/
          } /*else {
            this.fetch_error_msg = "Invalid response!";
          }*/
        });
      },
    }
  }
</script>
<style scoped>
</style>