<template>
  <div :class="{'node-tree-item':true, 'yellow': drag_highlight}" @click.stop="toggleChildrenVisibility" 
    @dragover.prevent @dragenter.prevent
    @drop.stop="onDrop($event)" @dragover.stop="onDragOver" @dragleave.stop="onDragLeave">

    <!--v-hover v-slot="{ hover }"-->
        <div class="label">      
            <v-icon v-if="!node.children || (node.children.length <= 0)"> mdi-folder </v-icon>
            <v-icon v-else-if="node.expanded"> mdi-folder-account-outline </v-icon>
            <v-icon v-else> mdi-folder-account </v-icon>

            <span>{{ node.name }}</span>
            <!--v-btn class="ml-2" x-small rounded color="secondary" v-if="drag_highlight"><v-icon>mdi-water-pump</v-icon></v-btn-->

            <!--v-btn class="ml-2" x-small rounded color="secondary" v-if="hover" @click.stop="addNewNodeClick()"><v-icon>mdi-plus</v-icon></v-btn>
            <v-btn class="ml-1" x-small rounded color="secondary" v-if="hover" @click.stop="delThisNodeClick(node)"><v-icon>mdi-close</v-icon></v-btn-->
        </div>
    <!--/v-hover-->

    <v-dialog v-model="addOUDialog" width="500">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2"> Add Organisation Unit </v-card-title>
            <v-card-text>
                <v-text-field label="Name*" v-model="addOUName" required></v-text-field>
                <v-card-text> {{ addOU_error_msg }} </v-card-text>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="addOUConfirm" > Confirm </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteOUDialog" width="500">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2"> Confirm Deletion </v-card-title>
            <v-card-text> Are you sure you want to delete "{{ seletectedDelOU["name"] }}" ? </v-card-text>
            <v-card-text> {{ delOU_error_msg }} </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="delConfirmClick" > Delete </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <div v-if="node.children && (node.children.length > 0) && node.expanded">
      <node-visu v-for="child in node.children" :node="child" :key="child.id" :depth="parseInt(depth)+1" 
        @nodedelclicked="delChildNode" @nodeclicked="emitRelay" @datadropped="dropEmitRelay"></node-visu>
    </div>
  </div>
</template>

<script>
export default {
  name: "node-visu", /* Visual Node */
  props: {
    node: Object,
    depth: Number
  },
  data(){
      return {
        drag_highlight: false,

        addOUName: "",
        addOUDialog: false,
        addOU_error_msg: null,

        deleteOUDialog: false,
        delOU_error_msg: null,
        seletectedDelOU: {},
      }
  },
  mounted() {
      this.node["depth"] = this.depth;
      this.node["expanded"] = false;
      /*if (this.node.children && (this.node.children.length > 0) && this.node.expanded) {
          this.node.children.forEach((node) => node["expanded"] = false);
      }*/
  },
  methods: {
      toggleChildrenVisibility(/*e*/) {
        //e.preventDefault();
        var objToEmit = this.node;//{"depth":this.node.depth, "name":this.node.name, "abspath":this.node.abspath};
        this.$emit('nodeclicked', objToEmit);
        //this.$parent.$emit('ouclicked', this.node);
        if (!this.node.expanded) { // Query sub companies from server only on expansion of directory
          this.$api.post(this.$orgmanurl + '/company/children',{"siteid":this.node.id})
                .then(response => {
                  //console.log(response.data);
                  if (response.data["code"] == "list_child_companies")
                  {
                    this.node.children = [];
                    response.data["child-companies-list"].forEach(sitetuple => {
                      var siteid = sitetuple[0];
                      var sitename = sitetuple[1];
                      var newdict = {"name": sitename, "id": siteid, "children":null};
                      this.node.children.push(newdict);
                    });
                  }
                  //this.$emit('loadcomplete');
                  if (this.node.children && (this.node.children.length > 0)) {
                      //this.node.expanded = !this.node.expanded;
                      this.$set(this.node, 'expanded', true);
                  }
                });
        } else {
          this.node.children = null;
          this.$set(this.node, 'expanded', false);
        }
      },
      emitRelay(nedo) {
        this.$emit('nodeclicked', nedo);
        //this.$parent.$emit('ouclicked', this.node);
      },
      addOUConfirm() {
        const parent = this.node;
        console.log(parent);
        const newchilddata = {'code':'add_child_company', "abspath":parent.abspath, "pid":parent.id, "cname":this.addOUName}
        console.log(newchilddata);
        this.$api.post(this.$orgmanurl + '/company/addchild', newchilddata)
                .then(response => {
                  if (response.data['code'] == 'add_child_company') {
                    if (response.data['success'])
                    {
                      const newchildname = response.data['name'];
                      const newchildid = response.data['id'];
                      const newchildabspath = response.data['abspath']; //parent.abspath.concat(this.addOUName);
                      const newchildOU = {name:newchildname, "id": newchildid, "abspath":newchildabspath, expanded:false, children:[] };
                      if(parent.children == null)
                          this.$set(parent, "children", [newchildOU]) ;
                      else parent.children.push(newchildOU);
                      parent.expanded = true;
                      this.addOUDialog = false;
                    } else {
                      this.addOU_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.addOU_error_msg = "Invalid response!";
                  }
                });
      },
      addNewNodeClick() {
        this.addOUDialog = true;
        this.addOU_error_msg= null;
      },
      delThisNodeClick() {
        const objToDel = {"depth":this.node.depth, "name":this.node.name};
        this.seletectedDelOU = objToDel;
        this.delOU_error_msg = null;
        this.deleteOUDialog = true;
      },
      delConfirmClick() {
        this.$api.post(this.$orgmanurl + '/company/delchild', {'code':'del_child_company', "siteid":this.node.id})
                .then(response => {
                  if (response.data['code'] == 'del_child_company') {
                    if (response.data['success'])
                    {
                      this.$emit('nodedelclicked', this.seletectedDelOU);
                      this.deleteOUDialog = false;
                    } else {
                      this.delOU_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.delOU_error_msg = "Invalid response!";
                  }
                });
      },
      delChildNode(nedo) {
        const indx = this.node.children.findIndex(v => v.name === nedo.name);
        this.node.children.splice(indx, 1);
      },
      onDragOver() {
        this.drag_highlight = true;
        //console.log("Pump drag entered Company: (" + this.node.id + ") " + this.node.name);
      },
      onDragLeave() {
        this.drag_highlight = false;
        //console.log("Pump drag exited Company: (" + this.node.id + ") " + this.node.name);
      },
      onDrop(evt) {
        this.drag_highlight = false;
        const dataid = evt.dataTransfer.getData('id');
        const dataname = evt.dataTransfer.getData('name');
        //console.log("Dropped PumpID: " + dataid + " over Company: (" + this.node.id + ") " + this.node.name);
        const droppeddata = {"id":dataid, "name": dataname, "newsiteid":this.node.id, "newsitename":this.node.name};
        this.$emit('datadropped', droppeddata);
      },
      dropEmitRelay(droppeddata) {
        this.$emit('datadropped', droppeddata);
        //this.$parent.$emit('ouclicked', this.node);
      },
  }
};
</script>
<style scoped>
.node-tree-item {
  padding-left: 16px;
  margin: 6px 0;
}
.node-tree-item :hover {
  cursor: pointer;
}
.label {
  background-color: rgba(#FFF, 0.8);
}
</style>