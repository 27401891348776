<template>
    <v-container>
        <v-row>
            <v-col>
                <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
                <template v-if="pump['daq'].length > 0">
                    <div class="gtm-img-wrapper d-none d-sm-block" v-if="pumpIsDual">
                        <template v-if="boostPumpIsInstalled">
                            <v-img class="gtm-img" src="@/assets/adk_lp_system_dual.png" ></v-img>
                            <div id="pcut1_dual">{{ pump["daq"][pcut1].toFixed() }}</div>
                        </template>
                        <v-img v-else src="@/assets/adk_lp_system_dual_noboost.png" ></v-img>
                        <div id="pcut2_dual">{{ pump["daq"][pcut2].toFixed() }}</div>
                        <div id="tempsense_dual">{{ pump["daq"][tempsense].toFixed(0) }}</div>
                    </div>
                    <div class="gtm-img-wrapper d-none d-sm-block" v-else>
                        <template v-if="boostPumpIsInstalled">
                            <v-img class="gtm-img" src="@/assets/adk_lp_system.png" ></v-img>
                            <div id="pcut1">{{ pump["daq"][pcut1].toFixed() }}</div>
                        </template>
                        <v-img v-else src="@/assets/adk_lp_system_noboost.png" ></v-img>
                        <div id="pcut2">{{ pump["daq"][pcut2].toFixed() }}</div>
                        <div id="tempsense">{{ pump["daq"][tempsense].toFixed(0) }}</div>
                    </div>
                    <v-simple-table class="d-block d-sm-none">
                        <template v-slot:default>
                            <tbody>
                            <tr v-if="boostPumpIsInstalled">
                                <td>Inlet Water Pressure</td>
                                <td>{{ pump["daq"][pcut1].toFixed() }} PSI</td>
                            </tr>
                            <tr>
                                <td>Boost Pressure</td>
                                <td>{{ pump["daq"][pcut2].toFixed() }} PSI</td>
                            </tr>
                            <tr>
                                <td>Cutting Water Temperature</td>
                                <td>{{ pump["daq"][tempsense].toFixed(0) }} °C</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "ADKLPSystemTab",
  props: {
      pump: null
  },
  data(){
      return {
          u16hmistatei: null,

          instpumpsi: null,
          boostinsti: null,

          pcut1:null,
          pcut2:null,
          tempsense:null
      }
  },
  created(){
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");
    this.instpumpsi = vhmi_mcdvars_list.indexOf("app.machine.multiplePumpManager.u8InstalledPumpCount");
    this.boostinsti = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineLPFluidController.boostPumpManager.boBoostPump_IsInstalled");

    this.pcut1 = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineLPFluidController.pressureSensor_Cut1.s16PressureSense_PSI_LPF32ms");
    this.pcut2 = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineLPFluidController.pressureSensor_Cut2.s16PressureSense_PSI_LPF32ms");
    this.tempsense = vhmi_mcdvars_list.indexOf("app.machine.sapController.machineLPFluidController.thermalSensor_CuttingWater.s16TempSense_Deg");
  },
  computed: {
      pumpIsDual() {
          return (this.pump["daq"][this.instpumpsi] == 2);
      },
      boostPumpIsInstalled() {
          return (this.pump["daq"][this.boostinsti] == 1);
      }
  }
}
</script>

<style scoped>
.gtm-img-wrapper {
    position:relative;
    max-width: 100%;
    min-height:100%;
    height: auto;
    width: auto\9; /* ie8 */
}
.gtm-img {
    padding: 0;
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
}
#pcut1     { position:absolute; top:49%; right:66%; font-size: 1.5em; }
#pcut2     { position:absolute; top:36%; right:3%; font-size: 1.5em; }
#tempsense { position:absolute; top:48%; right:3%; font-size: 1.5em; }

#pcut1_dual     { position:absolute; top:60%; right:66%; font-size: 1.5em; }
#pcut2_dual     { position:absolute; top:51%; right:6%; font-size: 1.5em; }
#tempsense_dual { position:absolute; top:60%; right:6%; font-size: 1.5em; }
</style>