<template>
  <div>
    <div class="d-flex justify-space-between mb-6 bg-surface-variant">
      <v-btn @click="goto_prev_step">Prev</v-btn>
      <v-btn v-if="curr_step_index >= 1" @click="replay_previous_step">Replay Previous Step</v-btn>
      <v-btn @click="goto_next_step">Next</v-btn>
    </div>
    <div class="d-flex justify-center mb-6 bg-surface-variant">
      <b>{{ video_legend }}</b>
    </div>
    <video ref="videoPlayer" class="video-js vjs-show-big-play-button-on-pause"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: 'VideoPlayer',
  props: {
    videosrc: {
      type: String,
    },
    steps:[],
  },
  data() {
    return {
      player: null,
      video_options: {
        type: Object,
        default() {
          return {};
        }
      },
      video_legend: "Step 1",
      curr_step_index: 0,
      prev_step_index: 0,
      step_changed_manually : false
    }
  },
  mounted() {
    this.setup_video_options(this.videosrc);
    this.player = videojs(this.$refs.videoPlayer, this.video_options, () => {
        //this.player.log('onPlayerReady', this);
        /*this.player.on('timeupdate', function () {
            //console.log(this.player_.currentTime());
            if (this.player_.currentTime() > 5 ) {
                //console.log(this);
                //this.player_.currentTime(0);
                //window.alert('Video has Played for 15 seconds!');
            }
        });*/

        this.player.playsinline("true");
        this.setup_controlbar_buttons();
        this.player.on('timeupdate',this.monitor_step_position);
        //this.$refs.videoPlayer.addEventListener('timeupdate',(event)=> {this.monitor_step_position(event);});
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch:{
    /*videosrc: function(vidsrc){
      this.setup_video_options(vidsrc);
    },*/
    curr_step_index: function (idx){
      this.video_legend = "Step " + (idx+1);
    }
  },
  methods:{
    setup_video_options(vidsrc){
      this.video_options = {
        autoplay: false,
        controls: true,
        controlBar: {
          volumePanel: {
            inline: false,
            vertical: true
          }
        },
        fluid:true,
        sources: [
          {
            src: vidsrc,
            type: 'video/mp4'
          }
        ]
      };
    },
    setup_controlbar_buttons(){
        var prevbtn = this.player.controlBar.addChild("button", {}, 1);
        var prevbtnDOM = prevbtn.el();
        prevbtnDOM.innerHTML = "<i class='v-icon mdi mdi-chevron-double-left theme--dark'></i>";
        prevbtnDOM.onclick = this.goto_prev_step;

        var nextbtn = this.player.controlBar.addChild("button", {}, 2);
        var nextbtnDOM = nextbtn.el();
        nextbtnDOM.innerHTML = "<i class='v-icon mdi mdi-chevron-double-right theme--dark'></i>";
        nextbtnDOM.onclick = this.goto_next_step;

        var replaybtn = this.player.controlBar.addChild("button", {}, 3);
        var replaybtnDOM = replaybtn.el();
        replaybtnDOM.innerHTML = "<i class='v-icon mdi mdi-rotate-left theme--dark'></i>";
        replaybtnDOM.onclick = this.replay_previous_step;
    },
    monitor_step_position(){
      //console.log(event);
      if (this.$refs.videoPlayer != null) {
        let currtime = this.$refs.videoPlayer.currentTime;
        //console.log(currtime);

        if (currtime > this.steps[this.steps.length-1]){
          this.curr_step_index = this.steps.length-1;
        } else {
          for (var i = 0; i < this.steps.length-1; i++){
            if ((currtime > this.steps[i]) && 
                (currtime < this.steps[i+1]) ) {
                  this.curr_step_index = i;
                  break;
            }
          }
        }

        if (this.curr_step_index != this.prev_step_index) {
        /*if ( (this.curr_step_index < this.steps.length-1) 
            && (currtime == this.steps[this.curr_step_index+1]-1) ) {*/
          if (!this.step_changed_manually) this.player.pause();
          this.step_changed_manually = false;
          this.prev_step_index = this.curr_step_index;
        }
      }
    },
    goto_prev_step(){
      if (this.curr_step_index > 0){
        this.curr_step_index--;
        this.player.currentTime(this.steps[this.curr_step_index]);
      }
    },
    goto_next_step(){
      if (this.curr_step_index < (this.steps.length-1)){
        this.curr_step_index++;
        this.player.currentTime(this.steps[this.curr_step_index]);
      }
    },
    replay_previous_step(){
      //this.player.currentTime(this.steps[this.curr_step_index]);
      this.goto_prev_step();
      this.player.play();
      this.step_changed_manually = true;
    }
  }
}
</script>
<style scoped lang="css">
@import "../../node_modules/video.js/dist/video-js.css";
</style>