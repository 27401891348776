<template>
  <div class="dtcdetails">
    <v-col cols="12" sm-11>
      <v-card class="ma-2" flat>
        <v-card-title>
          <template v-if="edit_dtc_title">
            <v-row>
            <v-col cols="2" sm-11><v-text-field outlined v-model="dtc_instance.shortLabel"></v-text-field></v-col>
            <v-col cols="1" sm-11> - </v-col>
            <v-col cols="7" sm-11>
              <v-text-field outlined v-model="dtc_topic.title"
                :rules="[v => v.length<=60 || 'Max 60 characters']" counter></v-text-field>
            </v-col>
            <v-col cols="2" sm-11>({{ dtc_instance.scope }})</v-col >              
            </v-row>
          </template>
          <h2 v-else>{{ dtc_instance.shortLabel }} - {{ dtc_topic.title }} ({{ dtc_instance.scope }})</h2>
          <v-spacer></v-spacer>
          <EditButton v-if="view_edit_controls" v-model="edit_dtc_title" />
        </v-card-title>
      </v-card>
      <v-card class="ma-2">
        <v-card-title>
          <h4>Description</h4>
          <v-spacer></v-spacer>
          <EditButton v-if="view_edit_controls"  v-model="edit_dtc_description" />
        </v-card-title>
        <v-card-text v-if="edit_dtc_description">
          <v-textarea outlined counter :rules="[v => v.length<=500 || 'Max 500 characters']"
            v-model="dtc_topic.description"></v-textarea>
        </v-card-text>
        <v-card-text v-else>
          {{ dtc_topic.description }}
        </v-card-text>
      </v-card>
      <v-card class="ma-2">
        <v-card-title>
          <h4>Instance</h4>
          <v-spacer></v-spacer>
          <EditButton v-if="view_edit_controls"  v-model="edit_dtc_instance" />
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">ShortLabel</th>
                <th class="text-center">Type</th>
                <th class="text-center">BaseCode</th>
                <th class="text-center">Scope</th>
                <th class="text-left">Label</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="edit_dtc_instance">
                <td class="text-center">{{ dtc_instance.shortLabel }}</td>
                <td class="text-center">
                  <v-combobox v-model="dtc_topic.type" :items="[1,2,3]" outlined></v-combobox>
                </td>
                <td class="text-center">{{ dtc_instance.basecode }}</td>
                <td v-if="edit_dtc_instance" class="text-left"><v-text-field outlined v-model="dtc_instance.scope"
                :rules="[v => v.length<=60 || 'Max 60 characters']" counter></v-text-field></td>
                <td v-else class="text-center">{{ dtc_instance.scope }}</td>
                <td v-if="edit_dtc_instance" class="text-left"><v-text-field outlined v-model="dtc_instance.label"
                :rules="[v => v.length<=60 || 'Max 60 characters']" counter></v-text-field></td>
                <td v-else class="text-left">{{ dtc_instance.label }}</td>
              </tr>
              <tr v-else>
                <td class="text-center">{{ dtc_instance.shortLabel }}</td>
                <td class="text-center">
                  <v-chip :color="levelColor(dtc_topic)">{{ levelConvert(dtc_topic) }}</v-chip>
                </td>
                <td class="text-center">{{ dtc_instance.basecode }}</td>
                <td v-if="edit_dtc_instance" class="text-left"><v-text-field outlined v-model="dtc_instance.scope"
                :rules="[v => v.length<=60 || 'Max 60 characters']" counter></v-text-field></td>
                <td v-else class="text-center">{{ dtc_instance.scope }}</td>
                <td v-if="edit_dtc_instance" class="text-left"><v-text-field outlined v-model="dtc_instance.label"
                :rules="[v => v.length<=60 || 'Max 60 characters']" counter></v-text-field></td>
                <td v-else class="text-left">{{ dtc_instance.label }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card class="ma-2" v-if="((typeof(dtc_topic.details) == 'string') && (dtc_topic.details != '')) || view_edit_controls">
        <v-card-title>
          <h4>Details</h4>
          <v-spacer></v-spacer>
          <EditButton v-if="view_edit_controls"  v-model="edit_dtc_details" />
        </v-card-title>
        <v-card-text v-if="edit_dtc_details">
          <v-textarea outlined counter :rules="[v => v.length<=3500 || 'Max 3500 characters']"
            v-model="dtc_topic.details"></v-textarea>
        </v-card-text>
        <v-card-text v-else-if="((typeof(dtc_topic.details) == 'string') && (dtc_topic.details != ''))">
          <markdown-it-vue-light class="md-body" :content="dtc_topic.details" />
          <!--pre>{{ dtc_topic.details }}</pre-->
        </v-card-text>
      </v-card>
      <v-card class="ma-2" v-if="((dtc_details.troubleshootings != null) && (dtc_details.troubleshootings.length > 0)) || view_edit_controls" >
        <v-card-title>
          <h4>Troubleshooting</h4>
          <v-spacer></v-spacer>
          <EditButton v-if="view_edit_controls"  v-model="edit_dtc_troubleshootings" />
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left trouble">Cause</th>
                <th class="text-left trouble">Solution</th>
                <th v-if="edit_dtc_troubleshootings" class="text-left trouble">Remove</th>
              </tr>
            </thead>
            <tbody v-if="edit_dtc_troubleshootings">
              <tr v-for="trouble,idx in dtc_troubleshootings" :key="idx">
                <td>
                  <v-textarea outlined counter :rules="[v => v.length<=350 || 'Max 350 characters']"
                    v-model="trouble.cause"></v-textarea>
                </td>
                <td class="text-left">
                  <v-textarea outlined counter :rules="[v => v.length<=600 || 'Max 600 characters']"
                    v-model="trouble.solution"></v-textarea>
                  <!--pre>{{ trouble.solution }}</pre-->
                </td>
                <td>
                  <v-btn @click="remove_troubleshooting(idx)">Remove</v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="trouble,idx in dtc_troubleshootings" :key="idx">
                <td class="text-left">{{ trouble.cause }}</td>
                <td class="text-left">
                  <markdown-it-vue-light class="md-body" :content="trouble.solution" />
                  <!--pre>{{ trouble.solution }}</pre-->
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text>
          <v-btn v-if="view_edit_controls && edit_dtc_troubleshootings" @click="add_blank_troubleshooting">Add Troubleshooting</v-btn>
        </v-card-text>
      </v-card>

      <v-card v-if="view_edit_controls" flat>
        <v-card-text>
          <v-btn class="primary" @click="saveChangesClick">Save Changes</v-btn>  
          <v-dialog v-model="saveChangesDialog" width="500">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"> Confirm Save </v-card-title>
              <v-card-text> Are you sure you want to Save changes? </v-card-text>
              <v-card-text> {{ save_error_msg }} </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="save_dtc_changes" > Confirm </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> 
          &nbsp;
          <v-btn class="primary" @click="revert_dtc_changes">Revert Changes</v-btn>  
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
  import EditButton from "../components/EditButton.vue";
import colors from 'vuetify/lib/util/colors';

import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js'
import 'markdown-it-vue/dist/markdown-it-vue-light.css'

import _ from 'lodash'

  export default {
    name: 'DTCDetailed',
    components: {
      EditButton,
      MarkdownItVueLight
    },
    data () {
      return {
        view_edit_controls: false,
        edit_dtc_title: false,
        edit_dtc_description: false,
        edit_dtc_instance: false,
        edit_dtc_details: false,
        edit_dtc_troubleshootings: false,
        save_error_msg: null,
        saveChangesDialog: false,
        dtc_details_original: {
          "instance": {
            id: 0,
            shortLabel: "",
            scope: "",
            label: "",
          },
          "topic": {
            id:"",
            title: "",
            description: "",
            basecode: 0,
            details: "",
            type: 0
          },
          troubleshootings: [],
        },
        dtc_details:null,
      }
    },
    computed: {
      dtc_topic() {
        return this.dtc_details["topic"]
      },
      dtc_instance() {
        return this.dtc_details["instance"]
      },
      dtc_troubleshootings() {
        return this.dtc_details["troubleshootings"]
      },
    },
    beforeMount(){
      this.dtc_details = _.cloneDeep(this.dtc_details_original);
      //this.dtc_details = JSON.parse(JSON.stringify(this.dtc_details_original));
    },
    mounted(){
      //this.$emit('loadstart');
      const useraccessdict = this.$store.getters.get_useraccessdict();
      //console.log(useraccessdict);
      this.view_edit_controls = (useraccessdict != null) && useraccessdict["editdtc"];
      this.fetchDTCdict();      
    },
    methods: {
      fetchDTCdict() {
        this.$emit('loadstart');
        this.$api.get(this.$dtcdburl + "/sl/" + this.$route.params["shortlabel"])
        .then(response => {
          //console.log(response.data);
          this.dtc_details_original = response.data;
          this.dtc_details = _.cloneDeep(this.dtc_details_original);
          //this.dtc_details = JSON.parse(JSON.stringify(this.dtc_details_original));
          // Set the initial number of items
          //this.totalRows = this.bill_list.length;
          this.$emit('loadcomplete');
          //alert(typeof this.dtc_details.details);
        });
      },
      levelConvert: function (rowobject) {
        var severity = "";
        switch(rowobject.type) {
          case "info":
            severity = "Information";
            break;
          case "warn":
            severity = "Warning";
            break;
          case "error":
            severity = "Error";
            break;
          default:
            severity = "null";
            break;
            // code block
        }
        return severity;
      },
      levelColor: function (rowobject) {
        var severity = "";
        switch(rowobject.type) {
          case "info":
            severity = colors.blue.lighten4;
            break;
          case "warn":
            severity = "yellow";
            break;
          case "error":
            severity = "red";
            break;
          default:
            severity = "white";
            break;
            // code block
        }
        return severity;
      },
      add_blank_troubleshooting(){
        this.dtc_details.troubleshootings.push({
          "id":null, 'basecode':this.dtc_details_original['topic']['basecode'],
          "cause":'',"solution":''})
      },
      remove_troubleshooting(delidx){
        this.dtc_details.troubleshootings.splice(delidx,1);
      },
      saveChangesClick(){
        this.save_error_msg = null;
        this.saveChangesDialog = true;
      },
      save_dtc_changes() {
        //console.log(this.dtc_details);
        this.save_error_msg = null;
        const dtc_changes_dict = {'code': 'save_dtc_changes',
          'olddict': this.dtc_details_original,
          'newdict': this.dtc_details
        }
        this.$api.post(this.$dtcdburl + '/update', dtc_changes_dict)
                .then(response => {
                  if (response.data['code'] == 'save_dtc_changes') {
                    if (response.data['success'])
                    {
                      this.fetchDTCdict();
                      this.saveChangesDialog = false;
                    } else {
                      this.save_error_msg = response.data['errmsg'];
                    }
                  } else {
                    this.save_error_msg = "Invalid response!";
                  }
                });
      },
      revert_dtc_changes() {
        this.dtc_details = _.cloneDeep(this.dtc_details_original);
        //this.dtc_details = JSON.parse(JSON.stringify(this.dtc_details_original));
        //this.fetchDTCdict();
      },
    }
  }
</script>
<style scoped>
th.trouble {
      font-size: 20px !important;
 }
 pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>