export default {
    //pumps_in_reporter : [],
    reporter_pumps_onlinestats : [],
    
    get_pumps_in_reporter : function () {
        this.$api.post(this.$pumpreporterurl + '/listpumps')
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "list_pumps_to_report")
                {
                  this.pumps_in_reporter = response.data["pumpiplist"];
                  this.reporter_pumps_onlinestats = response.data["onlinestats"];
                }
                //this.$emit('loadcomplete');
              });
    },
    add_pump_to_vhmi(pumpid,pumpip) {
      //console.log("Add: ", pumpid, pumpip);
      if ((pumpid != null) && (pumpip != null)) {
        this.$store.commit('add_vhmi_pump', {pumpid, pumpip});
        const authtoken = this.$store.getters.get_session_id();
        const monpumpsdict = {"authtoken": authtoken,"pumpidlist":this.$store.getters.get_vhmi_pumpids()};
        this.$socket.emit("configure_monitored_pumps", monpumpsdict);
        //this.$forceUpdate();
        this.pumps_onvhmi_check();
      } else {
        alert ("Adding invalid pump not allowed!")
      }
    },
    pumps_onvhmi_check(){
      var vhmipumpids = this.$store.getters.get_vhmi_pumpids();
      this.pumpslist.forEach( (pump, idx) => {
        if (vhmipumpids.includes(pump.id))
          this.$set(this.pumpslist[idx],'onvhmi', true);
        else this.$set(this.pumpslist[idx],'onvhmi', false);
      });
    },
    pumps_onreporter_check(){
      const reporterpumps = this.pumps_in_reporter;
      this.pumpslist.forEach( (pump, idx) => {
        if (reporterpumps.includes(pump.ip))
          this.$set(this.pumpslist[idx],'onreporter', true);
        else this.$set(this.pumpslist[idx],'onreporter', false);
      });
    },
    add_pump_to_reporter(pumpid,pumpip) {
      //console.log("ReporterAdd: ", pumpid, pumpip);
      const newchilddata = {'code':'add_pumps_to_report', "pumpidlist":[pumpid]}
      //console.log(newchilddata);
      this.$api.post(this.$pumpreporterurl + '/addpumps', newchilddata)
              .then(response => {
                if (response.data['code'] == 'add_pumps_to_report') {
                  if (response.data['success'])
                  {
                    this.pumps_in_reporter.push(pumpip);
                    this.pumps_onreporter_check();
                  } else {
                    alert("ReporterAdd: "+ response.data['errmsg']);
                  }
                } else {
                  alert("ReporterAdd: "+ "Invalid response!");
                }
              });
      this.pumps_onreporter_check();
    },
    del_pump_from_reporter(pumpip) {
      //console.log("ReporterDel: ", pumpip);
      const newchilddata = {'code':'del_pumps_from_report', "pumpiplist":[pumpip]}
      //console.log(newchilddata);
      this.$api.post(this.$pumpreporterurl + '/delpumps', newchilddata)
              .then(response => {
                //console.log(response.data);
                if (response.data['code'] == 'del_pumps_from_report') {
                  if (response.data['success'])
                  {
                    var index = this.pumps_in_reporter.indexOf(pumpip);
                    if (index !== -1) 
                      this.pumps_in_reporter.splice(index, 1);
                    this.pumps_onreporter_check();
                  } else {
                    alert("ReporterDel: "+ response.data['errmsg']);
                  }
                } else {
                  alert("ReporterDel: "+ "Invalid response!");
                }
              });
      this.pumps_onreporter_check();
    },
    goto_pumpreporter_varspage(pumpid){//pumpip
      //console.log("goto_pumpreporter_vars", pumpid,pumpip);
      this.$router.push({ path: '/pumpvars', query: { "pumpid": pumpid } });
    }
}