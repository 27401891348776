<template>
<div>
  <template v-if="pump['daq'].length > 0">
    <v-btn class="primary" @click="launch_pump_capture(pump['ipaddr'])">Refresh</v-btn>
    <!--v-btn class="primary" @click="get_pump_capture(pump['ipaddr'])">Blocking</v-btn-->
    <span>&nbsp;{{ user_message }} {{ timer_message }}</span>
    <apexchart width="750" height="300" type="line" :options="options" :series="pumpseries"></apexchart>
  </template>
  <v-container v-else>
    <v-row>
        <v-col>
            <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
        </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "PumpCaptureTab",
  props: {
      pump: null
  },
  data() {
    return {
      options: {"yaxis": {"decimalsInFloat": 2}},
      pump_capture_dict: null,
      user_message: null,
      loadingdata: false,
      loadTimeoutTimer: null,
      //loadIntervalTimer: null,
      probetries: 0,
      maxprobetries:30,
      probeInterval: 1000,
      timeElapsed: 0,
      timer_message: null,
    }
  },
  created() {
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");
  },
  computed:{
    pumpseries() {
      if (this.pump_capture_dict != null)
        return this.pump_capture_dict;//[{name: "Pressure", data: this.pump_capture_dict[0]["data"]}];
      else return [{name: "None", data: []}];
    }
  },
  methods: {
    launch_pump_capture(pumpip) {
      //this.$emit('loadstart');
      if (!this.loadingdata) {
        this.loadingdata = true;
        this.probetries = 0;
        this.user_message = "Loading...";
        this.timer_message = "";
        this.$api.get(this.$pumpcaptureurl + "/launch?pumpip=" + pumpip)
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "launch_data_capture")
          {
            if (response.data["success"] == true) 
            {
              if (response.data["ready"] == true) 
              {
                if (response.data["value"]["code"] == "get_capture_data") {

                  if (response.data["value"]["success"] == true) 
                  {
                        this.pump_capture_dict = response.data["value"]["signals"];
                        //this.$emit('loadcomplete');
                        const d = new Date();
                        let datetext = d.toLocaleString();
                        this.user_message = "Loaded at " + datetext;
                  } else {
                    this.user_message = "ERROR: " + response.data["value"]["error_message"];
                  }
                } else {
                  this.user_message = "ERROR: Invalid data received!";
                }
                this.loadingdata = false;
              }
              else {
                //this.loadTimeoutTimer = setTimeout(() => this.probe_pump_capture(pumpip), this.probeInterval);
                this.loadTimeoutTimer = setTimeout(() => this.probe_timing_handle(pumpip), this.probeInterval);
              }
            } else {
              this.user_message = "ERROR: " + response.data["error_message"];
              this.loadingdata = false;
            }
          }
        });
      }
    },
    probe_pump_capture(pumpip) {
      //this.$emit('loadstart');
      if (this.loadingdata) {
        this.$api.get(this.$pumpcaptureurl + "/probe?pumpip=" + pumpip)
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "probe_capture_data")
          {
            if (response.data["success"] == true) 
            {
              if (response.data["ready"] == true) 
              {
                //console.log(response.data["value"]);
                if (response.data["value"]["code"] == "get_capture_data") {

                  if (response.data["value"]["success"] == true) 
                  {
                        this.pump_capture_dict = response.data["value"]["signals"];
                        const capturetime = new Date(response.data["value"]["datetime"]);
                        //this.$emit('loadcomplete');
                        const d = new Date();
                        let datetext = d.toLocaleString();
                        this.user_message = "CaptureTime: " + capturetime.toLocaleString() + " | ";
                        this.user_message += "Loaded at " + datetext;
                  } else {
                    this.user_message = "ERROR: " + response.data["value"]["error_message"];
                  }
                } else {
                  this.user_message = "ERROR: Invalid data received!";
                }
                this.loadingdata = false;
              } /*else {
                if (++this.probetries < this.maxprobetries) {
                  clearTimeout(this.loadTimeoutTimer);
                  this.loadTimeoutTimer = setTimeout(() => this.probe_pump_capture(pumpip), this.probeInterval);
                  this.timeElapsed = (this.probeInterval*this.probetries)/1000;
                  this.timer_message = " | " + String(this.timeElapsed) + "s";
                } else {
                  this.user_message = "ERROR: Loading Graph Timed out! Try again.";
                  this.timer_message = " | " + String(this.timeElapsed) + "s";
                  this.timer_message = null;
                  this.loadingdata = false;
                }
              }*/
            } else {
              this.user_message = "ERROR: " + response.data["error_message"];
              this.loadingdata = false;
            }
          }
        });
      }
    },
    probe_timing_handle(pumpip) {
      if (this.loadingdata) {
        ++this.probetries;
        if (this.probetries % 3 == 0)
          this.probe_pump_capture(pumpip);

        if (this.probetries < this.maxprobetries) {
          clearTimeout(this.loadTimeoutTimer);
          this.loadTimeoutTimer = setTimeout(() => this.probe_timing_handle(pumpip), this.probeInterval);
          this.timeElapsed = (this.probeInterval*this.probetries)/1000;
          this.timer_message = " | " + String(this.timeElapsed) + "s";
        } else {
            this.user_message = "ERROR: Loading Graph Timed out! Try again.";
            this.timer_message = " | " + String(this.timeElapsed) + "s";
            this.loadingdata = false;
        }
      }
    },

    get_pump_capture(pumpip) {
      //this.$emit('loadstart');
      if (!this.loadingdata) {
        this.loadingdata = true;
        this.probetries = 0;
        this.user_message = "Loading...";
        /*if (this.loadIntervaltimer != null) clearInterval(this.loadIntervaltimer);
        this.loadIntervaltimer = setInterval(() => {
          this.probetries++;
          this.timeElapsed = (this.probeInterval*this.probetries)/1000;
          this.timer_message = " | " + String(this.timeElapsed) + "s";          
        }, this.probeInterval);*/

        this.$api.get(this.$pumpcaptureurl + "/get?pumpip=" + pumpip)
        .then(response => {
          //console.log(response.data);
          if (response.data["code"] == "get_capture_data")
          {
            if (response.data["success"] == true) 
            {
              this.pump_capture_dict = response.data["signals"];
              //this.$emit('loadcomplete');
              const d = new Date();
              let datetext = d.toLocaleString();
              this.user_message = "Loaded at " + datetext;
            } else {
              this.user_message = "ERROR: " + response.data["error_message"];
            }
            this.loadingdata = false;
            //clearInterval(this.loadIntervaltimer)
          }
        });
      }
    },
  }
}
</script>
