export default {
    enum_pump_states : {
        0:"Pump Off", 1:"PreEnable Cooling", 2:"PostEnable Cooling", 3:"Init", 4:"IndexPulseCalibration",
        5:"Homing", 6:"Purge", 7:"Running", 8:"RunWithHeadClosed", 9:"Stopping", 10:"DeInitialize",
        11:"Error", 12:"LocalEStop", 13:"RemoteEstop", 14:"WaitingForRemoteEnable"
    },

    represent_pump_state: function(pumpdata){
        const pump_connection_state = pumpdata["connstate"];
        if (pump_connection_state != null) {
            const pumpstate = pump_connection_state["state"];
            if (pumpstate == 'daqevents') {
                if (pumpdata["daq"][this.u16hmistatei] in this.enum_pump_states)
                    return this.enum_pump_states[pumpdata["daq"][this.u16hmistatei]];
                else
                    return "daqevents " + pumpdata["daq"][this.u16hmistatei];
            }
            else if (pumpstate == 'readingparams') {
                const readpercent = (100*parseInt(pumpdata["connstate"]["substate"])) / parseInt(pumpdata["connstate"]["paramcount"]);
                return "readingparams - " + readpercent.toFixed(2) + "%";
            }
            else {
                return pumpstate;
            }
        }
    }
}