<template>
  <div class="pumphealthprogress">
          <!--v-card>
            <v-card-title>
              <h3>PumpProgress - {{ pumpcname }}</h3>          
            </v-card-title>
            <v-card-subtitle>
              {{ pumpnname }} ({{ pumpip }})
            </v-card-subtitle>
          </v-card-->
            
          <v-container>
            <v-row>
              <v-col cols="12" sm="12"><h3>PumpProgress - {{ pumpcname }}</h3>{{ pumpnname }} ({{ pumpip }})</v-col>
              <v-col cols="6" sm="3">
                  <v-menu v-model="startdatemenu" :close-on-content-click="false" :nudge-right="40" 
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="startdate" readonly v-bind="attrs" v-on="on"
                      label="Select Start Date" prepend-icon="mdi-calendar"                    
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startdate" @input="searchreqdatemenu = false" ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3">
                  <v-menu v-model="enddatemenu" :close-on-content-click="false" :nudge-right="40" 
                  transition="scale-transition" offset-y min-width="auto" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="enddate" readonly v-bind="attrs" v-on="on"
                      label="Select End Date" prepend-icon="mdi-calendar"                    
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="enddate" @input="searchreqdatemenu = false" ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="2">
                <v-btn color="primary" @click.prevent="get_health_progress(pumpip,startdate,enddate)">Get Progress</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="true">
            <v-row>
              <v-col>
                <v-data-table :headers="progressheaders" :items="progress_table" :search="progresssearch"
                  :footer-props="{'items-per-page-options':[5, 10, 30, -1]}" :items-per-page="10" dense
                  must-sort :sort-by.sync="sortProgressBy" :sort-desc.sync="sortProgressDesc" :custom-sort="customSort">
                  <template #item.datetime="{ item }">
                    {{ get_date_format(item.timestamp) }}
                    <!--{{ new Date(item.timestamp).toLocaleDateString("ko-KR", {year: 'numeric', month: 'numeric', day: 'numeric' }) }}-->
                  </template>
                  <template #item.timeon="{ item }">
                    {{ get_hoursmins_textformat(item.timeon) }}
                  </template>
                  <template #item.timetotalrun="{ item }">
                    {{ get_hoursmins_textformat(item.timetotalrun) }}
                  </template>
                  <template #item.timetotalrunhmi="{ item }">
                    {{ get_hoursmins_textformat(item.timetotalrunhmi,true) }}
                  </template>
                  <template #item.p1pumpedvolume="{ item }">
                    {{ item.p1pumpedvolume.toFixed(2) }} litres
                  </template>
                  <template #item.p2pumpedvolume="{ item }">
                    {{ item.p2pumpedvolume.toFixed(2) }} litres
                  </template>
                  <template v-slot:footer>
                    <span style="font-size:10px;">* Estimated based on strokelength of last stroke at the time of capture</span>
                  </template>
                </v-data-table>
              </v-col>
              
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <!--{{ daqdata[stats_tvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_strokeCount" :series="strokeCountSeries"></apexchart>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <!--{{ daqdata[stats_tvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_RunTime" :series="runtimeSeries"></apexchart>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <!--{{ daqdata[stats_tvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_PumpedVolume" :series="pumpedVolumeSeries"></apexchart>
              </v-col>
              <v-col cols="12" md="6" class="d-flex justify-center align-center">
                <!--{{ daqdata[p1stats_stcvspsi_i].length }}
                {{ daqdata[p2stats_stcvspsi_i].length }}-->
                <apexchart :width="graphwidth" height="300" type="bar" :options="ChartOptions_psiStrokeStats" :series="psiStrokeStatsSeries"></apexchart>
              </v-col>
            </v-row>
            <!--v-row>
              <table>
                <tr v-for="daydata,idx in progress_difference_list" :key="idx">
                  <td>{{ get_date_format(daydata[0]) }}</td>
                  <td v-for="psistats,idx1 in daydata[1][p1stats_stcvspsi_i]" :key="idx1">
                    {{psistats}}
                  </td>
                </tr>
              </table>
            </v-row-->
          </v-container>
          <span v-else>{{ report_text }}</span>
  </div>
</template>

<script>
  import dateutils from '../plugins/date_utilities';

  export default {
    name: 'PumpHealthProgress',
    data () {
      return {
        loading: true,
        pumpip:null,pumpcname:null,pumpnname:null,
        startdatemenu: false,
        enddatemenu: false,
        startdate: "2024-07-01",
        //enddate: "2024-08-13",
        //startdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        enddate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        
        timetotalrunhmii:null,
        timetotalruni:null,
        timeoni:null,
        p1strokecounti:null,
        p2strokecounti:null,
        p1strokedlength0i:null,
        p1strokedlength1i:null,
        p1plungerdiameteri:null,
        p2strokedlength0i:null,
        p2strokedlength1i:null,
        p2plungerdiameteri:null,
        p1stats_stcvspsi_i:null,
        p2stats_stcvspsi_i:null,

        progress_profile:[],
        progress_list:[],//[("date",[...]),("date",[...]),...]
        progress_difference_list:[],
        progress_table:[],
        report_text:"",

        progressheaders:[
          { text: 'Date', value: 'datetime', align: 'center', filterable: true, sortable: true },
          { text: 'RuntimeHMI', value: 'timetotalrunhmi', align: 'right', filterable: false, sortable: false },
          { text: 'TotalRuntime', value: 'timetotalrun', align: 'right', filterable: true, sortable: true },
          { text: 'OnTime', value: 'timeon', align: 'right', filterable: false, sortable: false },
          { text: 'Pump1 StrokeCount', value: 'p1strokecount', align: 'right', filterable: false, sortable: false },
          { text: 'Pump2 StrokeCount', value: 'p2strokecount', align: 'right', filterable: false, sortable: false },
          { text: 'Pump1 PumpedVolume*', value: 'p1pumpedvolume', align: 'right', filterable: false, sortable: false },
          { text: 'Pump2 PumpedVolume*', value: 'p2pumpedvolume', align: 'right', filterable: false, sortable: false }
        ],
        progresssearch:"",
        sortProgressBy:"datetime", sortProgressDesc:false,

        graphwidth:450,//px
        ChartOptions: {
          chart:{
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            title:{text:''},
            categories: [],
            labels: {
              formatter: (val) => { return val },
            }
          },
          yaxis: {
            decimalsInFloat:0,
            title: { text: '' },
            labels: {
              formatter: (val) => { return val },
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            x: {formatter: (value) => value + "°C" },
            y: { 
              formatter: function (val) { 
                  return "$ "+val+"PSI"; 
                } 
              },
            /*custom: function(series,seriesIndex,dataPointIndex) {
              return '<div><span>'+series[seriesIndex][dataPointIndex]+'</span></div>';
            }*/
          },
          legend:{show:true,position:'top'}
        },

      }
    },
    computed:{
      ChartOptions_strokeCount(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'StrokeCount'};
        choptions['xaxis']['title']['text'] = 'Date';
        choptions['xaxis']['categories'] = this.progress_list.map((x) => x[0]);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_date_format;
        choptions['yaxis']['title']['text'] = 'Strokes';
        //if (this.progress_list.length > 0)
        //  choptions['yaxis']['min'] = this.progress_list[0][1][this.p1strokecounti];
        choptions['forceNiceScale'] = true;
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_date_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_RunTime(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'RunTime'};
        choptions['xaxis']['title']['text'] = 'Date';
        choptions['xaxis']['categories'] = this.progress_list.map((x) => x[0]);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_date_format;
        choptions['yaxis']['title']['text'] = 'Hours';
        //if (this.progress_list.length > 0)
        //  choptions['yaxis']['min'] = this.progress_list[0][1][this.timetotalrunhmii];
        choptions['forceNiceScale'] = true;
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_date_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      ChartOptions_PumpedVolume(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'PumpedVolume*'};
        choptions['xaxis']['title']['text'] = 'Date';
        choptions['xaxis']['categories'] = this.progress_table.map((x) => x.timestamp);
        choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_date_format;
        choptions['yaxis']['title']['text'] = 'Litres';
        //if (this.progress_table.length > 0)
        //  choptions['yaxis']['min'] = this.progress_table[0][this.p1pumpedvolume];
        choptions['forceNiceScale'] = true;
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_date_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      strokeCountSeries() {
        var statsseries = [{
              name: "Pump1",
              data: this.progress_difference_list.map((x) => x[1][this.p1strokecounti]),
            },{
              name: "Pump2",
              data: this.progress_difference_list.map((x) => x[1][this.p2strokecounti]),
            }];
        return statsseries;
      },
      runtimeSeries() {
        var statsseries = [{
              name: "HMI Time",
              data: this.progress_difference_list.map((x) => this.get_hoursmins(x[1][this.timetotalrunhmii],true)),
            },{
              name: "ESP-CM",
              data: this.progress_difference_list.map((x) => this.get_hoursmins(x[1][this.timetotalruni])),
            }];
        return statsseries;
      },
      pumpedVolumeSeries() {
        var statsseries = [{
              name: "Pump1",
              data: this.progress_table.map((x) => x.p1pumpedvolume),
            },{
              name: "Pump2",
              data: this.progress_table.map((x) => x.p2pumpedvolume),
            }];
        return statsseries;
      },
      ChartOptions_psiStrokeStats(){
        var choptions = JSON.parse(JSON.stringify(this.ChartOptions));
        choptions['title'] = {'text': 'Strokes at Pressure (between dates)'};
        choptions['xaxis']['title']['text'] = 'Pressure (KSI)';
        //choptions['xaxis']['categories'] = Array.from({length: 20}, (_, i) => i * 4.096);
        choptions['xaxis']['categories'] = Array.from({length: 19}, (_, i) => i * 4.096);
        if ((this.p1stats_stcvspsi_i !== null) && (this.progress_list.length > 0)) {
          const stats_stcvspsi_length = this.progress_list[0][1][this.p1stats_stcvspsi_i].length;
          choptions['xaxis']['categories'] = Array.from({length: stats_stcvspsi_length-1}, (_, i) => (i+1) * 4.096);
        }
        //choptions['xaxis']['labels']['formatter'] = this.chart_xaxis_pressure_format;
        choptions['yaxis']['title']['text'] = 'Strokes';
        choptions['yaxis']['labels']['formatter'] = this.chart_yaxis_format;
        choptions['tooltip']['x']['formatter'] = this.chart_tooltip_xaxis_pvsst_format; 
        choptions['tooltip']['y']['formatter'] = this.chart_tooltip_yaxis_format;  
        return choptions;
      },
      psiStrokeStatsSeries() {
        var statsseries = [{
              name: 'Pump 1',
              //data: Array.from({length: 20}, () => 0)
              data: Array.from({length: 19}, () => 0)
            }, {
              name: 'Pump 2',
              //data: Array.from({length: 20}, () => 0)
              data: Array.from({length: 19}, () => 0)
            }];
        if ((this.progress_list.length > 1) 
            && (this.p1stats_stcvspsi_i !== null)
            && (this.p2stats_stcvspsi_i !== null)
          ){
          const progresslist = this.progress_list;
          const p1stats_stcvspsi_oldest = progresslist[0][1][this.p1stats_stcvspsi_i];
          const p2stats_stcvspsi_oldest = progresslist[0][1][this.p2stats_stcvspsi_i];
          const p1stats_stcvspsi_latest = progresslist[progresslist.length-1][1][this.p1stats_stcvspsi_i];
          const p2stats_stcvspsi_latest = progresslist[progresslist.length-1][1][this.p2stats_stcvspsi_i];
          const p1psistrokedifference = p1stats_stcvspsi_oldest.map((x,j)=>(p1stats_stcvspsi_latest[j]-x));//Subtract start day's results
          const p2psistrokedifference = p2stats_stcvspsi_oldest.map((x,j)=>(p2stats_stcvspsi_latest[j]-x));//Subtract start day's results
          //console.log(progresslist,this.p1stats_stcvspsi_i,progresslist[progresslist.length-1][1],);
          //console.log(progresslist[progresslist.length-1][1][this.p1stats_stcvspsi_i],progresslist[0][1][this.p1stats_stcvspsi_i]);
          /*console.log(p1stats_stcvspsi_latest);
          console.log(p1stats_stcvspsi_oldest);
          console.log(p1psistrokedifference);*/
          statsseries[0]['data'] = p1psistrokedifference.slice(1);
          statsseries[1]['data'] = p2psistrokedifference.slice(1);
        }
        return statsseries;
      },
    },
    methods: {
      adjust_graphs_width(){
        if (window.innerWidth > 1280) this.graphwidth = 600;
        else if (window.innerWidth > 960) this.graphwidth = 450;
        else this.graphwidth = window.innerWidth-100;
      },
      get_date_format(datestr) {
        const date =  new Date(datestr);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const formattedDate = `${String(day).padStart(2, '0')}-${String(month).padStart(2, '0')}-${date.getFullYear()}`;
        return formattedDate;
      },
      isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      },
      get_hoursmins(numminsecs, isMins=false) {
        const intvalue = parseInt(numminsecs);
        if (isNaN(intvalue)) return -1;

        var hrsint;
        if (isMins) {
          const minsint = intvalue;
          hrsint =  minsint/60;
        } else {
          const secsint = intvalue;
          hrsint =  secsint/3600;
        }

        return hrsint;
      },
      get_hoursmins_textformat(numminsecs, isMins=false) {
        const intvalue = parseInt(numminsecs);
        if (isNaN(intvalue)) return "N/A";

        var minsint = 0;
        if (isMins) {
          minsint = intvalue;
        } else {
          const secsint = intvalue;
          minsint =  Math.floor(secsint/60);
        }

        const hrsint  = Math.floor(minsint/60);
        minsint = minsint - (hrsint*60);
        return hrsint.toString()+"h "+ minsint.toString()+"m"
      },

      chart_xaxis_date_format (value) {
        //console.log(value);
        const fmtval = this.get_date_format(value);
        return fmtval;
      },
      chart_yaxis_format (value) {
        //console.log(value);
        var fmtval = value;
        if(value > 1E12) fmtval = (value/1E12) + "T";
        else if(value > 1E9) fmtval = (value/1E9) + "B";
        else if(value > 1E6) fmtval = (value/1E6) + "M";
        else if(value > 1E3) fmtval = (value/1E3) + "K";
        return fmtval;
      },
      chart_tooltip_xaxis_date_format (value) {
        //console.log(value);
        //const fmtval = this.get_date_format(value);
        const fmtval = value;
        return fmtval;
      },
      chart_tooltip_yaxis_format (value) {
        //console.log(value);
        var fmtval = value.toFixed(2);
        if(value > 1E12) fmtval = (value/1E12).toFixed(12) + "T";
        else if(value > 1E9) fmtval = (value/1E9).toFixed(9) + "B";
        else if(value > 1E6) fmtval = (value/1E6).toFixed(6) + "M";
        else if(value > 1E3) fmtval = (value/1E3).toFixed(3) + "K";
        return fmtval;
      },

      rebuild_sorted_progresslist(raw_progress_list) {
        const datefmt_progress_list = raw_progress_list.map((v)=>[new Date(v[0]),v[1]]);
        // get temporary progress map so we traverse only once through the list
        // to get the value to sort
        const datetime_progress_map = datefmt_progress_list.map((v, i) => {
          //console.log(v[0]);
          return { i, value: v[0].getTime() };
        });
        // perform the sort on the map
        datetime_progress_map.sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          }
          if (a.value < b.value) {
            return -1;
          }
          return 0;
        });
        // use the sorted map to get the elements from progress list
        const sorted_progress_list = datetime_progress_map.map((v) => datefmt_progress_list[v.i]);
        return sorted_progress_list;
      },
      rebuild_progress_difference(sorted_progress_list){
        if (sorted_progress_list.length > 0) {
          //console.log("sorted_progress_list",sorted_progress_list);
          //const first_data = sorted_progress_list[0][1];
          //this.progress_difference_list = sorted_progress_list.map((v) => [v[0],v[1].map((x,i)=>(x-first_data[i]))]);
          const progesslist = sorted_progress_list;
          this.progress_difference_list = sorted_progress_list.map((v,i) => {
            if (i < (sorted_progress_list.length-1)) {             
              const progress_difference = [v[0],v[1].map((x,j)=> {
                if ((x!==null)&&(x.constructor === Array)){
                  const diffarray = x.map((y,k)=>(progesslist[i+1][1][j][k]-y));// Subtract previous day's array results
                  //console.log("Array", j, diffarray);
                  return diffarray;
                }
                else {
                  return (progesslist[i+1][1][j]-x); // Subtract previous day's results
                }
              })];
              //console.log(progress_difference);
              return progress_difference;
            }
            else {
              // Not Available
              return [v[0],v[1].map((x)=> {
                if ( (x !== null) && (x.constructor === Array) ){
                  return Array.from({length:x.length},()=>0);
                } else {
                  return 0;
                }                
              })];
            }
          });
        } else this.progress_difference_list = [];
      },
      rebuild_progress_table(progresslist){
        const timetotalrunhmii = this.progress_profile.indexOf("app.hmiViewModel.s32TotalRunTimeFromHMI_Minutes");
        const timetotalruni = this.progress_profile.indexOf("app.machine.sapController.u32TotalRuntime_1024msec");
        const timeoni = this.progress_profile.indexOf("app.machine.u32TotalOntime_1024msec");
        const p1strokecounti = this.progress_profile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.u32StrokeCount");
        const p2strokecounti = this.progress_profile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.u32StrokeCount");
        this.p1stats_stcvspsi_i = this.progress_profile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsPressureStats");

        const p1strokedlength0i = this.progress_profile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics_0.s32TotalStrokedLength");
        const p1strokedlength1i = this.progress_profile.indexOf("app.machine.pump1.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics_1.s32TotalStrokedLength");
        const p1plungerdiameteri = this.progress_profile.indexOf("app.machine.pump1.pumpController.s32PlungerDiameter");
        const p2strokedlength0i = this.progress_profile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics_0.s32TotalStrokedLength");
        const p2strokedlength1i = this.progress_profile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.astStrokeStatistics_1.s32TotalStrokedLength");
        const p2plungerdiameteri = this.progress_profile.indexOf("app.machine.pump2.pumpController.s32PlungerDiameter");
        this.p2stats_stcvspsi_i = this.progress_profile.indexOf("app.machine.pump2.actuatorController.pumpHLF.hlfStroking.au32StrokeCountVsPressureStats");

        this.timetotalrunhmii = timetotalrunhmii;
        this.timetotalruni = timetotalruni;
        this.timeoni = timeoni;
        this.p1strokecounti = p1strokecounti;
        this.p2strokecounti = p2strokecounti;
        this.p1strokedlength0i = p1strokedlength0i;
        this.p1strokedlength1i = p1strokedlength1i;
        this.p1plungerdiameteri = p1plungerdiameteri;
        this.p2strokedlength0i = p2strokedlength0i;
        this.p2strokedlength1i = p2strokedlength1i;
        this.p2plungerdiameteri = p2plungerdiameteri;

        var progresstable = [];
        progresslist.forEach(function (phval){
          const p1volumeperstroke_NOM = 3.14*0.5*phval[1][p1plungerdiameteri]*phval[1][p1plungerdiameteri]*0.5*(phval[1][p1strokedlength0i]+phval[1][p1strokedlength1i])*1E-6;//litres
          const p2volumeperstroke_NOM = 3.14*0.5*phval[1][p2plungerdiameteri]*phval[1][p2plungerdiameteri]*0.5*(phval[1][p2strokedlength0i]+phval[1][p2strokedlength1i])*1E-6;//litres
          progresstable.push({
            "timestamp":phval[0],
            "timetotalrunhmi":phval[1][timetotalrunhmii],
            "timetotalrun":phval[1][timetotalruni],
            "timeon":phval[1][timeoni],
            "p1strokecount":phval[1][p1strokecounti],
            "p2strokecount":phval[1][p2strokecounti],
            "p1pumpedvolume":phval[1][p2strokecounti]*p1volumeperstroke_NOM,
            "p2pumpedvolume":phval[1][p2strokecounti]*p2volumeperstroke_NOM,
          });
        }); 
        this.progress_table = progresstable;
      },
      get_health_progress(pumpip,startdatestr,enddatestr){

        var utcstartdatestr = this.convertLocalDateToUTCDate(startdatestr);
        var utcenddatestr = this.convertLocalDateToUTCDate(enddatestr);

        this.$api.post(
          this.$olsupervisorurl + '/health/progress', 
          {
            'code':'get_health_progress', 
            "pumpip":this.$route.query["pumpip"], 
            "startdate":utcstartdatestr,
            "enddate":utcenddatestr,
            "tzmins":-(new Date()).getTimezoneOffset()
          })
        .then(response => {
          //console.log(response.data);
          if (response.data['code'] == 'get_health_progress') {
            if (response.data['success'])
            {
              const progressreport = response.data['progress'];
              if (progressreport != null) {
                this.pumpip = progressreport['pumpip'];
                this.pumpcname = progressreport['cname'];
                this.pumpnname = progressreport['nname'];
                this.progress_profile = progressreport['profile'];
                //this.progress_list = progressreport['list'];
                this.progress_list = this.rebuild_sorted_progresslist(progressreport['list']);
                this.rebuild_progress_difference(this.progress_list);
                this.rebuild_progress_table(this.progress_list);
              } else {
                this.pumpip = this.$route.query["pumpip"];
                this.pumpcname = null;
                this.pumpnname = null;
                this.progress_profile = [];
                this.progress_list = [];
                this.report_text = "Data not available!";
              }
            } else {
              this.progress_profile = [];
              this.report_text = this.$route.query["pumpip"] + " - " + response.data['errmsg'];
            }
          } else {
            this.report_text = "Invalid response!";
          }
        });
      },
      customSort(items, index, isDesc) {
        //console.log(index, isDesc);
        //console.log(items.map((item => item.timestamp)));
        items.sort((a, b) => {
          //console.log(a, b, index, isDesc);
          if (index[0] === "datetime") {
            /*const a_parts = a.split('-');
            const a_date = new Date(a_parts[0], a_parts[1] - 1, a_parts[2]);
            const b_parts = b.split('-');
            const b_date = new Date(b_parts[0], b_parts[1] - 1, b_parts[2]);*/
            const a_date = a.timestamp; //new Date(a.timestamp);
            const b_date = b.timestamp; //new Date(b.timestamp);
            //console.log(a_date, b_date);
            //console.log(a_date.getTime(), b_date.getTime());
            //console.log(index[0], isDesc[0]);
            if (!isDesc[0]) {
              //console.log("false",a_date.getTime() - b_date.getTime());
              return (a_date.getTime() - b_date.getTime());
            } else {
              //console.log("true",a_date.getTime() - b_date.getTime());
              return (b_date.getTime() - a_date.getTime());
            }
          } else {
            if (!isDesc[0]) {
              //console.log("false",a[index[0]],b[index[0]]);
              return (a[index[0]] - b[index[0]]);
            } else {
              //console.log("true",a[index[0]],b[index[0]]);
              return (b[index[0]] - a[index[0]]);
            }
          }
        });
        //console.log(items.map((item => item.timestamp)));
        return items;
      }
    },
    created(){
      this.convertLocalDateToUTCDate = dateutils.convertLocalDateToUTCDate;
      this.convertUTCDateToLocalDate = dateutils.convertUTCDateToLocalDate;

      this.adjust_graphs_width();
      window.addEventListener("resize",this.adjust_graphs_width);
    },
    destroyed(){
      window.removeEventListener("resize",this.adjust_graphs_width);
    },
    mounted(){
      const pumpip = this.$route.query["pumpip"];
      
      //const yesterday = new Date();
      //yesterday.setDate(yesterday.getDate()-1);
      const start_of_month = new Date();
      start_of_month.setDate(1);
      //const today = new Date();
      
      this.startdate = (new Date(start_of_month - start_of_month.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      //this.enddate = (new Date(today - today.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

      this.get_health_progress(pumpip,this.startdate,this.enddate);
    },
    
  }
</script>
<style scoped>
@media print {
    .v-btn, .v-input {
        visibility: hidden;
    }
}
</style>