

<script>
export default {
    name: 'PageNotFound',
    mounted: function() {
        // Redirect outside the app using plain old javascript
        window.location.href = "/page_not_found.html";
    }
}
</script>