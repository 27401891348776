<template>
    <v-btn dark small color="grey" @click="handleInput">
        <template v-if="isInEditMode">
            <v-icon dark> mdi-progress-check </v-icon> Preview
        </template>
        <template v-else>
            <v-icon dark> mdi-feather </v-icon> Edit
        </template>
    </v-btn>
</template>

<script>
    export default {
        name: 'EditButton',
        props: {
            value : Boolean
        },
        data () {
            return {
                view_edit_controls : true,
                isInEditMode : this.value
            }
        },
        methods: {
            handleInput () {
                this.isInEditMode = !this.isInEditMode;
                //e.target.value = this.isInEditMode;
                this.$emit('input', this.isInEditMode)
            }
        }
    }
</script>