<template>
<div id="hmireference">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>HMI Screen: {{ this.$route.query['name'] }} </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container v-if="(screencontent != null) && (Object.keys(screencontent).length > 0)">
    <v-row>
      <v-col cols="2" class="text-left"><h2>Screen</h2></v-col>
      <v-col cols="10">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 480" width="800" height="480">
            <g>
                <image x="0" y="0" width="800" height="480" :xlink:href="getImgUrl(screencontent['imgsrc'])" />
            </g>
              <g>
                <template v-for="mbox,idx in screencontent['map']" >
                  <a v-if="mbox['targetscreen'] != null" :xlink:href="'hmiscreen?name='+mbox['targetscreen']" :key="'ts'+idx">
                      <rect :x="mbox['coords'][0]" :y="mbox['coords'][1]" :width="mbox['coords'][2]" :height="mbox['coords'][3]" class="controlbox"></rect>
                  </a>
                  <a v-else :xlink:href="'#'+mbox['targetcontrol']" :key="'tc'+idx">
                      <rect :x="mbox['coords'][0]" :y="mbox['coords'][1]" :width="mbox['coords'][2]" :height="mbox['coords'][3]" class="controlbox"></rect>
                  </a>
                </template>
            </g>
          </svg>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="text-left"><h2>Description</h2></v-col>
      <v-col cols="10">
        <markdown-it-vue-light class="md-body" :content="screencontent['description']" />
      </v-col>
    </v-row>
    <v-row v-if="screencontent['controls'].length > 0">
      <v-col cols="2" class="text-left"><h2>Control Details</h2></v-col>
      <v-col cols="10">
        <v-container>
          <template v-for="cntrl,idx in screencontent['controls']">
            <v-row  class="mt-2 mb-2" :key="'cntrl'+idx">
              <v-col cols="4">
                <img v-for="isrc,iidx in cntrl['imglist']" :src="getImgUrl(isrc)" :key="idx+'cimg'+iidx" style="max-width:100%"/>
              </v-col>
              <v-col cols="8">
                <a v-if="cntrl['name'] != null" :name="cntrl['name']"></a>
                <h3>{{ cntrl['label'] }}</h3>
                <span> {{ cntrl['details'] }} </span>
              </v-col>
            </v-row>
            <v-divider :key="'cdiv'+idx"></v-divider>
          </template>
        </v-container>
      </v-col>
    </v-row>
    </v-container>
    <div v-else>
      Invalid Page name
    </div>
</div>
</template>

<script>
  import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js';
  import 'markdown-it-vue/dist/markdown-it-vue-light.css';

  export default {
    name: "HMIReference",
    components: {
      MarkdownItVueLight
    },
    data: () => ({
        screencontent: {},
        testsc:{
          name: "AdvDiag - System Overview",
          imgsrc: "HMI085.jpg",
          map: [
            {'coords':[547,187,141,123], 'targetscreen': "AdvDiag%20-%20HP%20Output", 'targetcontrol': null},
            {'coords':[235,310,315,123], 'targetscreen': "AdvDiag%20-%20Lubrication", 'targetcontrol': null},
            {'coords':[94,189,141,123], 'targetscreen': "AdvDiag%20-%20LP%20Input", 'targetcontrol': null},
            {'coords':[138,0,87,50], 'targetscreen': "Main%20Menu", 'targetcontrol': null},
            {'coords':[71,0,67,49], 'targetscreen': "Main%20Menu", 'targetcontrol': null},
            {'coords':[0,0,72,49], 'targetscreen': "Pressure%20Control%20Screen", 'targetcontrol': null},
            {'coords':[237,92,313,96], 'targetscreen': null, 'targetcontrol': "Settings"},
            {'coords':[235,187,313,124], 'targetscreen': null, 'targetcontrol': "Settings"},
          ],
          description: "Summery of the 4 main systems in the pump.  \n\
  \n\
- Low Pressure Input Water  \n\
- Actuator (motor).  \n\
- High Pressure Output Water.  \n\
- Oil Lubrication.  \n\
  \n\
Press on a system to navigate to its page and view the system in more detail.",
          controls:[
            {"name":null, "imglist":['HMI091.png','HMI093.png'], "label":"Warning or Error Alert", "details":"A Warning or Error symbol will appear over each system if a problem is detected. Press on the system to enter its page and view the system in more detail."},
            {"name":"Settings", "imglist":['HMI083.jpg'], "label":"Advanced Diagnostics Settings", "details":"Turn on/off and inhibit the Separate Diagnostic Systems. Turning off a system will stop all alarms from that system from appearing or stopping the pump. Inhibiting a system will allow the alarm message to still appear, but it will not stop the pump from running."},
          ]
        }
    }),
    mounted() {
      //this.$emit('loadstart');
      //console.log(this.$dtcdburl + "/hmi/screenref?name=" + this.$route.query['name']);
      this.$api.get(this.$dtcdburl + "/hmi/screenref?name=" + this.$route.query['name'])
      .then(response => {
        this.screencontent = response.data;
        //console.log(response.data);
        //this.$emit('loadcomplete');
      });
    },
    methods: {
      getImgUrl(pic) {
        //return require('@/assets/'+ pic);
        return this.$dtcdburl + '/static/'+ pic;
      }
    }
  }
</script>

<style type="text/css">
    .controlbox {
        fill-opacity: 0.0;
    }

    .controlbox:hover {
        fill: rgb(255, 216, 0);
        fill-opacity: 0.5;
        /*stroke-width: 3;
        stroke: rgb(0,0,0);*/
    }
</style>