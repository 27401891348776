<template>
  <div>
    <v-simple-table v-if="pump['daq'].length > 0">
      <template v-slot:default>
          <thead>
          <tr>
              <th class="text-left">Description</th>
              <th class="text-left">Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
              <td>ESP-CM Version</td>
              <td>{{ espcmVersion }}</td>
          </tr>
          <tr>
              <td>Bootloader Firmware</td>
              <td>{{ bootldrVersion }}</td>
          </tr>
          <tr>
              <td>ProfileHash</td>
              <td>{{ pump["connstate"]["profilehash"] }}</td>
          </tr>
          <tr>
              <td>IP Address</td>
              <td>{{ pump["ipaddr"] }}</td>
          </tr>
          <tr>
              <td>Total Hours</td>
              <td>{{ totalhours.toFixed() }}</td>
          </tr>
          </tbody>
      </template>
    </v-simple-table>
    <v-container v-else>
        <v-row>
            <v-col>
                <p class="text-center">PumpState:&nbsp;<b>{{ represent_pump_state(pump) }}</b></p>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import vhmi_mcdvars_list from '../../plugins/mcdvariables';
import vhmiutils from '../../plugins/vhmi_utilities';

export default {
  name: "BasicInfoTab",
  props: {
      pump: null
  },
  data() {
    return {
      au8AppVersionFullStringi: null,
      au8BootldrVersionFullStringi: null,
      totalhoursi: null,
    }
  },
  created() {
    this.enum_pump_states = vhmiutils.enum_pump_states;
    this.represent_pump_state = vhmiutils.represent_pump_state;
    this.u16hmistatei = vhmi_mcdvars_list.indexOf("app.hmiViewModel.u16StateHMI");

    this.au8AppVersionFullStringi = vhmi_mcdvars_list.indexOf("app.applicationInfo.codeInformationBlock_App.stVersionInformation.au8VersionFullString");
    this.au8BootldrVersionFullStringi = vhmi_mcdvars_list.indexOf("app.applicationInfo.codeInformationBlock_BootLoader.stVersionInformation.au8VersionFullString");
    this.totalhoursi = vhmi_mcdvars_list.indexOf("app.hmiViewModel.s32TotalRunTimeFromHMI_Minutes");
  },
  computed: {
    espcmVersion: function() {
      const daqdata = this.pump["daq"];
      return String.fromCharCode.apply(null,daqdata[this.au8AppVersionFullStringi]);
    },
    bootldrVersion: function() {
      const daqdata = this.pump["daq"];
      return String.fromCharCode.apply(null,daqdata[this.au8BootldrVersionFullStringi]);
    },
    totalhours: function() {
      const daqdata = this.pump["daq"];
      return daqdata[this.totalhoursi]/60;
    }
  },
}
</script>
