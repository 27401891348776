<template>
<div id="pumpsearch" style="height:86vh">
    <v-toolbar class="mt-2" flat>
      <v-toolbar-title>Pumps Search</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    
    <v-container fluid class="fill-height">
    <v-row class="fill-height" justify="space-between" >

      <v-col cols="12" class="fill-height">
        <v-card class="fill-height" cols="9" style="overflow-y: scroll">
        <v-card-title>
          <v-text-field  v-model="pumpip_to_search" append-icon="mdi-magnify" class="shrink"
            filled dense hide-details :rules="[v => v.length<=15 || 'Max 15 characters']" counter>
          </v-text-field>
          &nbsp;
          <v-btn class="primary fill-height" @click="searchPumps">Search</v-btn>
        </v-card-title>
        <v-card-subtitle>
          <v-card-text><h3>Found Pumps ({{ num_found_pumps }})</h3></v-card-text>
        </v-card-subtitle>
        <v-scroll-y-transition mode="out-in">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"> Add to VHMI </th>
                    <th class="text-left"> Add to Reporter </th>
                    <th class="text-left" v-if="view_pumpreporter_vars"> ReporterVars </th>
                    <th class="text-left"> IP Address </th>
                    <th class="text-left"> Nickname </th>
                    <th class="text-left"> Customer </th>
                    <th class="text-left"> Reference Path </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pump,idx in pumpslist" :key="idx" >
                    <td v-if="num_found_pumps>0">
                      <v-btn v-if="pump.onvhmi" dark small color="error">
                        <v-icon light > mdi-checkbox-marked-outline </v-icon>
                      </v-btn>
                      <v-btn v-else dark small color="primary" @click="add_pump_to_vhmi(pump.id,pump.ip)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="num_found_pumps>0">
                      <v-btn v-if="pump.onreporter" dark small color="error" @click="del_pump_from_reporter(pump.id,pump.ip)">
                        <v-icon light > mdi-minus </v-icon>
                      </v-btn>
                      <v-btn v-else dark small color="primary" @click="add_pump_to_reporter(pump.id,pump.ip)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="view_pumpreporter_vars && (num_found_pumps>0)">
                      <v-btn dark small color="green" @click="goto_pumpreporter_varspage(pump.id)">
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </td>
                    <td class="text-left">{{ pump.ip }}</td>
                    <td class="text-left">{{ pump.nname }}</td>
                    <td class="text-left">{{ pump.cname }}</td>
                    <td> {{ pathListToString(pump.pumppath) }} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-scroll-y-transition>
  </v-card>
      </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
  import pumphooks from '../plugins/pump_data_hooks'
  export default {
    name: "PumpSearchPage",

    data: () => ({
      pumpip_to_search: "172.16.0.0",      
      pumpslist: [],
      num_found_pumps: 0,
      pumps_in_reporter: [],

      view_pumpreporter_vars: null
    }),
    created() {
      //this.pumps_in_reporter = pumphooks.pumps_in_reporter;
      this.get_pumps_in_reporter = pumphooks.get_pumps_in_reporter;
      this.add_pump_to_vhmi = pumphooks.add_pump_to_vhmi;
      this.pumps_onvhmi_check = pumphooks.pumps_onvhmi_check;
      this.pumps_onreporter_check = pumphooks.pumps_onreporter_check;
      this.add_pump_to_reporter = pumphooks.add_pump_to_reporter;
      this.del_pump_from_reporter = pumphooks.del_pump_from_reporter;
      this.goto_pumpreporter_varspage = pumphooks.goto_pumpreporter_varspage;
    },
    mounted() {
      //this.$emit('loadstart');
      const useraccessdict = this.$store.getters.get_useraccessdict();
      //console.log(useraccessdict);
      this.view_pumpreporter_vars = (useraccessdict != null) && useraccessdict["pumpvars"];
      
      this.get_pumps_in_reporter();
    },
    methods: {
      searchPumps () {
        //console.log(this.pumpip_to_search)
        this.pumpslist = [];
        this.num_found_pumps = 0;
        //console.log("test");

        this.$api.post(this.$orgmanurl + '/pumps/search',{"pumpip":this.pumpip_to_search})
              .then(response => {
                //console.log(response.data);
                if (response.data["code"] == "search_company_pumps")
                {
                  this.pumpslist = response.data["found-pumps-list"];
                  //console.log(this.pumpslist);
                  
                  if (this.pumpslist != null) {
                    this.pumps_onvhmi_check();
                    this.pumps_onreporter_check();
                  }
                  else this.pumpslist = [];
                  this.num_found_pumps = this.pumpslist.length;
                  if (this.pumpslist.length == 0) this.pumpslist = [{nname:"Not Found!", ip:this.pumpip_to_search, pumppath:[]}];
                }
                //this.$emit('loadcomplete');
              });

        return;
      },
      pathListToString(pathlist) {
        return pathlist.join(' -> ')
      }
    }
  }
</script>